// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useNavigate, useLocation } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import GroupNavbar from "examples/Navbars/GroupNavbar";
import Footer from "examples/Footer";

// http-api-tool
import axios from "axios";
// env
const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

function MemberList() {
  const location = useLocation();
  const usergroupId = location.pathname.split("/")[2];
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get Group Detail
  const [usergroupLoading, setUsergroupLoading] = useState(true);
  const [usergroup, setUsergroup, usergroupRef] = useState("");
  const usergroupBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const getUsergroupDetail = async () => {
    try {
      const usergroupResponse = await usergroupBackend.get("");
      const usergroupResponseData = await usergroupResponse.data;
      setUsergroup(usergroupResponseData);
      setUsergroupLoading(false);
    } catch (error) {
      navigate("/groups");
    }
  };

  // Get Membership List
  const [members, setMembers, membersRef] = useState([]);
  const memberListBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/memberships/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const getMemberList = async () => {
    try {
      const memberListResponse = await memberListBackend.get("/?ordering=status");
      const memberListResponseData = await memberListResponse.data;
      setMembers(memberListResponseData);
    } catch (error) {
      // console.log("error");
    }
  };

  // set avatar & Fisrt_name
  const setAvatar = (username) => {
    const userObj = usergroupRef.current.members.find((member) => member.username === username);
    const { avatar } = userObj;
    return avatar;
  };
  const setNickname = (username) => {
    const userObj = usergroupRef.current.members.find((member) => member.username === username);
    return userObj.nickname;
  };

  useEffect(() => {
    getUsergroupDetail();
    getMemberList();
  }, []);

  // Dialog for Member info window
  const [open, setOpen] = useState(false);
  const [, setUserWindowData, userWindowDataRef] = useState("");
  const [, setMemberWindowData, memberWindowDataRef] = useState("");
  const openMemberWindow = (username) => {
    const userObj = usergroupRef.current.members.find((member) => member.username === username);
    setUserWindowData(userObj);
    const memberObj = membersRef.current.find((member) => member.user === username);
    setMemberWindowData(memberObj);
    setOpen(true);
  };
  const closeMemberWindow = () => {
    setOpen(false);
  };

  // Remove Member
  const removeMember = async (memberId) => {
    try {
      const username = document.getElementById("removeUerCheck").value;
      if (username === memberWindowDataRef.current.user) {
        await memberListBackend
          .patch(memberId.toString().concat("/"), {
            status: "removed",
          })
          .then(() => {
            // update the member hook with new status
            getUsergroupDetail();
            getMemberList();
            openMemberWindow();
          })
          .then(() => {
            setOpen(false);
          });
      }
    } catch (error) {
      // console.log("error");
    }
  };

  // Reinvite Member
  const reinviteMember = async (memberId) => {
    try {
      const username = document.getElementById("reinviteUerCheck").value;
      if (username === memberWindowDataRef.current.user) {
        await memberListBackend
          .patch(memberId.toString().concat("/"), {
            status: "member",
          })
          .then(() => {
            getUsergroupDetail();
            getMemberList();
            openMemberWindow();
          })
          .then(() => {
            setOpen(false);
          });
      }
    } catch (error) {
      // console.log("error");
    }
  };

  return (
    <DashboardLayout>
      {usergroupLoading === false && (
        <Helmet>
          <title>
            {usergroup.name} | {appName}
          </title>
        </Helmet>
      )}
      <GroupNavbar />
      <MDBox
        pt={2}
        pb={3}
        sx={{
          mx: { xs: 0.5, md: 2, lg: 3 },
        }}
      >
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} sm={12} lg={8}>
            <Card>
              {usergroupLoading === false && (
                <MDBox pt={2} pb={1} px={2}>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid item>
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        {t("member")}
                      </MDTypography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        variant="gradient"
                        color="secondary"
                        size="small"
                        href={frontendURL.concat("/groups/", usergroup.id, "/members/invite")}
                      >
                        <Icon>group_add</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} lg={12}>
                      <Grid container spacing={1}>
                        {members.map((member) => {
                          if (member.status === "member") {
                            return (
                              <Grid item key={member.id}>
                                <MDButton
                                  variant="text"
                                  size="small"
                                  onClick={() => openMemberWindow(member.user)}
                                >
                                  <MDBox mx={-1}>
                                    <MDBox
                                      component="a"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <MDAvatar
                                        size="lg"
                                        bgColor="light"
                                        shadow="lg"
                                        alt="Avatar"
                                        variant="circular"
                                        src={setAvatar(member.user)}
                                      />
                                    </MDBox>
                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        align="center"
                                      >
                                        {setNickname(member.user)}
                                      </MDTypography>
                                    </MDBox>
                                  </MDBox>
                                </MDButton>
                              </Grid>
                            );
                          }
                          if (member.status === "invited") {
                            return (
                              <Grid item key={member.id}>
                                <MDButton
                                  variant="text"
                                  size="small"
                                  onClick={() => openMemberWindow(member.user)}
                                >
                                  <MDBox mx={-1}>
                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <MDAvatar
                                        size="lg"
                                        bgColor="light"
                                        shadow="lg"
                                        alt="Avatar"
                                        variant="circular"
                                        src={setAvatar(member.user)}
                                      />
                                    </MDBox>
                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <MDTypography variant="button" color="info" align="center">
                                        {setNickname(member.user)} <br /> ({t("invited")})
                                      </MDTypography>
                                    </MDBox>
                                  </MDBox>
                                </MDButton>
                              </Grid>
                            );
                          }
                          if (member.status === "removed") {
                            return (
                              <Grid item key={member.id}>
                                <MDButton
                                  variant="text"
                                  size="small"
                                  onClick={() => openMemberWindow(member.user)}
                                >
                                  <MDBox mx={-1}>
                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <MDAvatar
                                        size="lg"
                                        bgColor="light"
                                        shadow="lg"
                                        alt="Avatar"
                                        variant="circular"
                                        src={setAvatar(member.user)}
                                      />
                                    </MDBox>
                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <MDTypography variant="button" align="center">
                                        {setNickname(member.user)} <br /> ({t("removed")})
                                      </MDTypography>
                                    </MDBox>
                                  </MDBox>
                                </MDButton>
                              </Grid>
                            );
                          }
                          return null;
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog
        maxWidth="xs"
        open={open}
        keepMounted
        onClose={closeMemberWindow}
        aria-describedby="alert-dialog-slide-description"
      >
        {userWindowDataRef.current !== undefined && (
          <MDBox pt={2}>
            <Grid container justifyContent="center">
              <Grid item>
                <MDAvatar
                  size="xxl"
                  bgColor="light"
                  shadow="lg"
                  alt="Avatar"
                  variant="circular"
                  src={userWindowDataRef.current.avatar}
                />
              </Grid>
            </Grid>
            {usergroupLoading === false && userWindowDataRef.current !== "" && (
              <DialogTitle align="center">
                {setNickname(userWindowDataRef.current.username)}
              </DialogTitle>
            )}
            <DialogContent align="center">{userWindowDataRef.current.username}</DialogContent>
            {memberWindowDataRef.current.status === "member" && (
              <MDBox>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {t(
                      "If you wish to delete this user, please enter the username in the input field below."
                    )}
                  </DialogContentText>
                  <MDBox pt={2}>
                    <MDInput id="removeUerCheck" variant="standard" fullWidth />
                  </MDBox>
                </DialogContent>
                <DialogActions>
                  <MDButton
                    color="error"
                    size="small"
                    onClick={() => removeMember(memberWindowDataRef.current.id)}
                  >
                    <Icon>person_remove</Icon>
                    &nbsp;{t("remove")}
                  </MDButton>
                  <MDButton color="light" size="small" onClick={closeMemberWindow}>
                    <Icon>arrow_backward</Icon>
                    &nbsp;{t("go back")}
                  </MDButton>
                </DialogActions>
              </MDBox>
            )}
            {memberWindowDataRef.current.status === "removed" && (
              <MDBox>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {t(
                      "If you want to invite this user again, please enter the username in the input field below."
                    )}
                  </DialogContentText>
                  <br />
                  <DialogContentText id="alert-dialog-slide-description">
                    {t("User will join the group directly after being invited.")}
                  </DialogContentText>
                  <MDBox pt={2}>
                    <MDInput id="reinviteUerCheck" variant="standard" fullWidth />
                  </MDBox>
                </DialogContent>
                <DialogActions>
                  <MDButton
                    color="info"
                    size="small"
                    onClick={() => reinviteMember(memberWindowDataRef.current.id)}
                  >
                    <Icon>person_add</Icon>
                    &nbsp;{t("invite")}
                  </MDButton>
                  <MDButton color="light" size="small" onClick={closeMemberWindow}>
                    <Icon>arrow_backward</Icon>
                    &nbsp;{t("go back")}
                  </MDButton>
                </DialogActions>
              </MDBox>
            )}
            {memberWindowDataRef.current.status === "invited" && (
              <MDBox>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {t("Go to the invitation page if you want to cancel the invitation.")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <MDButton color="light" size="small" onClick={closeMemberWindow}>
                    <Icon>arrow_backward</Icon>
                    &nbsp;{t("go back")}
                  </MDButton>
                </DialogActions>
              </MDBox>
            )}
          </MDBox>
        )}
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default MemberList;
