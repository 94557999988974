// React
import { useEffect } from "react";
import useState from "react-usestateref";
// i18n
import { useTranslation } from "react-i18next";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// MUI components
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
// MD components
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import muiIcons from "components/MUIIcon/muiIcons.json";

// const muiIconList = muiIcons;

function IconSelecter({ icon }) {
  const { t } = useTranslation();

  const [iconFocus, setIconFocus] = useState("");
  const handleIconSelectOnChange = (event, value) => {
    setIconFocus(value);
  };
  const setOriginIcon = () => {
    setIconFocus(icon);
  };
  useEffect(() => {
    setOriginIcon();
  }, []);

  return (
    <Autocomplete
      id="iconSelect"
      disableClearable
      defaultValue={icon}
      options={muiIcons}
      onInputChange={(event, value) => handleIconSelectOnChange(event, value)}
      renderInput={(params) => (
        <MDInput
          {...params}
          label={t("Select a icon")}
          // variant="standard"
          InputProps={{
            ...params.InputProps,
            sx: { height: 47 },
            style: { fontSize: 16 },
            startAdornment: (
              <InputAdornment position="start">
                <Icon>{iconFocus}</Icon>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ style: { fontSize: 16, padding: 1 } }}
        />
      )}
      renderOption={(props, option) => (
        <MDBox
          key={option}
          component="li"
          sx={{ "& > span": { mr: 2, flexShrink: 0 } }}
          {...props}
          // id={option}
        >
          <Icon>{option}</Icon>
          {option}
        </MDBox>
      )}
    />
  );
}

// Setting default values for the props of IconSelecter
IconSelecter.defaultProps = {};

// Typechecking props for the IconSelecter
IconSelecter.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default IconSelecter;
