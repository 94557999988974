/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// React
// import useState from "react-usestateref";
// i18n
import { useTranslation } from "react-i18next";
/* eslint-disable no-dupe-keys */
// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import ChartDataLabels from "chartjs-plugin-datalabels";

const { gradients, dark } = colors;

let selectedDatasetIndex = -1;
let selectedIndex = -1;
let selectedDatasetLabel = "";

function configs(labels, datasets, cutout = 80) {
  const { t } = useTranslation();
  const backgroundColors = [];

  if (datasets.backgroundColors) {
    datasets.backgroundColors.forEach((color) => {
      if (gradients[color]) {
        if (color === "info") {
          backgroundColors.push(gradients.info.main);
        } else {
          backgroundColors.push(gradients[color].state);
        }
      } else {
        backgroundColors.push(dark.main);
      }
    });
  } else {
    backgroundColors.push(dark.main);
  }

  const centerText = {
    id: "centerText",
    beforeDatasetsDraw: (chart) => {
      const { ctx, data } = chart;
      // 偵測 selectedDatasetLabel，若不一樣，把 selectedDatasetIndex 設為 -1，從頭開始：先顯示 centerText = totalamount
      if (data.datasets[0].label !== selectedDatasetLabel) {
        selectedDatasetLabel = data.datasets[0].label;
        selectedDatasetIndex = -1;
      }
      // 沒資料時顯示 There is no data for this month
      if (data.datasets[0].data.length === 0) {
        const xCoor = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
        const yCoor = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
        const fontSize = 30;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "bolder 20px sans-serif";
        ctx.fillText(t("There is no data for this month"), xCoor, yCoor - fontSize);
        ctx.restore();
      }
      // 有資料時顯示 總額
      if (
        data.datasets[0].label === selectedDatasetLabel &&
        data.datasets[0].data.length > 0 &&
        selectedDatasetIndex < 0
      ) {
        let totalAmount = 0;
        data.datasets[0].data.map((amount) => {
          totalAmount += amount;
          return null;
        });
        const xCoor = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
        const yCoor = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
        const fontSize = 30;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "bolder 20px sans-serif";
        ctx.fillText(t("total"), xCoor, yCoor - fontSize);
        ctx.font = "bolder 24px sans-serif";
        ctx.fillText("$".concat(totalAmount.toLocaleString()), xCoor, yCoor);
        ctx.restore();
      }
    },
  };

  // const [selectedDatasetIndex, setSelectedDatasetIndex] = useState(-1);
  // const [selectedIndex, setSelectedIndex] = useState(-1);
  const clickLabel = {
    id: "clickLabel",
    afterDatasetsDraw: (chart) => {
      const { ctx, data } = chart;
      if (
        data.datasets[0].label === selectedDatasetLabel &&
        data.datasets[0].data.length > 0 &&
        selectedDatasetIndex >= 0
      ) {
        const amount = data.datasets[0].data[selectedIndex];
        let totalAmount = 0;
        data.datasets[0].data.map((amounti) => {
          totalAmount += amounti;
          return null;
        });
        const label = data.labels[selectedIndex];
        const percentage = "  ".concat(((amount / totalAmount) * 100).toFixed(2), "%");
        const xCoor = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
        const yCoor = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
        const fontSize = 30;
        ctx.save();
        ctx.fillStyle = data.datasets[0].backgroundColor[selectedIndex];
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "bolder 20px sans-serif";
        ctx.fillText(label, xCoor, yCoor - fontSize);
        ctx.font = "bolder 24px sans-serif";
        ctx.fillText("$".concat(amount.toLocaleString()), xCoor, yCoor);
        ctx.font = "bolder 18px calibri";
        ctx.fillText(percentage, xCoor, yCoor + (fontSize * 24) / 30);
        ctx.restore();
      }
    },
  };

  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          weight: 9,
          cutout,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: backgroundColors,
          fill: false,
          data: datasets.data,
        },
      ],
    },
    plugins: [ChartDataLabels, centerText, clickLabel],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      onClick(click, element, chart) {
        if (element[0]) {
          selectedDatasetIndex = element[0].datasetIndex;
          selectedIndex = element[0].index;
          chart.draw();
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          display: "auto",
          formatter: (val, context) =>
            `${context.chart.data.labels[context.dataIndex]}\n${(
              (Number(val) * 100) /
              context.chart.data.datasets[context.datasetIndex].data.reduce(
                (a, b) => Number(a) + Number(b),
                0
              )
            ).toFixed(2)}%`,
          borderRadius: 25,
          borderWidth: 3,
          color: "black",
          font: {
            size: "18px",
            weight: "bold",
          },
          padding: 0,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

export default configs;
