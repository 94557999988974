/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
// Routes
import { useLocation } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
// import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import AccountNavbarItem from "examples/Navbars/components/AccountNavbarItem";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from "context";

// http-api-tool
import axios from "axios";

const backendURL = process.env.REACT_APP_BACKENDURL;
const frontendURL = process.env.REACT_APP_FRONTENDURL;

function GroupSetNavbar({ absolute, light, isMini }) {
  const location = useLocation();
  const usergroupSetId = location.pathname.split("/")[2];

  const { t } = useTranslation();

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  // const route = useLocation().pathname.split("/").slice(1);

  // count_of_unread Notification
  const [countOfInvited, setCountOfInvited] = useState(0);
  const myMembershipsBackend = axios.create({
    baseURL: `${backendURL}/memberships/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const getCountOfInvited = async () => {
    try {
      const countResponse = await myMembershipsBackend.get("count_of_invited");
      setCountOfInvited(countResponse.data);
    } catch (error) {
      // console.log("error");
    }
  };

  // get usergroupSet name & icon
  const [usergroupSet, setUsergroupSet] = useState("");
  const usergroupSetsBackend = axios.create({
    baseURL: `${backendURL}/usergroupsets/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const getUsergroupSet = async () => {
    try {
      const usergroupSetResponse = await usergroupSetsBackend.get(usergroupSetId);
      const usergroupSetResponseData = await usergroupSetResponse.data;
      setUsergroupSet(usergroupSetResponseData);
    } catch (error) {
      // console.log("error");
    }
  };

  useEffect(() => {
    // get Count Of Invited
    getCountOfInvited();
    // get usergroupSet name
    getUsergroupSet();

    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar, usergroupSetId]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 0, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Tooltip
            title={
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="white"
                textTransform="capitalize"
              >
                {t("quickway")}
              </MDTypography>
            }
          >
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon sx={iconsStyle} fontSize="medium">
                {miniSidenav ? "apps" : "grid_view"}
              </Icon>
            </IconButton>
          </Tooltip>
          <MDBox
            color="inherit"
            sx={{
              width: { xs: "220px", md: "620px", lg: "620px" },
            }}
          >
            <MDTypography
              component="a"
              href="#"
              variant="h5"
              fontWeight="medium"
              textTransform="capitalize"
              maxWidth="100px"
            >
              {usergroupSet.name}
            </MDTypography>
          </MDBox>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"} display="flex">
              <MDBox>
                <Tooltip
                  title={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="white"
                      textTransform="capitalize"
                    >
                      {t("edit category")}
                    </MDTypography>
                  }
                >
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton}
                    variant="contained"
                    href={frontendURL.concat("/groupsets/", usergroupSetId, "/settings")}
                  >
                    <Icon sx={iconsStyle}>bookmark</Icon>
                  </IconButton>
                </Tooltip>
              </MDBox>
              <MDBox>
                <Tooltip
                  title={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="white"
                      textTransform="capitalize"
                    >
                      {t("notification")}
                    </MDTypography>
                  }
                >
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton}
                    variant="contained"
                    href={frontendURL.concat("/notifications")}
                  >
                    <Badge badgeContent={countOfInvited} color="info">
                      <Icon sx={iconsStyle}>notifications</Icon>
                    </Badge>
                  </IconButton>
                </Tooltip>
              </MDBox>
              <AccountNavbarItem light={light} />
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of GroupSetNavbar
GroupSetNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the GroupSetNavbar
GroupSetNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default GroupSetNavbar;
