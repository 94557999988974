/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// React
import React, { useEffect } from "react";
import useState from "react-usestateref";
// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import UserInfoDetail from "layouts/profile/userInfo/detail";
// Overview page components
import Header from "layouts/profile/components/Header";

// http-api-tool
import axios from "axios";
// env
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

function Overview() {
  const { t } = useTranslation();
  const [userinfo, setUserinfo] = useState([]);
  const userinfoBackend = axios.create({
    baseURL: `${backendURL}/userinfo/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const getUserinfoByToken = async () => {
    try {
      const response = await userinfoBackend.post("");
      const data = await response.data;
      setUserinfo(data);
      localStorage.setItem("userId", data.id);
    } catch (error) {
      // console.log("error");
    }
  };
  useEffect(() => {
    getUserinfoByToken();
  }, []);

  return (
    <DashboardLayout>
      <Helmet>
        <title>
          {t("profile")} | {appName}
        </title>
      </Helmet>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header avatar={userinfo.avatar} username={userinfo.username} firstName={userinfo.nickname}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              {userinfo.avatar !== undefined && (
                <UserInfoDetail
                  title="profile"
                  description=""
                  userId={userinfo.id}
                  avatar={userinfo.avatar}
                  getUserinfoByToken={getUserinfoByToken}
                  info={{
                    name: `${userinfo.nickname}`,
                    username: `${userinfo.username}`,
                    email: `${userinfo.email}`,
                  }}
                  social={[]}
                  shadow={false}
                />
              )}
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
