/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
// import { Link } from "react-router-dom";
import useState from "react-usestateref";
import { useEffect } from "react";

// i18n
import { useTranslation } from "react-i18next";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
// import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// heic/heic to jpeg
import heic2any from "heic2any";
// generate random filename for upload
import { v4 as uuidv4 } from "uuid";

// http-api-tool
import axios from "axios";
// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;

function UserInfoDetail({ title, description, userId, info, avatar, getUserinfoByToken, shadow }) {
  const labels = [];
  const values = [];
  const { t } = useTranslation();

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {t(label)}: &nbsp;
      </MDTypography>
      <MDTypography
        variant="button"
        fontWeight="regular"
        color="text"
        maxWidth="70%"
        whiteSpace="break-all"
      >
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));

  // Dialog for User edit window
  const [open, setOpen] = useState(false);
  const openUserWindow = () => {
    setOpen(true);
  };
  const closeUserWindow = () => {
    setOpen(false);
  };

  const avatarOriginUrl = backendURL.concat(avatar);
  const [, setAvatarUrl, avatarUrlRef] = useState("");
  useEffect(() => {
    setAvatarUrl(avatarOriginUrl);
  }, []);
  // preview user avatar
  const previewAvatar = (event) => {
    const file = event.target.files[0];
    if (
      file.name.split(".").at(-1) === "heic" ||
      file.name.split(".").at(-1) === "HEIC" ||
      file.name.split(".").at(-1) === "heif" ||
      file.name.split(".").at(-1) === "HEIF"
    ) {
      heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.3,
      }).then((blob) => {
        const fileUrl = URL.createObjectURL(blob);
        setAvatarUrl(fileUrl);
      });
    } else {
      const fileUrl = URL.createObjectURL(file);
      setAvatarUrl(fileUrl);
    }
  };

  // edit UserInfo
  const userBackend = axios.create({
    baseURL: `${backendURL}/users/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const editUserInfo = async () => {
    try {
      const formData = new FormData();
      if (document.getElementById("nickname").value.length >= 2) {
        formData.append("nickname", document.getElementById("nickname").value);
      }
      if (document.getElementById("avatar").files.length > 0) {
        const file = document.getElementById("avatar").files[0];
        if (
          file.name.split(".").at(-1) === "heic" ||
          file.name.split(".").at(-1) === "HEIC" ||
          file.name.split(".").at(-1) === "heif" ||
          file.name.split(".").at(-1) === "HEIF"
        ) {
          await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.3,
          }).then((blob) => {
            formData.append("avatar", blob, uuidv4().concat(".jpg"));
          });
        } else {
          formData.append("avatar", file, uuidv4().concat(".jpg"));
        }
      }
      userBackend
        .patch(userId.toString().concat("/"), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          getUserinfoByToken();
          closeUserWindow();
        });
    } catch (error) {
      // console.log("error", error);
    }
  };

  return (
    <MDBox>
      <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {t(title)}
          </MDTypography>
          <MDButton
            component="a"
            onClick={openUserWindow}
            variant="text"
            color="secondary"
            iconOnly
          >
            <Icon>edit</Icon>
          </MDButton>
        </MDBox>
        <MDBox p={2}>
          <MDBox mb={2} lineHeight={1}>
            <MDTypography variant="button" color="text" fontWeight="light">
              {description}
            </MDTypography>
          </MDBox>
          <MDBox opacity={0.3}>
            <Divider />
          </MDBox>
          <MDBox>{renderItems}</MDBox>
        </MDBox>
      </Card>
      <Dialog
        open={open}
        onClose={closeUserWindow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("profile")}</DialogTitle>
        <MDBox>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <MDBox pb={1} px={3}>
                <MDBox pb={2} display="flex" justifyContent="center">
                  <MDAvatar src={avatarUrlRef.current} size="xxl" />
                </MDBox>
                <MDInput
                  InputProps={{
                    inputProps: { accept: "image/*, .heic, .heif" },
                  }}
                  type="file"
                  id="avatar"
                  onChange={previewAvatar}
                  fullWidth
                />
              </MDBox>
            </Grid>
            <Grid item>
              <MDBox pb={1} px={3}>
                <MDInput id="nickname" label={t("name")} defaultValue={info.name} fullWidth />
              </MDBox>
            </Grid>
            <Grid item>
              <MDBox pt={1} pb={1} px={3}>
                <MDInput
                  id="username"
                  label={t("username")}
                  defaultValue={info.username}
                  fullWidth
                  disabled
                />
              </MDBox>
            </Grid>
            <Grid item>
              <MDBox pb={1} px={3}>
                <MDInput
                  id="email"
                  label={t("email")}
                  defaultValue={info.email}
                  fullWidth
                  disabled
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <DialogActions>
          <MDButton color="info" size="small" onClick={() => editUserInfo()}>
            <Icon>edit</Icon>
            &nbsp;&nbsp;{t("edit")}
          </MDButton>
          <MDButton color="light" size="small" onClick={closeUserWindow}>
            <Icon>arrow_backward</Icon>
            &nbsp;&nbsp;{t("go back")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

// Setting default props for the UserInfoDetail
UserInfoDetail.defaultProps = {
  shadow: true,
};

// Typechecking props for the UserInfoDetail
UserInfoDetail.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  avatar: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  getUserinfoByToken: PropTypes.func.isRequired,
  shadow: PropTypes.bool,
};

export default UserInfoDetail;
