// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
// import { useLocation } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
// import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
// import InputLabel from "@mui/material/InputLabel";

// Material Dashboard 2 React components
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

// heic/heic to jpeg
import heic2any from "heic2any";

// http-api-tool
import axios from "axios";

// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;

function UsergroupForm({ feature, currency, name, logo, noChange }) {
  const { t } = useTranslation();

  // Loading mode (Waiting for Axios before rendering)
  const [isLoading, setLoading] = useState(true);

  // Get Currency/Feature List
  const [features, setFeatures] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const featureListBackend = axios.create({
    baseURL: `${backendURL}/features/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const currencyListBackend = axios.create({
    baseURL: `${backendURL}/currencies/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getFeatureAndCurrencyList = async () => {
    try {
      const featureResponse = await featureListBackend.get("");
      const featureResponseData = await featureResponse.data;
      setFeatures(featureResponseData);
      const currencyResponse = await currencyListBackend.get("");
      const currencyResponseData = await currencyResponse.data;
      setCurrencies(currencyResponseData);
      setLoading(false);
    } catch (error) {
      // console.log("error", error);
    }
  };

  useEffect(() => {
    getFeatureAndCurrencyList();
  }, []);

  // set usergroup logo
  const [usergroupLogo, setUsergroupLogo] = useState(logo);
  // preview usergroup logo
  const previewLogo = (event) => {
    const file = event.target.files[0];
    if (
      file.name.split(".").at(-1) === "heic" ||
      file.name.split(".").at(-1) === "HEIC" ||
      file.name.split(".").at(-1) === "heif" ||
      file.name.split(".").at(-1) === "HEIF"
    ) {
      heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.3,
      }).then((blob) => {
        const fileUrl = URL.createObjectURL(blob);
        setUsergroupLogo(fileUrl);
      });
    } else {
      const fileUrl = URL.createObjectURL(file);
      setUsergroupLogo(fileUrl);
    }
  };

  if (isLoading) {
    return (
      <MDBox py={3}>
        <Card />
      </MDBox>
    );
  }

  return (
    <MDBox py={1}>
      <Card sx={{ width: 280, margin: "auto" }}>
        <MDBox py={2}>
          <Grid container spacing={2} direction="column" alignItems="center">
            <Grid item>
              <MDBox pb={1} px={3}>
                <MDBox pb={2} display="flex" justifyContent="center">
                  <MDBox
                    component="img"
                    sx={{
                      height: { xs: 140, md: 140, lg: 140 },
                      width: { xs: 140, md: 140, lg: 140 },
                      objectFit: "cover",
                    }}
                    src={usergroupLogo}
                    borderRadius="lg"
                    mb={-1}
                  />
                </MDBox>
                <MDInput
                  InputProps={{
                    inputProps: { accept: "image/*, .heic, .heif" },
                  }}
                  type="file"
                  id="usergroupLogo"
                  onChange={previewLogo}
                  sx={{ width: 200 }}
                />
              </MDBox>
            </Grid>
            <Grid item>
              <MDInput
                type="text"
                id="feature"
                label={t("group feature")}
                key={feature}
                defaultValue={feature}
                InputProps={{ sx: { height: 44.125 } }}
                sx={{ width: 200 }}
                InputLabelProps={{ style: { padding: 1 } }}
                select
              >
                {noChange ? (
                  <MenuItem key={feature} value={feature}>
                    {t(feature)}
                  </MenuItem>
                ) : (
                  features.map((fea) => {
                    if (fea.name !== "income" && fea.name !== "transfer") {
                      return (
                        <MenuItem key={fea.id} value={fea.name}>
                          {t(fea.name)}
                        </MenuItem>
                      );
                    }
                    return null;
                  })
                )}
              </MDInput>
            </Grid>
            <Grid item>
              {noChange ? (
                <Autocomplete
                  id="currency"
                  disableClearable
                  key={currency}
                  defaultValue={currency}
                  options={[currency]}
                  size="small"
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      label={t("currency")}
                      sx={{ width: 200 }}
                      InputLabelProps={{ style: { padding: 1 } }}
                    />
                  )}
                />
              ) : (
                <Autocomplete
                  id="currency"
                  disableClearable
                  key={currency}
                  defaultValue={currency}
                  options={currencies.map((curr) => curr.code)}
                  size="small"
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      label={t("currency")}
                      sx={{ width: 200 }}
                      InputLabelProps={{ style: { padding: 1 } }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item>
              <MDInput
                type="text"
                id="name"
                label={t("group name")}
                key={name}
                defaultValue={name}
                // variant="standard"
                sx={{ width: 200 }}
                InputLabelProps={{ style: { padding: 1 } }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}

// Setting default values for the props of Usergroup
UsergroupForm.defaultProps = {
  noChange: false,
  feature: "",
  currency: "TWD",
  name: "",
};

// Typechecking props for the Usergroup
UsergroupForm.propTypes = {
  feature: PropTypes.string,
  currency: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string.isRequired,
  noChange: PropTypes.bool,
};

export default UsergroupForm;
