// react
import { useEffect, useCallback, useMemo } from "react";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// reactflow
import ReactFlow, {
  addEdge,
  MarkerType,
  Background,
  useNodesState,
  useEdgesState,
} from "reactflow";
import "reactflow/dist/style.css";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// i18n
import { useTranslation } from "react-i18next";
import UserNode from "components/ReactFlow/UserNode";
import GroupNode from "components/ReactFlow/GroupNode";

// remove Reactflow logo
const proOptions = { hideAttribution: true };

function BalanceFlowChart({ usergroup, balanceAllAmount, setAvatar, setNickname }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();

  const nodeTypes = useMemo(
    () => ({
      userNode: UserNode,
      groupNode: GroupNode,
    }),
    []
  );

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const setInitialNodes = () => {
    const initialNodes = [];
    let i = 0;
    let y1 = 0;
    let y2 = 0;
    while (balanceAllAmount[i]) {
      if (balanceAllAmount[i].amount < 0) {
        const memberNode = {
          id: balanceAllAmount[i].user,
          position: { x: 20, y: 100 * y1 },
          type: "userNode",
          data: {
            name: setNickname(balanceAllAmount[i].user),
            avatar: setAvatar(balanceAllAmount[i].user),
          },
          // data: { label: balanceAllAmount[i].user },
          // sourcePosition: "right",
        };
        initialNodes.push(memberNode);
        y1 += 1;
      }
      if (balanceAllAmount[i].amount > 0) {
        const memberNode = {
          id: balanceAllAmount[i].user,
          position: { x: 400, y: 100 * y2 },
          type: "userNode",
          data: {
            name: setNickname(balanceAllAmount[i].user),
            avatar: setAvatar(balanceAllAmount[i].user),
          },
        };
        initialNodes.push(memberNode);
        y2 += 1;
      }
      i += 1;
    }
    const groupNode = {
      id: usergroup.name,
      position: { x: 200, y: 60 },
      type: "groupNode",
      data: {
        currency: usergroup.currency,
        feature: usergroup.feature,
        name: usergroup.name,
        avatar: usergroup.logo,
      },
      // data: { label: usergroup.name },
      // sourcePosition: "right",
      // targetPosition: "left",
    };
    if (initialNodes.length > 0) {
      initialNodes.push(groupNode);
    }
    // return initialNodes;
    setNodes(initialNodes);
  };

  const setInitialEdges = () => {
    const initialEdges = [];
    let i = 0;
    while (balanceAllAmount[i]) {
      if (balanceAllAmount[i].amount < 0) {
        const memberEdge = {
          id: usergroup.name.concat("-", balanceAllAmount[i].user),
          source: balanceAllAmount[i].user,
          target: usergroup.name,
          label: Math.abs(balanceAllAmount[i].amount).toLocaleString(),
          labelStyle: { fontSize: 16, fontWeight: 1000 },
          animated: true,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 15,
            height: 15,
            color: "#000000",
          },
          style: {
            strokeWidth: 2,
            stroke: "#000000",
          },
        };
        initialEdges.push(memberEdge);
      }
      if (balanceAllAmount[i].amount > 0) {
        const memberEdge = {
          id: usergroup.name.concat("-", balanceAllAmount[i].user),
          source: usergroup.name,
          target: balanceAllAmount[i].user,
          label: Math.abs(balanceAllAmount[i].amount).toLocaleString(),
          labelStyle: { fontSize: 16, fill: "red", fontWeight: 1000 },
          animated: true,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 15,
            height: 15,
            color: "#FF0072",
          },
          style: {
            strokeWidth: 2,
            stroke: "#FF0072",
          },
        };
        initialEdges.push(memberEdge);
      }
      i += 1;
    }
    // return initialEdges;
    setEdges(initialEdges);
  };

  useEffect(() => {
    setInitialNodes();
    setInitialEdges();
  }, [usergroup, balanceAllAmount]);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  return (
    <MDBox>
      {edges.length > 1 && (
        <MDBox>
          <MDBox
            sx={{
              height: { xs: 220, md: 300, lg: 400 },
              width: "100%",
            }}
            borderRadius="lg"
            variant="gradient"
            shadow="lg"
            bgColor={darkMode ? "grey-900" : "white"}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              proOptions={proOptions}
              fitView
            >
              <Background />
            </ReactFlow>
          </MDBox>
          <MDBox mx={1} mt={-3} display="flex" justifyContent="flex-end">
            <MDTypography
              variant="button"
              fontWeight="medium"
              align="center"
              textTransform="capitalize"
            >
              {t(usergroup.feature)} | {usergroup.currency}
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}

// Setting default values for the props of BalanceFlowChart
BalanceFlowChart.defaultProps = {};

// Typechecking props for the BalanceFlowChart
BalanceFlowChart.propTypes = {
  balanceAllAmount: PropTypes.instanceOf(Array).isRequired,
  usergroup: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    feature: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  setAvatar: PropTypes.func.isRequired,
  setNickname: PropTypes.func.isRequired,
};

export default BalanceFlowChart;
