/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// i18n
import { useTranslation } from "react-i18next";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication pages components
import Footer from "layouts/authentication/components/Footer";

function BasicLayout({ image, children }) {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="20%">
          <MDBox position="absolute" textAlign="center">
            <MDTypography variant="h3" fontWeight="medium" sx={{ color: "#0DABF3" }} mt={1}>
              AllBill
            </MDTypography>
            <MDTypography variant="h5" fontWeight="medium" sx={{ color: "#0DABF3" }} mt={1}>
              {t("Split bills - simple, fast and clear")}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="10%">
          <MDBox position="absolute">
            <MDTypography variant="h6" sx={{ color: "#B8EBFF" }} mt={1}>
              1. {t("Create a group")}
            </MDTypography>
            <MDTypography variant="h6" sx={{ color: "#B8EBFF" }} mt={1}>
              2. {t("Invite friends")}
            </MDTypography>
            <MDTypography variant="h6" sx={{ color: "#B8EBFF" }} mt={1}>
              3. {t("Start managing your shared expenses")}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="70%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      <Footer light />
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
