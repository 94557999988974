// refer to the following:
// 1. www.creative-tim.com

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
// import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/favicon-128x128.ico";
import brandDark from "assets/images/favicon-128x128-dark.ico";

// http-api-tool
import axios from "axios";

// env
const backendURL = process.env.REACT_APP_BACKENDURL;

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, sidenavColor, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // deconstruct routes
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  // navigate
  const navigate = useNavigate();

  // Check user is signin or not
  const userinfoBackend = axios.create({
    baseURL: `${backendURL}/userinfo/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const checkUser = async () => {
    try {
      const getUserByToken = await userinfoBackend.post("", {});
      if (getUserByToken !== null) {
        // isSignedIn
      }
    } catch (error) {
      // notSignedIn
      navigate("/authentication/sign-in");
    }
  };

  useEffect(() => {
    if (
      pathname !== "/about" &&
      pathname !== "/about/" &&
      pathname !== "/tw/about" &&
      pathname !== "/tw/about/" &&
      pathname !== "/en/about" &&
      pathname !== "/en/about/" &&
      pathname !== "/authentication/sign-in" &&
      pathname !== "/authentication/sign-in/"
    ) {
      checkUser();
    }
  }, []);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {pathname !== "/about" &&
        pathname !== "/about/" &&
        pathname !== "/tw/about" &&
        pathname !== "/tw/about/" &&
        pathname !== "/en/about" &&
        pathname !== "/en/about/" &&
        pathname !== "/authentication/sign-in" &&
        pathname !== "/authentication/sign-in/" && (
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="AllBill"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/groups" />} />
      </Routes>
    </ThemeProvider>
  );
}
