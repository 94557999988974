// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useLocation, useNavigate } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// heic/heic to jpeg
import heic2any from "heic2any";
// generate random filename for upload
import { v4 as uuidv4 } from "uuid";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import GroupNavbar from "examples/Navbars/GroupNavbar";
import Footer from "examples/Footer";
// UsergroupForm components
import UsergroupForm from "layouts/usergroups/components/UsergroupForm";

// http-api-tool
import axios from "axios";
// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

function UsergroupDetail() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  const usergroupId = location.pathname.split("/")[2];

  // Loading mode (Waiting for Axios before rendering)
  const [isLoading, setLoading] = useState(true);
  const [usergroup, setUsergroup] = useState("");

  const usergroupBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  // Get Group and Bill Detail
  const getUsergroupDetail = async () => {
    try {
      const usergroupResponse = await usergroupBackend.get("");
      const usergroupResponseData = await usergroupResponse.data;
      setUsergroup(usergroupResponseData);
      setLoading(false);
    } catch (error) {
      navigate("/groups");
    }
  };

  useEffect(() => {
    getUsergroupDetail();
  }, []);

  // Alert for Create usergroup
  const [successSwitch, setSuccessSwitch] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorSwitch, setErrorSwitch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleClose = () => {
    setSuccessSwitch(false);
    setErrorSwitch(false);
  };

  // Tooltip for remove group button
  const [removeTooltip, setRemoveTooltip] = useState(false);
  const handleRemoveTooltipClose = () => {
    setRemoveTooltip(false);
  };
  const handleRemoveTooltipOpen = () => {
    setRemoveTooltip(true);
  };
  // Edit Usergroup detail
  const editUsergroupDetail = async () => {
    try {
      if (
        document.getElementById("feature").innerHTML === usergroup.feature &&
        document.getElementById("currency").value === usergroup.currency &&
        document.getElementById("name").value === usergroup.name &&
        document.getElementById("usergroupLogo").files.length === 0
      ) {
        setErrorSwitch(true);
        setErrorMessage("Group edited error: No change.");
      } else {
        const formData = new FormData();
        formData.append(
          "feature",
          t(document.getElementById("feature").innerHTML, { lng: "zhTWReverse" })
        );
        formData.append("currency", document.getElementById("currency").value);
        formData.append("name", document.getElementById("name").value);
        if (document.getElementById("usergroupLogo").files.length > 0) {
          const file = document.getElementById("usergroupLogo").files[0];
          if (
            file.name.split(".").at(-1) === "heic" ||
            file.name.split(".").at(-1) === "HEIC" ||
            file.name.split(".").at(-1) === "heif" ||
            file.name.split(".").at(-1) === "HEIF"
          ) {
            await heic2any({
              blob: file,
              toType: "image/jpeg",
              quality: 0.3,
            }).then((blob) => {
              formData.append("logo", blob, uuidv4().concat(".jpg"));
            });
          } else {
            formData.append("logo", file, uuidv4().concat(".jpg"));
          }
        }
        await usergroupBackend
          .put("", formData)
          .then(() => {
            setSuccessSwitch(true);
            setSuccessMessage(t("group edited successfully"));
          })
          .then(() => {
            setTimeout(() => {
              navigate(`/groups/${usergroupId}/bills`);
            }, 1000);
          });
      }
    } catch (error) {
      // console.log("error", error);
      setErrorSwitch(true);
      setErrorMessage(t("required fields are not filled in"));
    }
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <GroupNavbar />
        ...
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>
          {usergroup.name} | {appName}
        </title>
      </Helmet>
      <GroupNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} md={10} lg={8}>
            <Card>
              <MDBox pt={2} pb={1}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      {t("group setting")}
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <UsergroupForm
                feature={usergroup.feature}
                currency={usergroup.currency}
                name={usergroup.name}
                logo={usergroup.logo}
                noChange
              />
              <Snackbar
                open={successSwitch}
                autoHideDuration={10000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                  {successMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                open={errorSwitch}
                autoHideDuration={10000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                  {errorMessage}
                </Alert>
              </Snackbar>
              <MDBox pt={1} pb={5}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={3} md={3} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={() => editUsergroupDetail()}
                      fullWidth
                    >
                      <Icon>edit</Icon>
                      &nbsp;{t("edit")}
                    </MDButton>
                  </Grid>
                </Grid>
                <MDBox py={1} />
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={3} md={3} lg={3}>
                    <ClickAwayListener onClickAway={handleRemoveTooltipClose}>
                      <Tooltip
                        open={removeTooltip}
                        title={t(
                          "If you want to delete this group, you can do so by removing all group members."
                        )}
                      >
                        <MDButton
                          variant="gradient"
                          color="error"
                          size="small"
                          onClick={handleRemoveTooltipOpen}
                          fullWidth
                        >
                          <Icon>delete</Icon>
                          &nbsp;{t("delete group")}
                        </MDButton>
                      </Tooltip>
                    </ClickAwayListener>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UsergroupDetail;
