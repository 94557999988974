// timeZone
import moment from "moment-timezone";
// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useNavigate, useParams } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import GroupNavbar from "examples/Navbars/GroupNavbar";
import Footer from "examples/Footer";

// BillListUnit components
import Bill from "layouts/usergroups/bills/components/BillListUnit";
// BalanceFlowChart
import BalanceFlowChart from "layouts/usergroups/bills/components/BalanceFlowChart";

// http-api-tool
import axios from "axios";

// env
const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

// timeZone of current location
const cLTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

function BillList() {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [bills, setBills] = useState([]);
  const billsBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${params.id}/bills`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const [usergroupLoading, setUsergroupLoading] = useState(true);
  const [usergroup, setUsergroup, usergroupRef] = useState("");
  const usergroupBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${params.id}/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getBackendData = async () => {
    try {
      const billsResponse = await billsBackend.get("/?ordering=-datetime_created");
      const billsResponseData = await billsResponse.data;
      setBills(billsResponseData);
      const usergroupResponse = await usergroupBackend.get("/?ordering=-datetime_created");
      const usergroupResponseData = await usergroupResponse.data;
      setUsergroup(usergroupResponseData);
      setUsergroupLoading(false);
    } catch (error) {
      navigate("/groups");
    }
  };

  // Get balance_all_amount
  const [balanceAllAmount, setBalanceAllAmount] = useState([]);
  const balanceAllAmountBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${params.id}/bills/balance_all_amount/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const getBalanceAllAmount = async () => {
    try {
      const balanceAllAmountResponse = await balanceAllAmountBackend.get("");
      const balanceAllAmountResponseData = await balanceAllAmountResponse.data;
      setBalanceAllAmount(balanceAllAmountResponseData);
    } catch (error) {
      // console.log("error");
    }
  };

  // mark_all_as_read
  const notificationsBackend = axios.create({
    baseURL: `${backendURL}/notifications/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const markAllAsRead = async () => {
    try {
      await notificationsBackend.patch("mark_all_as_read/", { usergroup: params.id });
    } catch (error) {
      // console.log("error");
    }
  };

  // set avatar & Fisrt_name
  const setAvatar = (username) => {
    const userObj = usergroupRef.current.members.find((member) => member.username === username);
    const { avatar } = userObj;
    return avatar;
  };
  const setNickname = (username) => {
    const userObj = usergroupRef.current.members.find((member) => member.username === username);
    return userObj.nickname;
  };

  useEffect(() => {
    getBackendData();
    getBalanceAllAmount();
    markAllAsRead();
  }, []);

  let displayDate = moment().tz(cLTZ).format("YYYY/MM/DD");

  return (
    <DashboardLayout>
      {usergroupLoading === false && (
        <Helmet>
          <title>
            {usergroup.name} | {appName}
          </title>
        </Helmet>
      )}
      <GroupNavbar />
      {usergroupLoading === false && bills.length === 0 && (
        <MDBox pt={10} pb={10} px={6}>
          <Grid container spacing={5} direction="column" alignItems="center">
            <Grid item>
              <MDTypography variant="body1" align="center">
                {t("now, let's invite your friends to join the group")}
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox
                component="a"
                color="secondary"
                href={frontendURL.concat("/groups/", params.id, "/members")}
              >
                <Icon fontSize="large">person_add_alt1</Icon>
              </MDBox>
            </Grid>
            <Grid item>
              <MDTypography variant="body1">{t("or try to add a record")}</MDTypography>
            </Grid>
            <Grid item>
              <MDBox
                component="a"
                color="secondary"
                href={frontendURL.concat("/groups/", params.id, "/bills/create")}
              >
                <Icon fontSize="large">note_add</Icon>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <MDBox
        pt={2}
        pb={3}
        sx={{
          mx: { xs: 0.5, md: 2, lg: 3 },
        }}
      >
        <MDBox pb={1}>
          {usergroupLoading === false && (
            <BalanceFlowChart
              usergroup={usergroup}
              balanceAllAmount={balanceAllAmount}
              setAvatar={setAvatar}
              setNickname={setNickname}
            />
          )}
        </MDBox>
        <MDBox mb={-1}>
          <MDTypography variant="h6" color="text" fontWeight="medium">
            {bills.length !== 0 &&
              moment(bills[0].datetime).tz(cLTZ).format("YYYY/MM/DD") ===
                moment().tz(cLTZ).format("YYYY/MM/DD") &&
              t("today")}
          </MDTypography>
        </MDBox>
        {usergroupLoading === false &&
          bills.map((bill) => {
            const username = localStorage.getItem("username");
            const myPayment = bill.payment_record.find((payment) => payment.payer === username);
            const myPaymentAmount = myPayment.amount;
            const myPaymentForeignAmount = myPayment.foreign_amount;
            const myShare = bill.share_record.find((share) => share.sharer === username);
            const myShareAmount = myShare.amount;
            const myShareForeignAmount = myShare.foreign_amount;

            const dataDate = moment(bill.datetime).tz(cLTZ).format("YYYY/MM/DD");

            if (dataDate !== displayDate) {
              displayDate = dataDate;
              return (
                <MDBox>
                  <MDBox mt={1} mb={-1}>
                    <MDTypography variant="h6" color="text" fontWeight="medium">
                      {moment(bill.datetime).tz(cLTZ).format("YYYY/MM/DD | ddd")}
                    </MDTypography>
                  </MDBox>
                  <Bill
                    key={bill.id}
                    type={bill.type}
                    name={bill.name}
                    remark={bill.remark}
                    category={bill.category}
                    currency={bill.currency}
                    usergroupCurrency={usergroup.currency}
                    amount={bill.amount}
                    foreignAmount={bill.foreign_amount}
                    myPaymentAmount={myPaymentAmount}
                    myPaymentForeignAmount={myPaymentForeignAmount}
                    myShareAmount={myShareAmount}
                    myShareForeignAmount={myShareForeignAmount}
                    href={frontendURL.concat("/groups/", params.id, "/bills/", bill.id)}
                  />
                </MDBox>
              );
            }
            if (dataDate === displayDate) {
              return (
                <MDBox>
                  <Bill
                    key={bill.id}
                    type={bill.type}
                    name={bill.name}
                    remark={bill.remark}
                    category={bill.category}
                    currency={bill.currency}
                    usergroupCurrency={usergroup.currency}
                    amount={bill.amount}
                    foreignAmount={bill.foreign_amount}
                    myPaymentAmount={myPaymentAmount}
                    myPaymentForeignAmount={myPaymentForeignAmount}
                    myShareAmount={myShareAmount}
                    myShareForeignAmount={myShareForeignAmount}
                    href={frontendURL.concat("/groups/", params.id, "/bills/", bill.id)}
                  />
                </MDBox>
              );
            }
            return null;
          })}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BillList;
