// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useLocation } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";

// customize checkbox
import { BpIcon, BpCheckedIcon } from "components/MUICheckbox/style";

// customize HelperText
import "./BillForm.css";

// Numeric Format
import AmountFormat from "layouts/usergroups/bills/components/AmountFormat";
import ExchangeRateFormat from "layouts/usergroups/bills/components/ExchangeRateFormat";
import PercentFormat from "layouts/usergroups/bills/components/PercentFormat";

// Material Dashboard 2 React components
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { useMaterialUIController } from "context";

// http-api-tool
import axios from "axios";

// TimeForm
import TimeForm from "layouts/usergroups/bills/components/TimeForm";

// emoji mart
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;

function BillForm({ usergroup, memberships, bill, billType, setValidForm }) {
  const { t } = useTranslation();

  const location = useLocation();
  const locationParam = location.pathname.split("/")[4];

  // Dark mode
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // set boardColor
  const setBoardColor = () => {
    if (billType === "expense") {
      return "secondary.light";
    }
    if (billType === "income") {
      return "primary.light";
    }
    return "";
  };

  // Tooltip for Pay, Receive, Share Label
  const [payTooltip, setPayTooltip] = useState(false);
  const [receiveTooltip, setReceiveTooltip] = useState(false);
  const [shareTooltip, setShareTooltip] = useState(false);

  const handlePayTooltipClose = () => {
    setPayTooltip(false);
  };
  const handleReceiveTooltipClose = () => {
    setReceiveTooltip(false);
  };
  const handleShareTooltipClose = () => {
    setShareTooltip(false);
  };

  const handlePayTooltipOpen = () => {
    setPayTooltip(true);
  };
  const handleReceiveTooltipOpen = () => {
    setReceiveTooltip(true);
  };
  const handleShareTooltipOpen = () => {
    setShareTooltip(true);
  };
  // payer list switch
  const [showPayerListSwitch, setShowPayerListSwitch] = useState(false);
  const handleShowPayerListSwitch = (event) => {
    setShowPayerListSwitch(event.target.checked);
  };

  // get member
  const currentMembers = memberships.filter((membership) => membership.status === "member");
  const removedMembers = memberships.filter((membership) => membership.status === "removed");

  // get the member satisfy: 1. current member 2. on the bill
  const getCurrentOnBillMember = () => {
    let i = 0;
    const currentOnBillMember = [];
    while (currentMembers[i]) {
      const username = currentMembers[i].user;
      if (bill.share_record.filter((share) => share.sharer === username).length !== 0) {
        currentOnBillMember.push(currentMembers[i]);
      }
      i += 1;
    }
    return currentOnBillMember;
  };
  // get the member satisfy: 1. removed member 2. on the bill
  const getRemovedOnBillMember = () => {
    let i = 0;
    const removedOnBillMember = [];
    if (removedMembers.length > 0) {
      while (removedMembers[i]) {
        const username = removedMembers[i].user;
        if (bill.share_record.filter((share) => share.sharer === username).length !== 0) {
          removedOnBillMember.push(removedMembers[i]);
        }
        i += 1;
      }
      return removedOnBillMember;
    }
    return [];
  };

  // Check member is a new member
  const checkMemberInBill = (username) => {
    const isNewMember = bill.payment_record.find((payment) => payment.payer === username);
    return isNewMember;
  };

  // Get the pay, & share Amount
  const getPayAmount = (username) => {
    const payAmount = bill.payment_record.find((payment) => payment.payer === username).amount;
    if (payAmount !== "0.00") {
      return Math.abs(payAmount);
    }
    return null;
  };

  const getPayForeignAmount = (username) => {
    const payForeignAmount = bill.payment_record.find(
      (payment) => payment.payer === username
    ).foreign_amount;
    if (payForeignAmount !== "0.00" && payForeignAmount !== null) {
      return Math.abs(payForeignAmount);
    }
    return null;
  };

  const getShareAmount = (username) => {
    const shareAmount = bill.share_record.find((share) => share.sharer === username).amount;
    if (shareAmount !== "0.00") {
      return Math.abs(shareAmount);
    }
    return null;
  };

  const getShareForeignAmount = (username) => {
    const shareForeignAmount = bill.share_record.find(
      (share) => share.sharer === username
    ).foreign_amount;
    if (shareForeignAmount !== "0.00" && shareForeignAmount !== null) {
      return Math.abs(shareForeignAmount);
    }
    return null;
  };

  // handle values
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [remark, setRemark] = useState("");
  const [amount, setAmount, amountRef] = useState("");
  const [currency, setCurrency] = useState("");
  const [currencyExchangeRate, setCurrencyExchangeRate] = useState("1");
  const [foreignAmount, setForeignAmount, foreignAmountRef] = useState("");
  const [payAmountList, setPayAmountList, payAmountListRef] = useState([]);
  const [payForeignAmountList, setPayForeignAmountList] = useState([]);
  const [shareAmountList, setShareAmountList, shareAmountListRef] = useState([]);
  const [shareForeignAmountList, setShareForeignAmountList] = useState([]);
  const [payPercentList, setPayPercentList] = useState([]);
  const [sharePercentList, setSharePercentList] = useState([]);
  const [sharePercentSum, setSharePercentSum] = useState(0);

  // generating a series id for counting "member (status=member(&removed))"
  let payerCount = 0;
  let sharerCount = 0;
  // number for frequentlyUsedName
  let frequentlyUsedNameNumber = 0;

  const setOriginBillInfo = () => {
    setCategory(bill.category);
    setName(bill.name);
    setRemark(bill.remark);
  };

  const setOriginAmount = () => {
    if (locationParam === "create") {
      setAmount("");
    } else {
      setAmount(Math.abs(bill.amount).toString());
    }
  };

  const setOriginForeignAmount = () => {
    if (locationParam === "create") {
      setForeignAmount("");
    } else {
      setForeignAmount(Math.abs(bill.foreign_amount).toString());
    }
  };

  const setOriginCurrency = () => {
    if (locationParam === "create") {
      setCurrency(usergroup.currency);
    } else {
      setCurrency(bill.currency);
    }
  };

  const setOriginCurrencyExchangeRate = () => {
    if (locationParam === "create") {
      setCurrencyExchangeRate("1");
    } else {
      setCurrencyExchangeRate(Math.abs(bill.amount / bill.foreign_amount).toString());
    }
  };

  const setOriginAmountPercentList = () => {
    if (locationParam === "create") {
      let j = 1;
      const originAmountPercentList = [];
      while (j <= currentMembers.length) {
        originAmountPercentList.push("");
        j += 1;
      }
      setPayAmountList(originAmountPercentList);
      setShareAmountList(originAmountPercentList);
      setPayForeignAmountList(originAmountPercentList);
      setShareForeignAmountList(originAmountPercentList);
      setPayPercentList(originAmountPercentList);
      setSharePercentList(originAmountPercentList);
    } else {
      let k = 1;
      const originPayAmountList = [];
      const originShareAmountList = [];
      const originPayForeignAmountList = [];
      const originShareForeignAmountList = [];
      const originPayPercentList = [];
      const originSharePercentList = [];
      while (k <= getCurrentOnBillMember().length) {
        const username = getCurrentOnBillMember()[k - 1].user;
        const originPayAmount = getPayAmount(username);
        originPayAmountList.push(originPayAmount);
        const originShareAmount = getShareAmount(username);
        originShareAmountList.push(originShareAmount);
        const originPayForeignAmount = getPayForeignAmount(username);
        originPayForeignAmountList.push(originPayForeignAmount);
        const originShareForeignAmount = getShareForeignAmount(username);
        originShareForeignAmountList.push(originShareForeignAmount);
        const originPayPercent = Math.abs(
          parseFloat(((getPayAmount(username) * 100) / bill.amount).toFixed(2))
        );
        originPayPercentList.push(originPayPercent);
        const originSharePercent = Math.abs(
          parseFloat(((getShareAmount(username) * 100) / bill.amount).toFixed(2))
        );
        originSharePercentList.push(originSharePercent);
        k += 1;
      }
      let m = 1;
      while (m <= getRemovedOnBillMember().length) {
        const username = getRemovedOnBillMember()[m - 1].user;
        const originPayAmount = getPayAmount(username);
        originPayAmountList.push(originPayAmount);
        const originShareAmount = getShareAmount(username);
        originShareAmountList.push(originShareAmount);
        const originPayForeignAmount = getPayForeignAmount(username);
        originPayForeignAmountList.push(originPayForeignAmount);
        const originShareForeignAmount = getShareForeignAmount(username);
        originShareForeignAmountList.push(originShareForeignAmount);
        const originPayPercent = Math.abs(
          parseFloat(((getPayAmount(username) * 100) / bill.amount).toFixed(2))
        );
        originPayPercentList.push(originPayPercent);
        const originSharePercent = Math.abs(
          parseFloat(((getShareAmount(username) * 100) / bill.amount).toFixed(2))
        );
        originSharePercentList.push(originSharePercent);
        m += 1;
      }
      setPayAmountList(originPayAmountList);
      setShareAmountList(originShareAmountList);
      setPayForeignAmountList(originPayForeignAmountList);
      setShareForeignAmountList(originShareForeignAmountList);
      setPayPercentList(originPayPercentList);
      setSharePercentList(originSharePercentList);
    }
  };

  // Loading mode (Waiting for Axios before rendering)
  const [isLoading, setLoading] = useState(true);

  // Get Currency/Category List
  const [currencies, setCurrencies] = useState([]);
  const [currencyExchangeRates, setCurrencyExchangeRates] = useState({});
  const [categories, setCategories] = useState([]);
  const [frequentlyUsedNames, setFrequentlyUsedNames] = useState([]);

  const currencyListBackend = axios.create({
    baseURL: `${backendURL}/currencies/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const currencyExchangeRatesBackend = axios.create({
    baseURL: `${backendURL}/currencyexchangerates/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const categoryListBackend = axios.create({
    baseURL: `${backendURL}/categories/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const billFrequentlyUsedNamesBackend = axios.create({
    baseURL: `${backendURL}/bills/frequently_used_names/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getCurrencyCategoryList = async () => {
    try {
      const currencyResponse = await currencyListBackend.get("");
      const currencyResponseData = await currencyResponse.data;
      setCurrencies(currencyResponseData);
      const ratesResponse = await currencyExchangeRatesBackend.get("get_latest_rates");
      const ratesResponseData = await ratesResponse.data;
      setCurrencyExchangeRates(ratesResponseData.rates);
      const categoryResponse = await categoryListBackend.get("");
      const categoryResponseData = await categoryResponse.data;
      setCategories(categoryResponseData);
      setLoading(false);
    } catch (error) {
      // console.log("error");
    }
  };

  const getBillFrequentlyUsedNames = async () => {
    try {
      const frequentlyUsedNamesResponse = await billFrequentlyUsedNamesBackend.get("");
      const frequentlyUsedNamesResponseData = await frequentlyUsedNamesResponse.data;
      setFrequentlyUsedNames(frequentlyUsedNamesResponseData);
    } catch (error) {
      // console.log("error");
    }
  };

  useEffect(() => {
    getCurrencyCategoryList();
    if (locationParam === "create") {
      getBillFrequentlyUsedNames();
    }
    setOriginBillInfo();
    setOriginAmount();
    setOriginCurrency();
    if (bill.currency !== usergroup.currency) {
      setOriginCurrencyExchangeRate();
      setOriginForeignAmount();
    }
    setOriginAmountPercentList();
  }, []);

  const setAvatar = (username) => {
    const userObj = usergroup.members.find((member) => member.username === username);
    return userObj.avatar;
  };
  const setNickname = (username) => {
    const userObj = usergroup.members.find((member) => member.username === username);
    return userObj.nickname;
  };

  // onChange Event for Payment and Share Percentage
  const setPayPercentage = () => {
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));
    const payPercentArray = [...payPercentList];
    let x = 1;
    while (x <= payerCount) {
      const payAmount = parseFloat(payAmountListRef.current[x - 1]);
      const payPercentNumber = parseFloat(((payAmount / totalAmount) * 100).toFixed(2));
      payPercentArray[x - 1] = Math.min(payPercentNumber, 100);
      x += 1;
    }
    setPayPercentList(payPercentArray);
  };

  const setSharePercentage = () => {
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));
    const sharePercentArray = [...sharePercentList];
    let y = 1;
    while (y <= sharerCount) {
      const shareAmount = parseFloat(shareAmountListRef.current[y - 1]);
      const sharePercentNumber = parseFloat(((shareAmount / totalAmount) * 100).toFixed(2));
      sharePercentArray[y - 1] = Math.min(sharePercentNumber, 100);
      y += 1;
    }
    // handle individual share percent
    setSharePercentList(sharePercentArray);
    // handle sum share percent
    let sum = 0;
    sharePercentArray.map((sharePercent) => {
      let sharePercentNumber = 0;
      if (sharePercent === "") {
        sharePercentNumber = 0;
      } else {
        sharePercentNumber = parseFloat(sharePercent);
      }
      sum += sharePercentNumber;
      return sum;
    });
    setSharePercentSum(sum);
  };

  // handle AA of share amount
  const setAverageShareAmount = () => {
    // get number of members
    let currentMemberCount = 0;
    if (locationParam === "create") {
      currentMemberCount = currentMembers.length;
    }
    if (locationParam !== "create") {
      currentMemberCount = getCurrentOnBillMember().length + getRemovedOnBillMember().length;
    }
    // set average share amounts
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));
    const average = parseFloat((totalAmount / currentMemberCount).toFixed(2));
    const shareAmountArray = [...shareAmountList];
    if (totalAmount === average * currentMemberCount) {
      let i = 1;
      while (i <= currentMemberCount) {
        shareAmountArray[i - 1] = average;
        i += 1;
      }
    } else {
      const remainder = totalAmount - average * (currentMemberCount - 1);
      shareAmountArray[0] = remainder;
      let i = 2;
      while (i <= currentMemberCount) {
        shareAmountArray[i - 1] = average;
        i += 1;
      }
    }
    setShareAmountList(shareAmountArray);

    // set average foreign share amount
    if (currency !== usergroup.currency) {
      const totalForeignAmount = parseFloat(foreignAmountRef.current.replace(/,/g, ""));
      const foreignAverage = parseFloat((totalForeignAmount / currentMemberCount).toFixed(2));
      const shareForeignAmountArray = [...shareForeignAmountList];
      if (totalForeignAmount === foreignAverage * currentMemberCount) {
        let i = 1;
        while (i <= currentMemberCount) {
          shareForeignAmountArray[i - 1] = foreignAverage;
          i += 1;
        }
      } else {
        const foreignRemainder = totalForeignAmount - foreignAverage * (currentMemberCount - 1);
        shareForeignAmountArray[0] = foreignRemainder;
        let i = 2;
        while (i <= currentMemberCount) {
          shareForeignAmountArray[i - 1] = foreignAverage;
          i += 1;
        }
      }
      setShareForeignAmountList(shareForeignAmountArray);
    }

    // set share percentage
    setSharePercentage();
  };

  const checkAverageShareAmount = () => {
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));
    const average = parseFloat((totalAmount / sharerCount).toFixed(2));
    let i = 1;
    let count = 0;
    const shareAmountArray = [...shareAmountListRef.current];
    while (i <= sharerCount) {
      if (parseFloat(shareAmountArray[i - 1]) === average) {
        count += 1;
      }
      i += 1;
    }
    if (count === sharerCount) {
      return true;
    }
    return false;
  };

  const setAllZeroShareAmount = () => {
    // get number of members
    let currentMemberCount = 0;
    if (locationParam === "create") {
      currentMemberCount = currentMembers.length;
    }
    if (locationParam !== "create") {
      currentMemberCount = getCurrentOnBillMember().length + getRemovedOnBillMember().length;
    }
    // set share amount = 0
    let i = 1;
    const shareAmountArray = [...shareAmountList];
    while (i <= currentMemberCount) {
      shareAmountArray[i - 1] = 0;
      i += 1;
    }
    setShareAmountList(shareAmountArray);

    // set share foreign amount = 0
    if (currency !== usergroup.currency) {
      let j = 1;
      const shareForeignAmountArray = [...shareForeignAmountList];
      while (j <= currentMemberCount) {
        shareForeignAmountArray[j - 1] = 0;
        j += 1;
      }
      setShareForeignAmountList(shareForeignAmountArray);
    }

    // set share percentage
    setSharePercentage();
  };

  // check sum amount
  const checkSumOfPayAmountIsEqualToAmount = () => {
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));
    let i = 1;
    let sumAmount = 0;
    let amounti = 0;
    while (i <= payerCount) {
      if (payAmountListRef.current[i - 1] === "") {
        amounti = 0;
      } else if (payAmountListRef.current[i - 1] === null) {
        amounti = 0;
      } else if (Number.isNaN(payAmountListRef.current[i - 1]) === true) {
        amounti = 0;
      } else {
        amounti = parseFloat(payAmountListRef.current[i - 1]);
      }
      sumAmount += amounti;
      i += 1;
    }
    if (totalAmount === parseFloat(sumAmount.toFixed(2))) {
      return true;
    }
    return false;
  };

  const checkSumOfShareAmountIsEqualToAmount = () => {
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));
    let i = 1;
    let sumAmount = 0;
    let amounti = 0;
    while (i <= sharerCount) {
      if (shareAmountListRef.current[i - 1] === "") {
        amounti = 0;
      } else if (shareAmountListRef.current[i - 1] === null) {
        amounti = 0;
      } else if (Number.isNaN(shareAmountListRef.current[i - 1]) === true) {
        amounti = 0;
      } else {
        amounti = parseFloat(shareAmountListRef.current[i - 1]);
      }
      sumAmount += amounti;
      i += 1;
    }
    if (totalAmount === parseFloat(sumAmount.toFixed(2))) {
      return true;
    }
    return false;
  };

  // handle checkbox of share amount
  const [sumChecked, setSumChecked] = useState(false);
  const [checked, setChecked] = useState([]);

  const setOriginCheckedList = () => {
    let j = 1;
    const checkedList = [];
    while (j <= memberships.length) {
      if (memberships[j - 1].status === "member" || memberships[j - 1].status === "removed") {
        if (bill.share_record.length === 0) {
          if (memberships[j - 1].status === "member") {
            checkedList.push(false);
          }
        }
        if (bill.share_record.length !== 0) {
          const username = memberships[j - 1].user;
          if (bill.share_record.filter((share) => share.sharer === username).length !== 0) {
            if (getShareAmount(username) === 0) {
              checkedList.push(false);
            }
            if (getShareAmount(username) === null) {
              checkedList.push(false);
            }
            if (getShareAmount(username) !== 0 && getShareAmount(username) !== null) {
              checkedList.push(true);
            }
          }
        }
      }
      j += 1;
    }
    if (checkedList.length !== 0) {
      setChecked(checkedList);
    }
    if (checkedList.filter((checkItem) => checkItem === true).length === checkedList.length) {
      setSumChecked(true);
    }
    return null;
  };

  useEffect(() => {
    setOriginCheckedList();
  }, [memberships]);

  // Form Validation
  const [amountInputStatus, setAmountInputStatus, amountInputStatusRef] = useState(false);
  const [amountInputHelperText, setAmountInputHelperTest] = useState("");
  const [categoryInputStatus, setCategoryInputStatus, categoryInputStatusRef] = useState(false);
  const [categoryInputHelperText, setCatogoryInputHelperText] = useState("");
  const [nameInputStatus, setNameInputStatus, nameInputStatusRef] = useState(false);
  const [nameInputHelperText, setNameInputHelperText] = useState("");
  const [payAmountInputStatus, setPayAmountInputStatus, payAmountInputStatusRef] = useState(false);
  const [payAmountInputHelperText, setPayAmountInputHelperTest] = useState("");
  const [shareAmountInputStatus, setShareAmountInputStatus, shareAmountInputStatusRef] =
    useState(false);
  const [shareAmountInputHelperText, setShareAmountInputHelperTest] = useState("");

  const handleAllForm = () => {
    if (
      amountInputStatusRef.current === false &&
      categoryInputStatusRef.current === false &&
      nameInputStatusRef.current === false &&
      shareAmountInputStatusRef.current === false &&
      payAmountInputStatusRef.current === false
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  };

  const handleCategoryOnChange = (event) => {
    const categoryi = event.target.value;
    setCategory(categoryi);
    if (categoryi === "" || categoryi === null) {
      setCategoryInputStatus(true);
      setCatogoryInputHelperText(t("required"));
    }
    if (categoryi !== "" && categoryi !== null) {
      setCategoryInputStatus(false);
      setCatogoryInputHelperText("");
    }
    handleAllForm();
  };

  const handleNameOnChange = (event) => {
    const namei = event.target.value;
    setName(namei);
    if (namei.trim().length === 0 || namei === null) {
      setNameInputStatus(true);
      setNameInputHelperText(t("required"));
    }
    if (namei.trim().length !== 0 && namei !== null) {
      setNameInputStatus(false);
      setNameInputHelperText("");
    }
    handleAllForm();
  };

  const handleRemarkOnChange = (event) => {
    setRemark(event.target.value);
  };

  // emoji mart in remark
  const [showEmojis, setShowEmojis] = useState(false);
  const addEmoji = (e) => {
    const sym = e.unified.split("-");
    const codesArray = [];
    sym.forEach((el) => codesArray.push("0x".concat(el)));
    const emoji = String.fromCodePoint(...codesArray);
    setRemark(remark.concat(emoji));
  };

  const handleFrequentlyUsedNameButton = (frequentlyUsedName) => {
    setCategory(frequentlyUsedName.category);
    setName(frequentlyUsedName.name);
  };

  const handlePayAmountErrorMessage = () => {
    if (checkSumOfPayAmountIsEqualToAmount() === false) {
      setPayAmountInputStatus(true);
      if (billType === "expense") {
        setPayAmountInputHelperTest(
          t("sum of the paid amounts below must equal the total amount above")
        );
      }
      if (billType === "income") {
        setPayAmountInputHelperTest(
          t("sum of the received amounts below must equal the total amount above")
        );
      }
    }
    if (checkSumOfPayAmountIsEqualToAmount() === true) {
      setPayAmountInputStatus(false);
      setPayAmountInputHelperTest("");
    }
  };

  const handleShareAmountErrorMessage = () => {
    if (checkSumOfShareAmountIsEqualToAmount() === false) {
      setShareAmountInputStatus(true);
      setShareAmountInputHelperTest(
        t("sum of the distributed amounts below must equal the total amount above")
      );
    }
    if (checkSumOfShareAmountIsEqualToAmount() === true) {
      setShareAmountInputStatus(false);
      setShareAmountInputHelperTest("");
    }
  };

  // 使用 amountSetted 達成：
  // 1. 第一次輸入 amount = myPayAmount(僅限第一次);
  // 2. 除了第一次，之後都有 ErrorMessage
  const [amountSetted, setAmountSetted] = useState(false);

  // Onchange Event when create a bill: Total Amount -> payment amount for one time
  const setOringinPayAmount = (event) => {
    const payAmountArray = [...payAmountList];
    payAmountArray[0] = event.target.value;
    setPayAmountList(payAmountArray);
  };

  const setOringinShareAmount = (event) => {
    const shareAmountArray = [...shareAmountList];
    shareAmountArray[0] = event.target.value;
    setShareAmountList(shareAmountArray);
  };

  const setOringinPayForeignAmount = (event) => {
    const payForeignAmountArray = [...payForeignAmountList];
    payForeignAmountArray[0] = event.target.value;
    setPayForeignAmountList(payForeignAmountArray);
    const payAmountArray = [...payAmountList];
    payAmountArray[0] = parseFloat((event.target.value * currencyExchangeRate).toFixed(2));
    setPayAmountList(payAmountArray);
  };

  const setOringinShareForeignAmount = (event) => {
    const shareForeignAmountArray = [...shareForeignAmountList];
    shareForeignAmountArray[0] = event.target.value;
    setShareForeignAmountList(shareForeignAmountArray);
    const shareAmountArray = [...shareAmountList];
    shareAmountArray[0] = parseFloat((event.target.value * currencyExchangeRate).toFixed(2));
    setShareAmountList(shareAmountArray);
  };

  const handleAmountOnChange = (event) => {
    const amountValue = event.target.value;
    setAmount(amountValue.toString());
    if (amountValue <= 0 || amountValue === null) {
      setAmountInputStatus(true);
      setAmountInputHelperTest(t("required"));
    }
    if (amountValue > 0) {
      setAmountInputStatus(false);
      setAmountInputHelperTest("");
      setOringinPayAmount(event);
      if (memberships.length === 1) {
        setOringinShareAmount(event);
      }
      setPayPercentage();
      setSharePercentage();

      if (amountSetted === true) {
        handlePayAmountErrorMessage();
        handleShareAmountErrorMessage();
      }
    }
    handleAllForm();
  };

  const handleOneTimeAmountSetted = (event) => {
    handleAmountOnChange(event);
    setAmountSetted(true);
  };

  const handleCurrencyOnChange = (event) => {
    setCurrency(event.target.innerHTML);
    const originCurrencyRate = currencyExchangeRates[usergroup.currency];
    const foreignCurrencyRate = currencyExchangeRates[event.target.innerHTML];
    const exchangeRate = parseFloat((originCurrencyRate / foreignCurrencyRate).toFixed(6));
    setCurrencyExchangeRate(exchangeRate);
    if (foreignAmount > 0) {
      const amountValue = parseFloat((foreignAmount * exchangeRate).toFixed(2));
      setAmount(amountValue.toString());
    }
  };
  const handleCurrencyExchangeRateOnChange = (event) => {
    const currencyExchangeRateValue = event.target.value;
    setCurrencyExchangeRate(currencyExchangeRateValue);
    if (foreignAmount > 0) {
      const amountValue = parseFloat((foreignAmount * currencyExchangeRateValue).toFixed(2));
      setAmount(amountValue.toString());
    }
  };

  const handleForeignAmountOnChange = (event) => {
    const foreignAmountValue = event.target.value;
    setForeignAmount(foreignAmountValue.toString());
    const amountValue = parseFloat((foreignAmountValue * currencyExchangeRate).toFixed(2));
    setAmount(amountValue.toString());

    if (amountValue <= 0 || amountValue === null) {
      setAmountInputStatus(true);
      setAmountInputHelperTest(t("required"));
    }
    if (amountValue > 0) {
      setAmountInputStatus(false);
      setAmountInputHelperTest("");
      setOringinPayForeignAmount(event);
      if (memberships.length === 1) {
        setOringinShareForeignAmount(event);
      }
      setPayPercentage();
      setSharePercentage();
      if (amountSetted === true) {
        handlePayAmountErrorMessage();
        handleShareAmountErrorMessage();
      }
    }
    handleAllForm();
  };

  const handleOneTimeForeignAmountSetted = () => {
    setAmountSetted(true);
  };

  const handlePayAmountOnChange = (event) => {
    const inputId = event.target.name.slice(9) - 1;
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));
    const eventAmount = parseFloat(event.target.value);

    // handle pay amount
    const payAmountArray = [...payAmountList];
    payAmountArray[inputId] = eventAmount;

    // handle pay amount for only 2 members
    if (payAmountArray.length === 2) {
      if (payAmountArray[inputId + 1] !== undefined) {
        payAmountArray[inputId + 1] = totalAmount - eventAmount;
      } else {
        payAmountArray[inputId - 1] = totalAmount - eventAmount;
      }
    }
    setPayAmountList(payAmountArray);
    setPayPercentage();
    handlePayAmountErrorMessage();
    handleAllForm();
  };

  const handlePayForeignAmountOnChange = (event) => {
    const inputId = event.target.name.slice(16) - 1;
    const totalForeignAmount = parseFloat(foreignAmountRef.current.replace(/,/g, ""));
    const eventForeignAmount = parseFloat(event.target.value);
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));
    const eventAmount = parseFloat(
      (eventForeignAmount * (totalAmount / totalForeignAmount)).toFixed(2)
    );

    // handle pay foreign amount
    const payForeignAmountArray = [...payForeignAmountList];
    payForeignAmountArray[inputId] = eventForeignAmount;

    // handle pay foreign amount for only 2 members
    if (payForeignAmountArray.length === 2) {
      if (payForeignAmountArray[inputId + 1] !== undefined) {
        payForeignAmountArray[inputId + 1] = totalForeignAmount - eventForeignAmount;
      } else {
        payForeignAmountArray[inputId - 1] = totalForeignAmount - eventForeignAmount;
      }
    }
    setPayForeignAmountList(payForeignAmountArray);

    // handle pay amount
    const payAmountArray = [...payAmountList];
    payAmountArray[inputId] = eventAmount;
    // handle pay amount for only 2 members
    if (payAmountArray.length === 2) {
      if (payAmountArray[inputId + 1] !== undefined) {
        payAmountArray[inputId + 1] = totalAmount - eventAmount;
      } else {
        payAmountArray[inputId - 1] = totalAmount - eventAmount;
      }
    }
    setPayAmountList(payAmountArray);
    setPayPercentage();
    handlePayAmountErrorMessage();
    handleAllForm();
  };

  const handleCheck = (event) => {
    // set checked
    const checkedArray = [...checked];
    let i = 1;
    while (i <= sharerCount) {
      if (event.target.id === "checkbox".concat(i.toString())) {
        checkedArray[i - 1] = event.target.checked;
      }
      i += 1;
    }
    setChecked(checkedArray);

    // get number of checked
    let checkedNumber = 0;
    checkedArray.map((checkItem) => {
      if (checkItem === true) {
        checkedNumber += 1;
      }
      return null;
    });

    // set share amount
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));

    let j = 1;
    let k = 0;
    const shareAmountArray = [];
    const average = parseFloat((totalAmount / checkedNumber).toFixed(2));
    while (j <= sharerCount) {
      if (average * checkedNumber === totalAmount) {
        if (checkedArray[j - 1] === true) {
          shareAmountArray.push(average);
        } else {
          shareAmountArray.push(0);
        }
      } else if (average * checkedNumber !== totalAmount) {
        if (checkedArray[j - 1] === true) {
          if (k < 1) {
            shareAmountArray.push(totalAmount - average * (checkedNumber - 1));
            k += 1;
          } else {
            shareAmountArray.push(average);
          }
        } else {
          shareAmountArray.push(0);
        }
      }
      j += 1;
    }
    setShareAmountList(shareAmountArray);

    setSharePercentage();

    // set share foreign amount
    if (currency !== usergroup.currency) {
      let m = 1;
      let n = 0;
      const shareForeignAmountArray = [];
      const totalForeignAmount = parseFloat(foreignAmountRef.current.replace(/,/g, ""));
      let foreignAverage = 0;
      if (checkedNumber !== 0) {
        foreignAverage = parseFloat((totalForeignAmount / checkedNumber).toFixed(2));
      }
      while (m <= sharerCount) {
        if (foreignAverage * checkedNumber === totalForeignAmount) {
          if (checkedArray[m - 1] === true) {
            shareForeignAmountArray.push(foreignAverage);
          } else {
            shareForeignAmountArray.push(0);
          }
        } else if (foreignAverage * checkedNumber !== totalForeignAmount) {
          if (checkedArray[m - 1] === true) {
            if (n < 1) {
              shareForeignAmountArray.push(
                totalForeignAmount - foreignAverage * (checkedNumber - 1)
              );
              n += 1;
            } else {
              shareForeignAmountArray.push(foreignAverage);
            }
          } else {
            shareForeignAmountArray.push(0);
          }
        }
        m += 1;
      }
      setShareForeignAmountList(shareForeignAmountArray);
    }

    if (checkAverageShareAmount() === true) {
      setSumChecked(true);
    }
    if (checkAverageShareAmount() === false) {
      setSumChecked(false);
    }
    // handle error message
    handleShareAmountErrorMessage();
    handleAllForm();
  };

  const handleSumCheck = (event) => {
    const checkedArray = [...checked];
    if (parseFloat(amount) !== 0 && amount !== "") {
      // get member count
      let currentMemberCount = 0;
      if (locationParam === "create") {
        currentMemberCount = currentMembers.length;
      } else {
        currentMemberCount = getCurrentOnBillMember().length + getRemovedOnBillMember().length;
      }

      // set check and sumcheck
      let i = 1;
      while (i <= currentMemberCount) {
        checkedArray[i - 1] = event.target.checked;
        i += 1;
      }
      setChecked(checkedArray);
      setSumChecked(event.target.checked);

      // set share amount
      if (event.target.checked === true) {
        setAverageShareAmount();
      }
      if (event.target.checked === false) {
        setAllZeroShareAmount();
      }
    }
    // handle error message
    handleShareAmountErrorMessage();
    handleAllForm();
  };

  const setCheckByShareAmount = (shareAmount) => {
    if (parseFloat(shareAmount) !== 0 && shareAmount !== "") {
      return true;
    }
    return false;
  };

  const handleShareAmountOnChange = (event) => {
    const inputId = event.target.name.slice(11) - 1;
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));
    const eventAmount = parseFloat(event.target.value);

    // handel share amount
    const shareAmountArray = [...shareAmountList];
    shareAmountArray[inputId] = eventAmount;

    // handle checkboxList
    const checkedArray = [...checked];
    checkedArray[inputId] = setCheckByShareAmount(eventAmount);

    // handle share amount for only 2 members
    if (shareAmountArray.length === 2) {
      if (shareAmountArray[inputId + 1] !== undefined) {
        shareAmountArray[inputId + 1] = totalAmount - eventAmount;
        checkedArray[inputId + 1] = setCheckByShareAmount(totalAmount - eventAmount);
      } else {
        shareAmountArray[inputId - 1] = totalAmount - eventAmount;
        checkedArray[inputId - 1] = setCheckByShareAmount(totalAmount - eventAmount);
      }
    }

    // set share amount and checkboxList
    setShareAmountList(shareAmountArray);
    setChecked(checkedArray);

    // handle sumChecked
    if (checkAverageShareAmount() === true) {
      setSumChecked(true);
    }
    if (checkAverageShareAmount() === false) {
      setSumChecked(false);
    }
    // handle share Percentage
    setSharePercentage();
    // handle erroe message
    handleShareAmountErrorMessage();
    handleAllForm();
  };

  const handleShareForeignAmountOnChange = (event) => {
    const inputId = event.target.name.slice(18) - 1;
    const totalForeignAmount = parseFloat(foreignAmountRef.current.replace(/,/g, ""));
    const eventForeignAmount = parseFloat(event.target.value);
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));
    const eventAmount = parseFloat(
      (eventForeignAmount * (totalAmount / totalForeignAmount)).toFixed(2)
    );

    // handle share foreign amount
    const shareForeignAmountArray = [...shareForeignAmountList];
    shareForeignAmountArray[inputId] = eventForeignAmount;

    // handle share foreign amount for only 2 members
    if (shareForeignAmountArray.length === 2) {
      if (shareForeignAmountArray[inputId + 1] !== undefined) {
        shareForeignAmountArray[inputId + 1] = totalForeignAmount - eventForeignAmount;
      } else {
        shareForeignAmountArray[inputId - 1] = totalForeignAmount - eventForeignAmount;
      }
    }
    setShareForeignAmountList(shareForeignAmountArray);

    // handle share amount
    const shareAmountArray = [...shareAmountList];
    shareAmountArray[inputId] = eventAmount;

    // handle checkboxList
    const checkedArray = [...checked];
    checkedArray[inputId] = setCheckByShareAmount(eventAmount);

    // handle share amount for only 2 members
    if (shareAmountArray.length === 2) {
      if (shareAmountArray[inputId + 1] !== undefined) {
        shareAmountArray[inputId + 1] = totalAmount - eventAmount;
        checkedArray[inputId + 1] = setCheckByShareAmount(totalAmount - eventAmount);
      } else {
        shareAmountArray[inputId - 1] = totalAmount - eventAmount;
        checkedArray[inputId - 1] = setCheckByShareAmount(totalAmount - eventAmount);
      }
    }

    // set share amount and checkboxList
    setShareAmountList(shareAmountArray);
    setChecked(checkedArray);

    // handle sumChecked
    if (checkAverageShareAmount() === true) {
      setSumChecked(true);
    }
    if (checkAverageShareAmount() === false) {
      setSumChecked(false);
    }
    // handle share Percentage
    setSharePercentage();
    // handle erroe message
    handleShareAmountErrorMessage();
    handleAllForm();
  };

  const handlePayPercentOnChange = (event) => {
    const inputId = event.target.name.slice(10) - 1;
    const eventPercent = event.target.value;
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));
    const eventAmount = parseFloat(eventPercent / 100) * totalAmount;
    const totalForeignAmount = parseFloat(foreignAmountRef.current.replace(/,/g, ""));
    const eventForeignAmount = parseFloat(eventPercent / 100) * totalForeignAmount;

    // handle pay percent
    const payPercentArray = [...payPercentList];
    payPercentArray[inputId] = eventPercent;

    // handle pay percent for only 2 members
    if (payPercentArray.length === 2) {
      if (payPercentArray[inputId + 1] !== undefined) {
        payPercentArray[inputId + 1] = 100 - eventPercent;
      } else {
        payPercentArray[inputId - 1] = 100 - eventPercent;
      }
    }
    setPayPercentList(payPercentArray);

    // handle pay amount
    const payAmountArray = [...payAmountList];
    payAmountArray[inputId] = eventAmount;
    // handle pay amount for only 2 members
    if (payAmountArray.length === 2) {
      if (payAmountArray[inputId + 1] !== undefined) {
        payAmountArray[inputId + 1] = totalAmount - eventAmount;
      } else {
        payAmountArray[inputId - 1] = totalAmount - eventAmount;
      }
    }
    setPayAmountList(payAmountArray);

    // handle pay foreign amount
    if (currency !== usergroup.currency) {
      const payForeignAmountArray = [...payForeignAmountList];
      payForeignAmountArray[inputId] = eventForeignAmount;
      // handle pay foreign amount for only 2 members
      if (payForeignAmountArray.length === 2) {
        if (payForeignAmountArray[inputId + 1] !== undefined) {
          payForeignAmountArray[inputId + 1] = totalForeignAmount - eventForeignAmount;
        } else {
          payForeignAmountArray[inputId - 1] = totalForeignAmount - eventForeignAmount;
        }
      }
      setPayForeignAmountList(payForeignAmountArray);
    }

    // handle error message
    handlePayAmountErrorMessage();
    handleAllForm();
  };

  const handleSharePercentOnChange = (event) => {
    const inputId = event.target.name.slice(12) - 1;
    const eventPercent = event.target.value;
    const totalAmount = parseFloat(amountRef.current.replace(/,/g, ""));
    const eventAmount = parseFloat(eventPercent / 100) * totalAmount;
    const totalForeignAmount = parseFloat(foreignAmountRef.current.replace(/,/g, ""));
    const eventForeignAmount = parseFloat(eventPercent / 100) * totalForeignAmount;

    // handle share percent
    const sharePercentArray = [...sharePercentList];
    sharePercentArray[inputId] = eventPercent;
    // handle share percent for only 2 members
    if (sharePercentArray.length === 2) {
      if (sharePercentArray[inputId + 1] !== undefined) {
        sharePercentArray[inputId + 1] = 100 - eventPercent;
      } else {
        sharePercentArray[inputId - 1] = 100 - eventPercent;
      }
    }
    setSharePercentList(sharePercentArray);

    // handle sum share percent
    let sum = 0;
    sharePercentArray.map((sharePercent) => {
      let sharePercentNumber = 0;
      if (sharePercent === "") {
        sharePercentNumber = 0;
      } else {
        sharePercentNumber = parseFloat(sharePercent);
      }
      sum += sharePercentNumber;
      return sum;
    });
    setSharePercentSum(sum);

    // handle share amount
    const shareAmountArray = [...shareAmountList];
    shareAmountArray[inputId] = eventAmount;

    // handle checkboxList
    const checkedArray = [...checked];
    checkedArray[inputId] = setCheckByShareAmount(eventAmount);

    // handle share amount for only 2 members
    if (shareAmountArray.length === 2) {
      if (shareAmountArray[inputId + 1] !== undefined) {
        shareAmountArray[inputId + 1] = totalAmount - eventAmount;
        checkedArray[inputId + 1] = setCheckByShareAmount(totalAmount - eventAmount);
      } else {
        shareAmountArray[inputId - 1] = totalAmount - eventAmount;
        checkedArray[inputId - 1] = setCheckByShareAmount(totalAmount - eventAmount);
      }
    }

    // set share amount and checkboxList
    setShareAmountList(shareAmountArray);
    setChecked(checkedArray);

    // handle share foreign amount
    if (currency !== usergroup.currency) {
      const shareForeignAmountArray = [...shareForeignAmountList];
      shareForeignAmountArray[inputId] = eventForeignAmount;
      // handle share foreign amount for only 2 members
      if (shareForeignAmountArray.length === 2) {
        if (shareForeignAmountArray[inputId + 1] !== undefined) {
          shareForeignAmountArray[inputId + 1] = totalForeignAmount - eventForeignAmount;
        } else {
          shareForeignAmountArray[inputId - 1] = totalForeignAmount - eventForeignAmount;
        }
      }
      setShareForeignAmountList(shareForeignAmountArray);
    }

    // handle sumChecked
    if (checkAverageShareAmount() === true) {
      setSumChecked(true);
    }
    if (checkAverageShareAmount() === false) {
      setSumChecked(false);
    }

    // handle error message
    handleShareAmountErrorMessage();
    handleAllForm();
  };

  const handleLocalOrForeignDisplaySwitch = (currencyArea) => {
    if (currency === usergroup.currency) {
      if (currencyArea === "local") {
        return "block";
      }
      if (currencyArea === "foreign") {
        return "none";
      }
    } else if (currency !== usergroup.currency) {
      if (currencyArea === "local") {
        return "none";
      }
      if (currencyArea === "foreign") {
        return "block";
      }
    }
    return null;
  };

  if (isLoading) {
    return (
      <MDBox py={3}>
        <Card />
      </MDBox>
    );
  }

  return (
    <MDBox
      bgColor={darkMode ? "grey-900" : "grey-100"}
      borderTop={5}
      borderBottom={3}
      borderColor={setBoardColor()}
      borderRadius="lg"
      position="relative"
      pb={1}
      pt={3}
      px={3}
    >
      <MDBox component="form" role="form">
        <TimeForm dataTime={bill.datetime} dataTz={bill.tz} />
        <Grid container spacing={2}>
          <Grid item xs={4} md={3} lg={3}>
            <Autocomplete
              id="currency"
              disableClearable
              key={bill.currency}
              defaultValue={bill.currency}
              options={currencies.map((currencyItem) => currencyItem.code)}
              onChange={handleCurrencyOnChange}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label={t("currency")}
                  InputProps={{ ...params.InputProps, sx: { height: 47 }, style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16, padding: 1 } }}
                />
              )}
            />
          </Grid>
          {currency !== usergroup.currency && (
            <Grid item xs={8} md={9} lg={9}>
              <MDInput
                type="text"
                id="foreignAmount"
                name="foreignAmount"
                label={t("foreign currency amount")}
                onChange={handleForeignAmountOnChange}
                onBlur={handleOneTimeForeignAmountSetted}
                placeholder="0"
                value={foreignAmount}
                InputProps={{
                  style: { fontSize: 16 },
                  inputComponent: AmountFormat,
                }}
                InputLabelProps={{ style: { padding: 1 } }}
                fullWidth
                helperText={amountInputHelperText}
                error={amountInputStatus}
              />
            </Grid>
          )}
          {currency !== usergroup.currency && (
            <Grid item xs={4} md={3} lg={3}>
              <MDInput
                type="text"
                id="currencyExchangeRate"
                name="currencyExchangeRate"
                label={t("exchange rate")}
                onChange={handleCurrencyExchangeRateOnChange}
                value={currencyExchangeRate}
                InputProps={{
                  style: { fontSize: 16 },
                  inputComponent: ExchangeRateFormat,
                }}
                InputLabelProps={{ style: { padding: 1 } }}
                fullWidth
              />
            </Grid>
          )}
          <Grid
            item
            component={MDBox}
            display={handleLocalOrForeignDisplaySwitch("local")}
            xs={8}
            md={9}
            lg={9}
          >
            <MDInput
              type="text"
              id="amount"
              name="amount"
              label={t("amount")}
              onChange={handleAmountOnChange}
              onBlur={handleOneTimeAmountSetted}
              key={bill.amount}
              placeholder="0"
              value={amount}
              InputProps={{
                style: { fontSize: 16 },
                inputComponent: AmountFormat,
              }}
              InputLabelProps={{ style: { padding: 1 } }}
              fullWidth
              helperText={amountInputHelperText}
              error={amountInputStatus}
            />
          </Grid>
          <Grid
            item
            component={MDBox}
            display={handleLocalOrForeignDisplaySwitch("foreign")}
            xs={8}
            md={9}
            lg={9}
          >
            <MDTypography
              variant="body2"
              color="secondary"
              fontWeight="regular"
              whiteSpace="nowrap"
            >
              &nbsp;&nbsp;&nbsp;
              {parseFloat(foreignAmount).toLocaleString()}
              &nbsp;{currency}
              &nbsp; <Icon style={{ position: "relative", top: "2px" }}>arrow_forward</Icon> &nbsp;
              {parseFloat(amount).toLocaleString()}
              &nbsp;{usergroup.currency}
            </MDTypography>
          </Grid>
        </Grid>
        {locationParam === "create" && frequentlyUsedNames.length > 0 && (
          <MDBox pt={2} mb={-2} dispaly="flex">
            <Grid container spacing={1}>
              {frequentlyUsedNames.map((frequentlyUsedName) => {
                if (frequentlyUsedNameNumber < 5) {
                  if (billType === "expense") {
                    if (frequentlyUsedName.type === "expense") {
                      frequentlyUsedNameNumber += 1;
                      return (
                        <Grid key={frequentlyUsedName.name} item xs={2.4} md={2.4} lg={2.4}>
                          <MDButton
                            varient="gradient"
                            circular
                            fullWidth
                            sx={{ padding: 0 }}
                            onClick={() => handleFrequentlyUsedNameButton(frequentlyUsedName)}
                          >
                            <MDTypography
                              variant="button"
                              fontWeight="medium"
                              textTransform="capitalize"
                              maxWidth="100%"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              overflow="hidden !important"
                            >
                              {frequentlyUsedName.name}
                            </MDTypography>
                          </MDButton>
                        </Grid>
                      );
                    }
                  } else if (billType === "income") {
                    if (frequentlyUsedName.type === "income") {
                      frequentlyUsedNameNumber += 1;
                      return (
                        <Grid key={frequentlyUsedName.name} item xs={2.4} md={2.4} lg={2.4}>
                          <MDButton
                            varient="gradient"
                            circular
                            fullWidth
                            sx={{ padding: 0 }}
                            onClick={() => handleFrequentlyUsedNameButton(frequentlyUsedName)}
                          >
                            <MDTypography
                              variant="button"
                              fontWeight="medium"
                              textTransform="capitalize"
                              maxWidth="100%"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              overflow="hidden !important"
                            >
                              {frequentlyUsedName.name}
                            </MDTypography>
                          </MDButton>
                        </Grid>
                      );
                    }
                  }
                }
                return null;
              })}
            </Grid>
          </MDBox>
        )}
        <MDBox pt={4} pb={3} px={1}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={3} lg={3}>
              <MDInput
                type="text"
                id="category"
                label={t("category")}
                onChange={handleCategoryOnChange}
                onBlur={handleCategoryOnChange}
                value={category}
                variant="standard"
                InputProps={{
                  sx: { height: 32 },
                  style: { fontSize: 16 },
                }}
                InputLabelProps={{ style: { padding: 1 } }}
                select
                fullWidth
                helperText={categoryInputHelperText}
                error={categoryInputStatus}
              >
                {categories.map((categoryi) => {
                  if (billType === "expense") {
                    if (
                      categoryi.features.filter((feature) => feature === usergroup.feature)
                        .length !== 0
                    ) {
                      return (
                        <MenuItem key={categoryi.id} value={categoryi.english_name}>
                          {t(categoryi.english_name)}
                        </MenuItem>
                      );
                    }
                  }
                  if (billType === "income") {
                    if (categoryi.features.filter((feature) => feature === "income").length !== 0) {
                      return (
                        <MenuItem key={categoryi.id} value={categoryi.english_name}>
                          {t(categoryi.english_name)}
                        </MenuItem>
                      );
                    }
                  }
                  return null;
                })}
              </MDInput>
            </Grid>
            <Grid item xs={8} md={9} lg={9}>
              <MDInput
                type="text"
                id="name"
                label={t("name")}
                onChange={handleNameOnChange}
                onBlur={handleNameOnChange}
                value={name}
                variant="standard"
                inputProps={{
                  maxLength: 30,
                  style: { fontSize: 16 },
                }}
                InputLabelProps={{ style: { padding: 1 } }}
                fullWidth
                helperText={nameInputHelperText}
                error={nameInputStatus}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <MDInput
                type="textarea"
                id="remark"
                label={t("remark")}
                onChange={handleRemarkOnChange}
                value={remark}
                variant="standard"
                InputProps={{
                  inputProps: { maxLength: 300 },
                  style: { fontSize: 16 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <MDButton
                        variant="text"
                        color="secondary"
                        size="medium"
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowEmojis(!showEmojis);
                        }}
                        iconOnly
                      >
                        <Icon>add_reaction</Icon>
                      </MDButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ style: { padding: 1 } }}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </MDBox>
        {showEmojis && (
          <Picker
            data={data}
            onEmojiSelect={addEmoji}
            onClickOutside={() => setShowEmojis(false)}
            maxFrequentRows="2"
            perLine="7"
            skin="1"
            previewPosition="none"
          />
        )}
        <MDBox mx={1}>
          {payAmountInputHelperText !== "" && (
            <MDBox display="flex">
              <MDTypography variant="button" color="error" fontWeight="regular">
                <Icon sx={{ mb: -0.5 }} fontSize="small">
                  error_outline
                </Icon>
                &nbsp;
              </MDTypography>
              <MDTypography variant="button" color="error" fontWeight="regular">
                {payAmountInputHelperText}
              </MDTypography>
            </MDBox>
          )}
          {shareAmountInputHelperText !== "" && (
            <MDBox display="flex">
              <MDTypography variant="button" color="error" fontWeight="regular">
                <Icon sx={{ mb: -0.5 }} fontSize="small">
                  error_outline
                </Icon>
                &nbsp;
              </MDTypography>
              <MDTypography variant="button" color="error" fontWeight="regular">
                {shareAmountInputHelperText}
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
        <MDBox
          sx={{
            mx: { xs: -3, md: 0, lg: 3 },
          }}
          py={1}
          display={memberships.length > 1 ? "block" : "none"}
        >
          <Card>
            <MDBox display="flex" justifyContent="flex-end" alignItems="center">
              <Switch checked={showPayerListSwitch} onChange={handleShowPayerListSwitch} />
            </MDBox>
            <MDBox
              pt={0}
              pb={2}
              sx={{
                px: { xs: 1, md: 3, lg: 3 },
              }}
            >
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={5} md={5} lg={5}>
                  {billType === "expense" && (
                    <InputLabel>
                      {t("payer")}&nbsp;
                      <ClickAwayListener onClickAway={handlePayTooltipClose}>
                        <Tooltip open={payTooltip} title={t("Who paid the bill and how much?")}>
                          <Icon onClick={handlePayTooltipOpen}>help_outline</Icon>
                        </Tooltip>
                      </ClickAwayListener>
                    </InputLabel>
                  )}
                  {billType === "income" && (
                    <InputLabel>
                      {t("receiver")}&nbsp;
                      <ClickAwayListener onClickAway={handleReceiveTooltipClose}>
                        <Tooltip
                          open={receiveTooltip}
                          title={t("Who received the income and how much?")}
                        >
                          <Icon onClick={handleReceiveTooltipOpen}>help_outline</Icon>
                        </Tooltip>
                      </ClickAwayListener>
                    </InputLabel>
                  )}
                </Grid>
                <Grid item xs={5} md={5} lg={5}>
                  <InputLabel sx={{ pt: 0.5 }}>{t("amount")}</InputLabel>
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                  <InputLabel>%</InputLabel>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox
              sx={{
                px: { xs: 1, md: 3, lg: 3 },
              }}
            >
              <MDBox pb={3}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={1} md={1} lg={1} container justifyContent="flex-end">
                    <MDAvatar
                      src={setAvatar(memberships[0].user)}
                      bgColor="secondary"
                      sx={{
                        height: { xs: 30, md: 50, lg: 50 },
                        width: { xs: 30, md: 50, lg: 50 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <input id={"member".concat("1")} value={memberships[0].user} type="hidden" />
                    <MDInput
                      type="text"
                      id={"memberName".concat("1")}
                      key={setNickname(memberships[0].user)}
                      value={setNickname(memberships[0].user)}
                      variant="standard"
                      InputProps={{ style: { fontSize: 16 } }}
                      multiline
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    component={MDBox}
                    display={handleLocalOrForeignDisplaySwitch("local")}
                    xs={5}
                    md={5}
                    lg={5}
                  >
                    <MDInput
                      type="text"
                      id={"payAmount".concat("1")}
                      name={"payAmount".concat("1")}
                      value={payAmountList[0]}
                      onChange={handlePayAmountOnChange}
                      placeholder="0"
                      variant="standard"
                      InputProps={{
                        style: { fontSize: 16 },
                        inputComponent: AmountFormat,
                      }}
                      fullWidth
                      error={payAmountInputStatus}
                      hidden
                    />
                  </Grid>
                  <Grid
                    item
                    component={MDBox}
                    display={handleLocalOrForeignDisplaySwitch("foreign")}
                    xs={5}
                    md={5}
                    lg={5}
                  >
                    <MDInput
                      type="text"
                      id={"payForeignAmount".concat("1")}
                      name={"payForeignAmount".concat("1")}
                      value={payForeignAmountList[0]}
                      onChange={handlePayForeignAmountOnChange}
                      placeholder="0"
                      variant="standard"
                      InputProps={{
                        style: { fontSize: 16 },
                        inputComponent: AmountFormat,
                      }}
                      fullWidth
                      error={payAmountInputStatus}
                    />
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <MDInput
                      type="text"
                      id={"payPercent".concat("1")}
                      name={"payPercent".concat("1")}
                      value={payPercentList[0]}
                      onChange={handlePayPercentOnChange}
                      placeholder="0"
                      variant="standard"
                      InputProps={{
                        style: { fontSize: 16 },
                        inputComponent: PercentFormat,
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </MDBox>
              {memberships.map((member) => {
                payerCount += 1;
                if (member.status === "member") {
                  if (checkMemberInBill(member.user) !== undefined) {
                    return (
                      <MDBox display={showPayerListSwitch ? "block" : "none"} key={member.id}>
                        {payerCount > 1 && (
                          <MDBox pb={3}>
                            <Grid container spacing={1} alignItems="flex-end">
                              <Grid item xs={1} md={1} lg={1} container justifyContent="flex-end">
                                <MDAvatar
                                  src={setAvatar(member.user)}
                                  bgColor="secondary"
                                  sx={{
                                    height: { xs: 30, md: 50, lg: 50 },
                                    width: { xs: 30, md: 50, lg: 50 },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4} md={4} lg={4}>
                                <input
                                  id={"member".concat(payerCount.toString())}
                                  value={member.user}
                                  type="hidden"
                                />
                                <MDInput
                                  type="text"
                                  id={"memberName".concat(payerCount.toString())}
                                  key={setNickname(member.user)}
                                  value={setNickname(member.user)}
                                  variant="standard"
                                  InputProps={{ style: { fontSize: 16 } }}
                                  multiline
                                  fullWidth
                                />
                              </Grid>
                              <Grid
                                item
                                component={MDBox}
                                display={handleLocalOrForeignDisplaySwitch("local")}
                                xs={5}
                                md={5}
                                lg={5}
                              >
                                <MDInput
                                  type="text"
                                  id={"payAmount".concat(payerCount.toString())}
                                  name={"payAmount".concat(payerCount.toString())}
                                  value={payAmountList[payerCount - 1]}
                                  onChange={handlePayAmountOnChange}
                                  placeholder="0"
                                  variant="standard"
                                  InputProps={{
                                    style: { fontSize: 16 },
                                    inputComponent: AmountFormat,
                                  }}
                                  fullWidth
                                  error={payAmountInputStatus}
                                  hidden
                                />
                              </Grid>
                              <Grid
                                item
                                component={MDBox}
                                display={handleLocalOrForeignDisplaySwitch("foreign")}
                                xs={5}
                                md={5}
                                lg={5}
                              >
                                <MDInput
                                  type="text"
                                  id={"payForeignAmount".concat(payerCount.toString())}
                                  name={"payForeignAmount".concat(payerCount.toString())}
                                  value={payForeignAmountList[payerCount - 1]}
                                  onChange={handlePayForeignAmountOnChange}
                                  placeholder="0"
                                  variant="standard"
                                  InputProps={{
                                    style: { fontSize: 16 },
                                    inputComponent: AmountFormat,
                                  }}
                                  fullWidth
                                  error={payAmountInputStatus}
                                />
                              </Grid>
                              <Grid item xs={2} md={2} lg={2}>
                                <MDInput
                                  type="text"
                                  id={"payPercent".concat(payerCount.toString())}
                                  name={"payPercent".concat(payerCount.toString())}
                                  value={payPercentList[payerCount - 1]}
                                  onChange={handlePayPercentOnChange}
                                  placeholder="0"
                                  variant="standard"
                                  InputProps={{
                                    style: { fontSize: 16 },
                                    inputComponent: PercentFormat,
                                  }}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                        )}
                      </MDBox>
                    );
                  }
                  if (checkMemberInBill(member.user) === undefined) {
                    if (locationParam === "create") {
                      return (
                        <MDBox display={showPayerListSwitch ? "block" : "none"} key={member.id}>
                          {payerCount > 1 && (
                            <MDBox pb={3}>
                              <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={1} md={1} lg={1} container justifyContent="flex-end">
                                  <MDAvatar
                                    src={setAvatar(member.user)}
                                    bgColor="secondary"
                                    sx={{
                                      height: { xs: 30, md: 50, lg: 50 },
                                      width: { xs: 30, md: 50, lg: 50 },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                  <input
                                    id={"member".concat(payerCount.toString())}
                                    value={member.user}
                                    type="hidden"
                                  />
                                  <MDInput
                                    type="text"
                                    id={"memberName".concat(payerCount.toString())}
                                    key={setNickname(member.user)}
                                    value={setNickname(member.user)}
                                    variant="standard"
                                    InputProps={{ style: { fontSize: 16 } }}
                                    multiline
                                    fullWidth
                                  />
                                </Grid>
                                <Grid
                                  item
                                  component={MDBox}
                                  display={handleLocalOrForeignDisplaySwitch("local")}
                                  xs={5}
                                  md={5}
                                  lg={5}
                                >
                                  <MDInput
                                    type="text"
                                    id={"payAmount".concat(payerCount.toString())}
                                    name={"payAmount".concat(payerCount.toString())}
                                    value={payAmountList[payerCount - 1]}
                                    onChange={handlePayAmountOnChange}
                                    placeholder="0"
                                    variant="standard"
                                    InputProps={{
                                      style: { fontSize: 16 },
                                      inputComponent: AmountFormat,
                                    }}
                                    fullWidth
                                    error={payAmountInputStatus}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  component={MDBox}
                                  display={handleLocalOrForeignDisplaySwitch("foreign")}
                                  xs={5}
                                  md={5}
                                  lg={5}
                                >
                                  <MDInput
                                    type="text"
                                    id={"payForeignAmount".concat(payerCount.toString())}
                                    name={"payForeignAmount".concat(payerCount.toString())}
                                    value={payForeignAmountList[payerCount - 1]}
                                    onChange={handlePayForeignAmountOnChange}
                                    placeholder="0"
                                    variant="standard"
                                    InputProps={{
                                      style: { fontSize: 16 },
                                      inputComponent: AmountFormat,
                                    }}
                                    fullWidth
                                    error={payAmountInputStatus}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2} lg={2}>
                                  <MDInput
                                    type="text"
                                    id={"payPercent".concat(payerCount.toString())}
                                    name={"payPercent".concat(payerCount.toString())}
                                    value={payPercentList[payerCount - 1]}
                                    onChange={handlePayPercentOnChange}
                                    placeholder="0"
                                    variant="standard"
                                    InputProps={{
                                      style: { fontSize: 16 },
                                      inputComponent: PercentFormat,
                                    }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </MDBox>
                          )}
                        </MDBox>
                      );
                    }
                    if (locationParam !== "create") {
                      payerCount -= 1;
                    }
                  }
                }
                if (member.status === "removed") {
                  if (locationParam !== "create") {
                    if (checkMemberInBill(member.user) !== undefined) {
                      return (
                        <MDBox display={showPayerListSwitch ? "block" : "none"} key={member.id}>
                          {payerCount > 1 && (
                            <MDBox pb={3}>
                              <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={1} md={1} lg={1} container justifyContent="flex-end">
                                  <MDAvatar
                                    src={setAvatar(member.user)}
                                    bgColor="secondary"
                                    sx={{
                                      height: { xs: 30, md: 50, lg: 50 },
                                      width: { xs: 30, md: 50, lg: 50 },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                  <input
                                    id={"member".concat(payerCount.toString())}
                                    value={member.user}
                                    type="hidden"
                                  />
                                  <MDInput
                                    type="text"
                                    id={"memberName".concat(payerCount.toString())}
                                    key={setNickname(member.user)}
                                    value={setNickname(member.user)}
                                    variant="standard"
                                    InputProps={{ style: { fontSize: 16 } }}
                                    multiline
                                    fullWidth
                                    disabled
                                  />
                                </Grid>
                                <Grid
                                  item
                                  component={MDBox}
                                  display={handleLocalOrForeignDisplaySwitch("local")}
                                  xs={5}
                                  md={5}
                                  lg={5}
                                >
                                  <MDInput
                                    type="text"
                                    id={"payAmount".concat(payerCount.toString())}
                                    name={"payAmount".concat(payerCount.toString())}
                                    value={payAmountList[payerCount - 1]}
                                    onChange={handlePayAmountOnChange}
                                    placeholder="0"
                                    variant="standard"
                                    fullWidth
                                    InputProps={{
                                      style: { fontSize: 16 },
                                      inputComponent: AmountFormat,
                                    }}
                                    error={payAmountInputStatus}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  component={MDBox}
                                  display={handleLocalOrForeignDisplaySwitch("foreign")}
                                  xs={5}
                                  md={5}
                                  lg={5}
                                >
                                  <MDInput
                                    type="text"
                                    id={"payForeignAmount".concat(payerCount.toString())}
                                    name={"payForeignAmount".concat(payerCount.toString())}
                                    value={payForeignAmountList[payerCount - 1]}
                                    onChange={handlePayForeignAmountOnChange}
                                    placeholder="0"
                                    variant="standard"
                                    InputProps={{
                                      style: { fontSize: 16 },
                                      inputComponent: AmountFormat,
                                    }}
                                    fullWidth
                                    error={payAmountInputStatus}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2} lg={2}>
                                  <MDInput
                                    type="text"
                                    id={"payPercent".concat(payerCount.toString())}
                                    name={"payPercent".concat(payerCount.toString())}
                                    value={payPercentList[payerCount - 1]}
                                    onChange={handlePayPercentOnChange}
                                    placeholder="0"
                                    variant="standard"
                                    InputProps={{
                                      style: { fontSize: 16 },
                                      inputComponent: PercentFormat,
                                    }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </MDBox>
                          )}
                        </MDBox>
                      );
                    }
                    if (checkMemberInBill(member.user) === undefined) {
                      payerCount -= 1;
                    }
                  }
                  if (locationParam === "create") {
                    payerCount -= 1;
                  }
                }
                if (member.status === "invited") {
                  payerCount -= 1;
                }
                return null;
              })}
              <input id="count" value={payerCount} type="hidden" />
            </MDBox>
          </Card>
        </MDBox>
        <MDBox
          sx={{
            mx: { xs: -3, md: 0, lg: 3 },
          }}
          py={1}
          display={memberships.length > 1 ? "block" : "none"}
        >
          <Card>
            <MDBox
              pt={3}
              pb={0}
              sx={{
                px: { xs: 1, md: 3, lg: 3 },
              }}
            >
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={5} md={5} lg={5}>
                  <InputLabel>
                    {t("distribution method")}&nbsp;
                    <ClickAwayListener onClickAway={handleShareTooltipClose}>
                      <Tooltip
                        id="payTooltipString"
                        open={shareTooltip}
                        title={t(
                          "How was the bill distributed/splited? Please enter the amount per member."
                        )}
                      >
                        <Icon onClick={handleShareTooltipOpen}>help_outline</Icon>
                      </Tooltip>
                    </ClickAwayListener>
                  </InputLabel>
                </Grid>
                <Grid item xs={5} md={5} lg={5}>
                  <InputLabel sx={{ pt: 0.5 }}>{t("amount")}</InputLabel>
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                  <InputLabel>%</InputLabel>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox
              pb={0}
              sx={{
                px: { xs: 1, md: 3, lg: 3 },
              }}
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={5} md={5} lg={5} />
                <Grid item xs={5} md={5} lg={5}>
                  <Checkbox
                    sx={{ m: -1 }}
                    checked={sumChecked}
                    onChange={handleSumCheck}
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                  />
                  <MDTypography variant="button" color="secondary" fontWeight="regular">
                    &nbsp; {t("AA")}
                  </MDTypography>
                </Grid>
                <Grid item xs={2} md={2} lg={2} overflow="hidden">
                  <MDTypography
                    id="totalSharePercent"
                    variant="body2"
                    color="secondary"
                    fontWeight="regular"
                    whiteSpace="nowrap"
                  >
                    {parseFloat(sharePercentSum.toFixed(2))}%
                  </MDTypography>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox
              sx={{
                px: { xs: 1, md: 3, lg: 3 },
              }}
            >
              {memberships.map((member) => {
                sharerCount += 1;
                if (member.status === "member") {
                  if (checkMemberInBill(member.user) !== undefined) {
                    return (
                      <MDBox pb={3} key={member.id}>
                        <Grid container spacing={1} alignItems="flex-end">
                          <Grid item xs={1} md={1} lg={1} container justifyContent="flex-end">
                            <MDAvatar
                              src={setAvatar(member.user)}
                              bgColor="secondary"
                              sx={{
                                height: { xs: 30, md: 50, lg: 50 },
                                width: { xs: 30, md: 50, lg: 50 },
                              }}
                            />
                          </Grid>
                          <Grid item xs={4} md={4} lg={4}>
                            <input
                              id={"member".concat(sharerCount.toString())}
                              value={member.user}
                              type="hidden"
                            />
                            <MDInput
                              type="text"
                              id={"memberName".concat(sharerCount.toString())}
                              key={setNickname(member.user)}
                              value={setNickname(member.user)}
                              variant="standard"
                              InputProps={{ style: { fontSize: 16 } }}
                              multiline
                              fullWidth
                            />
                          </Grid>
                          <Grid
                            item
                            component={MDBox}
                            display={handleLocalOrForeignDisplaySwitch("local")}
                            xs={5}
                            md={5}
                            lg={5}
                          >
                            <MDInput
                              type="text"
                              id={"shareAmount".concat(sharerCount.toString())}
                              name={"shareAmount".concat(sharerCount.toString())}
                              value={shareAmountList[sharerCount - 1]}
                              onChange={handleShareAmountOnChange}
                              placeholder="0"
                              variant="standard"
                              InputProps={{
                                style: { fontSize: 16 },
                                inputComponent: AmountFormat,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Checkbox
                                      id={"checkbox".concat(sharerCount.toString())}
                                      size="small"
                                      sx={{ m: -1 }}
                                      checked={checked[sharerCount - 1]}
                                      onChange={handleCheck}
                                      checkedIcon={<BpCheckedIcon />}
                                      icon={<BpIcon />}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                              error={shareAmountInputStatus}
                            />
                          </Grid>
                          <Grid
                            item
                            component={MDBox}
                            display={handleLocalOrForeignDisplaySwitch("foreign")}
                            xs={5}
                            md={5}
                            lg={5}
                          >
                            <MDInput
                              type="text"
                              id={"shareForeignAmount".concat(sharerCount.toString())}
                              name={"shareForeignAmount".concat(sharerCount.toString())}
                              value={shareForeignAmountList[sharerCount - 1]}
                              onChange={handleShareForeignAmountOnChange}
                              placeholder="0"
                              variant="standard"
                              InputProps={{
                                style: { fontSize: 16 },
                                inputComponent: AmountFormat,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Checkbox
                                      id={"checkbox".concat(sharerCount.toString())}
                                      size="small"
                                      sx={{ m: -1 }}
                                      checked={checked[sharerCount - 1]}
                                      onChange={handleCheck}
                                      checkedIcon={<BpCheckedIcon />}
                                      icon={<BpIcon />}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                              error={shareAmountInputStatus}
                            />
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="text"
                              id={"sharePercent".concat(sharerCount.toString())}
                              name={"sharePercent".concat(sharerCount.toString())}
                              value={sharePercentList[sharerCount - 1]}
                              onChange={handleSharePercentOnChange}
                              placeholder="0"
                              variant="standard"
                              InputProps={{
                                style: { fontSize: 16 },
                                inputComponent: PercentFormat,
                              }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                    );
                  }
                  if (checkMemberInBill(member.user) === undefined) {
                    if (locationParam === "create") {
                      return (
                        <MDBox pb={3} key={member.id}>
                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={1} md={1} lg={1} container justifyContent="flex-end">
                              <MDAvatar
                                src={setAvatar(member.user)}
                                bgColor="secondary"
                                sx={{
                                  height: { xs: 30, md: 50, lg: 50 },
                                  width: { xs: 30, md: 50, lg: 50 },
                                }}
                              />
                            </Grid>
                            <Grid item xs={4} md={4} lg={4}>
                              <input
                                id={"member".concat(sharerCount.toString())}
                                value={member.user}
                                type="hidden"
                              />
                              <MDInput
                                type="text"
                                id={"memberName".concat(sharerCount.toString())}
                                key={setNickname(member.user)}
                                value={setNickname(member.user)}
                                variant="standard"
                                InputProps={{ style: { fontSize: 16 } }}
                                multiline
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              item
                              component={MDBox}
                              display={handleLocalOrForeignDisplaySwitch("local")}
                              xs={5}
                              md={5}
                              lg={5}
                            >
                              <MDInput
                                type="text"
                                id={"shareAmount".concat(sharerCount.toString())}
                                name={"shareAmount".concat(sharerCount.toString())}
                                value={shareAmountList[sharerCount - 1]}
                                onChange={handleShareAmountOnChange}
                                placeholder="0"
                                variant="standard"
                                InputProps={{
                                  style: { fontSize: 16 },
                                  inputComponent: AmountFormat,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Checkbox
                                        id={"checkbox".concat(sharerCount.toString())}
                                        size="small"
                                        sx={{ m: -1 }}
                                        checked={checked[sharerCount - 1]}
                                        onChange={handleCheck}
                                        checkedIcon={<BpCheckedIcon />}
                                        icon={<BpIcon />}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                fullWidth
                                error={shareAmountInputStatus}
                              />
                            </Grid>
                            <Grid
                              item
                              component={MDBox}
                              display={handleLocalOrForeignDisplaySwitch("foreign")}
                              xs={5}
                              md={5}
                              lg={5}
                            >
                              <MDInput
                                type="text"
                                id={"shareForeignAmount".concat(sharerCount.toString())}
                                name={"shareForeignAmount".concat(sharerCount.toString())}
                                value={shareForeignAmountList[sharerCount - 1]}
                                onChange={handleShareForeignAmountOnChange}
                                placeholder="0"
                                variant="standard"
                                InputProps={{
                                  style: { fontSize: 16 },
                                  inputComponent: AmountFormat,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Checkbox
                                        id={"checkbox".concat(sharerCount.toString())}
                                        size="small"
                                        sx={{ m: -1 }}
                                        checked={checked[sharerCount - 1]}
                                        onChange={handleCheck}
                                        checkedIcon={<BpCheckedIcon />}
                                        icon={<BpIcon />}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                fullWidth
                                error={shareAmountInputStatus}
                              />
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                              <MDInput
                                type="text"
                                id={"sharePercent".concat(sharerCount.toString())}
                                name={"sharePercent".concat(sharerCount.toString())}
                                value={sharePercentList[sharerCount - 1]}
                                onChange={handleSharePercentOnChange}
                                placeholder="0"
                                variant="standard"
                                InputProps={{
                                  style: { fontSize: 16 },
                                  inputComponent: PercentFormat,
                                }}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </MDBox>
                      );
                    }
                    if (locationParam !== "create") {
                      sharerCount -= 1;
                    }
                  }
                }
                if (member.status === "removed") {
                  if (locationParam !== "create") {
                    if (checkMemberInBill(member.user) !== undefined) {
                      return (
                        <MDBox pb={3} key={member.id}>
                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={1} md={1} lg={1} container justifyContent="flex-end">
                              <MDAvatar
                                src={setAvatar(member.user)}
                                bgColor="secondary"
                                sx={{
                                  height: { xs: 30, md: 50, lg: 50 },
                                  width: { xs: 30, md: 50, lg: 50 },
                                }}
                              />
                            </Grid>
                            <Grid item xs={4} md={4} lg={4}>
                              <input
                                id={"member".concat(sharerCount.toString())}
                                value={member.user}
                                type="hidden"
                              />
                              <MDInput
                                type="text"
                                id={"memberName".concat(sharerCount.toString())}
                                key={setNickname(member.user)}
                                value={setNickname(member.user)}
                                variant="standard"
                                InputProps={{ style: { fontSize: 16 } }}
                                multiline
                                fullWidth
                                disabled
                              />
                            </Grid>
                            <Grid
                              item
                              component={MDBox}
                              display={handleLocalOrForeignDisplaySwitch("local")}
                              xs={5}
                              md={5}
                              lg={5}
                            >
                              <MDInput
                                type="text"
                                id={"shareAmount".concat(sharerCount.toString())}
                                name={"shareAmount".concat(sharerCount.toString())}
                                value={shareAmountList[sharerCount - 1]}
                                onChange={handleShareAmountOnChange}
                                placeholder="0"
                                variant="standard"
                                fullWidth
                                InputProps={{
                                  style: { fontSize: 16 },
                                  inputComponent: AmountFormat,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Checkbox
                                        id={"checkbox".concat(sharerCount.toString())}
                                        size="small"
                                        sx={{ m: -1 }}
                                        checked={checked[sharerCount - 1]}
                                        onChange={handleCheck}
                                        checkedIcon={<BpCheckedIcon />}
                                        icon={<BpIcon />}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                error={shareAmountInputStatus}
                              />
                            </Grid>
                            <Grid
                              item
                              component={MDBox}
                              display={handleLocalOrForeignDisplaySwitch("foreign")}
                              xs={5}
                              md={5}
                              lg={5}
                            >
                              <MDInput
                                type="text"
                                id={"shareForeignAmount".concat(sharerCount.toString())}
                                name={"shareForeignAmount".concat(sharerCount.toString())}
                                value={shareForeignAmountList[sharerCount - 1]}
                                onChange={handleShareForeignAmountOnChange}
                                placeholder="0"
                                variant="standard"
                                InputProps={{
                                  style: { fontSize: 16 },
                                  inputComponent: AmountFormat,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Checkbox
                                        id={"checkbox".concat(sharerCount.toString())}
                                        size="small"
                                        sx={{ m: -1 }}
                                        checked={checked[sharerCount - 1]}
                                        onChange={handleCheck}
                                        checkedIcon={<BpCheckedIcon />}
                                        icon={<BpIcon />}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                fullWidth
                                error={shareAmountInputStatus}
                              />
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                              <MDInput
                                type="text"
                                id={"sharePercent".concat(sharerCount.toString())}
                                name={"sharePercent".concat(sharerCount.toString())}
                                value={sharePercentList[sharerCount - 1]}
                                onChange={handleSharePercentOnChange}
                                placeholder="0"
                                variant="standard"
                                InputProps={{
                                  style: { fontSize: 16 },
                                  inputComponent: PercentFormat,
                                }}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </MDBox>
                      );
                    }
                    if (checkMemberInBill(member.user) === undefined) {
                      sharerCount -= 1;
                    }
                  }
                  if (locationParam === "create") {
                    sharerCount -= 1;
                  }
                }
                if (member.status === "invited") {
                  sharerCount -= 1;
                }
                return null;
              })}
              <input id="count" value={sharerCount} type="hidden" />
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill
BillForm.defaultProps = {
  billType: "expense",
};

// Typechecking props for the Bill
BillForm.propTypes = {
  usergroup: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    feature: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    members: PropTypes.instanceOf(Array),
  }).isRequired,
  memberships: PropTypes.instanceOf(Array).isRequired,
  bill: PropTypes.shape({
    type: PropTypes.string,
    amount: PropTypes.string.isRequired,
    foreign_amount: PropTypes.string,
    currency: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tz: PropTypes.string.isRequired,
    datetime: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    remark: PropTypes.string.isRequired,
    usergroup: PropTypes.string.isRequired,
    payment_record: PropTypes.instanceOf(Array),
    share_record: PropTypes.instanceOf(Array),
  }).isRequired,
  billType: PropTypes.string,
  setValidForm: PropTypes.func.isRequired,
};

export default BillForm;
