import { Helmet } from "react-helmet";
// i18n
import { useTranslation } from "react-i18next";
// env
const frontendURL = process.env.REACT_APP_FRONTENDURL;

function IndexHelmet() {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>AllBill</title>
      <meta name="application-name" content="AllBill" />
      <meta
        name="description"
        content={t(
          "Sign in to AllBill to manage your money with your friends, family and others. If you are used to recording and sharing your life in private groups, try using AllBill to manage your expenses, income, transfers, and splits. Let's start by creating a group."
        )}
      />
      <meta property="og:url" content="https://allbill.com/" />
      <meta property="og:title" content="AllBill" />
      <meta property="og:image" content={frontendURL.concat("/favicon-128x128-dark.png")} />
      <meta
        property="og:description"
        content={t(
          "Sign in to AllBill to manage your money with your friends, family and others. If you are used to recording and sharing your life in private groups, try using AllBill to manage your expenses, income, transfers, and splits. Let's start by creating a group."
        )}
      />
      <meta
        name="twitter:description"
        content={t(
          "Sign in to AllBill to manage your money with your friends, family and others. If you are used to recording and sharing your life in private groups, try using AllBill to manage your expenses, income, transfers, and splits. Let's start by creating a group."
        )}
      />
      <meta name="twitter:card" content={frontendURL.concat("/favicon-128x128-dark.png")} />
    </Helmet>
  );
}

export default IndexHelmet;
