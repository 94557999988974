// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useLocation } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React components
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import { useMaterialUIController } from "context";

// Numeric Format
import AmountFormat from "layouts/usergroups/bills/components/AmountFormat";

// http-api-tool
import axios from "axios";

// TimeForm
import TimeForm from "layouts/usergroups/bills/components/TimeForm";

// emoji mart
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;

function TransferForm({ usergroup, memberships, bill, billType, setValidForm }) {
  const { t } = useTranslation();

  const location = useLocation();
  const locationParam = location.pathname.split("/")[4];
  // Dark mode
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // Loading mode (Waiting for Axios before rendering)
  const [isLoading, setLoading] = useState(true);

  // Get Currency/Category List
  const [currencies, setCurrencies] = useState([]);
  const [categories, setCategories] = useState([]);

  const currencyListBackend = axios.create({
    baseURL: `${backendURL}/currencies/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const categoryListBackend = axios.create({
    baseURL: `${backendURL}/categories/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getCurrencyCategoryList = async () => {
    try {
      const currencyResponse = await currencyListBackend.get("");
      const currencyResponseData = await currencyResponse.data;
      setCurrencies(currencyResponseData);
      const categoryResponse = await categoryListBackend.get("");
      const categoryResponseData = await categoryResponse.data;
      setCategories(categoryResponseData);
      setLoading(false);
    } catch (error) {
      // console.log("error");
    }
  };

  useEffect(() => {
    getCurrencyCategoryList();
  }, []);

  // amount (if zero: using placeholder)
  const getAmount = () => {
    if (bill.amount !== "0.00") {
      return Math.abs(bill.amount);
    }
    return null;
  };

  const getAmountZero = () => {
    if (bill.amount === "0.00") {
      return "0";
    }
    return null;
  };

  const setAvatar = (username) => {
    const userObj = usergroup.members.find((member) => member.username === username);
    return userObj.avatar;
  };

  const setNickname = (username) => {
    const userObj = usergroup.members.find((member) => member.username === username);
    return userObj.nickname;
  };

  // Set the fromUser and toUser
  const [fromUser, setFromUser] = useState("");
  const [fromUserName, setFromUserName] = useState("");
  const [fromUserAvatar, setFromUserAvatar] = useState("");
  const [toUser, setToUser] = useState("");
  const [toUserName, setToUserName] = useState("");
  const [toUserAvatar, setToUserAvatar] = useState("");

  const setOriginFromUser = () => {
    if (locationParam === "create") {
      const username = localStorage.getItem("username");
      setFromUser(username);
      setFromUserName(setNickname(username));
      setFromUserAvatar(setAvatar(username));
    }
    if (locationParam !== "create") {
      if (bill !== undefined) {
        const payments = bill.payment_record;
        const username = payments.find((payment) => payment.amount < 0).payer;
        setFromUser(username);
        setFromUserName(setNickname(username));
        setFromUserAvatar(setAvatar(username));
      }
    }
    return null;
  };

  const setOriginToUser = () => {
    if (locationParam !== "create") {
      if (bill !== undefined) {
        const payments = bill.payment_record;
        const username = payments.find((payment) => payment.amount > 0).payer;
        setToUser(username);
        setToUserName(setNickname(username));
        setToUserAvatar(setAvatar(username));
      }
    }
    return null;
  };

  useEffect(() => {
    setOriginFromUser();
    setOriginToUser();
  }, []);

  const handleFromUserOnChange = (event) => {
    const username = event.target.value;
    setFromUser(username);
    setFromUserName(setNickname(username));
    setFromUserAvatar(setAvatar(username));
    // document.getElementById("fromUserName").value = setNickname(username);
    // document.getElementById("fromUserAvatar").innerHTML = "<img src='".concat(
    //   setAvatar(username),
    //   "' class='MuiAvatar-img css-1v6zmq-MuiAvatar-img'>"
    // );
  };

  const handleToUserOnChange = (event) => {
    const username = event.target.value;
    setToUser(username);
    setToUserName(setNickname(username));
    setToUserAvatar(setAvatar(username));
    // document.getElementById("toUserName").value = setNickname(username);
    // document.getElementById("toUserAvatar").innerHTML = "<img src='".concat(
    //   setAvatar(username),
    //   "' class='MuiAvatar-img css-1v6zmq-MuiAvatar-img'>"
    // );
  };

  // emoji mart
  const [showEmojis, setShowEmojis] = useState(false);
  const addEmoji = (e) => {
    const sym = e.unified.split("-");
    const codesArray = [];
    sym.forEach((el) => codesArray.push("0x".concat(el)));
    const emoji = String.fromCodePoint(...codesArray);
    const originName = document.getElementById("remark").value;
    document.getElementById("remark").value = originName.concat(emoji);
  };

  // Form Validation
  const [amountInputStatus, setAmountInputStatus, amountInputStatusRef] = useState(false);
  const [amountInputHelperText, setAmountInputHelperTest] = useState("");
  const [categoryInputStatus, setCategoryInputStatus, categoryInputStatusRef] = useState(false);
  const [categoryInputHelperText, setCatogoryInputHelperText] = useState("");
  const [nameInputStatus, setNameInputStatus, nameInputStatusRef] = useState(false);
  const [nameInputHelperText, setNameInputHelperText] = useState("");

  const handleAllForm = () => {
    if (
      amountInputStatusRef.current === false &&
      categoryInputStatusRef.current === false &&
      nameInputStatusRef.current === false
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  };

  const handleAmountOnChange = (event) => {
    const amount = event.target.value;
    if (amount <= 0 || amount === null) {
      setAmountInputStatus(true);
      setAmountInputHelperTest(t("required"));
    }
    if (amount > 0) {
      setAmountInputStatus(false);
      setAmountInputHelperTest("");
    }
    handleAllForm();
  };

  const handleCategoryOnChange = (event) => {
    const category = event.target.value;
    if (category === "" || category === null) {
      setCategoryInputStatus(true);
      setCatogoryInputHelperText(t("required"));
    }
    if (category !== "" && category !== null) {
      setCategoryInputStatus(false);
      setCatogoryInputHelperText("");
    }
    handleAllForm();
  };

  const handleNameOnChange = (event) => {
    const name = event.target.value;
    if (name.trim().length === 0 || name === null) {
      setNameInputStatus(true);
      setNameInputHelperText(t("required"));
    }
    if (name.trim().length !== 0 && name !== null) {
      setNameInputStatus(false);
      setNameInputHelperText("");
    }
    handleAllForm();
  };

  if (isLoading) {
    return (
      <MDBox py={3}>
        <Card />
      </MDBox>
    );
  }

  return (
    <MDBox
      bgColor={darkMode ? "grey-900" : "grey-100"}
      borderTop={5}
      borderBottom={3}
      borderColor="info.light"
      borderRadius="lg"
      position="relative"
      pb={1}
      pt={3}
      px={3}
    >
      <MDBox component="form" role="form">
        <TimeForm dataTime={bill.datetime} dataTz={bill.tz} />
        <Grid container spacing={2}>
          <Grid item xs={4} md={3} lg={3}>
            <Autocomplete
              id="currency"
              disableClearable
              key={bill.currency}
              defaultValue={bill.currency}
              options={currencies.map((currency) => currency.code)}
              size="small"
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label={t("currency")}
                  InputProps={{ ...params.InputProps, sx: { height: 47 }, style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16, padding: 1 } }}
                  disabled
                />
              )}
            />
          </Grid>
          <Grid item xs={8} md={9} lg={9}>
            <MDInput
              type="text"
              id="amount"
              name="amount"
              label={t("amount")}
              onChange={handleAmountOnChange}
              onBlur={handleAmountOnChange}
              key={bill.amount}
              placeholder={getAmountZero()}
              defaultValue={getAmount()}
              InputProps={{
                style: { fontSize: 16 },
                inputComponent: AmountFormat,
              }}
              InputLabelProps={{ style: { padding: 1 } }}
              fullWidth
              helperText={amountInputHelperText}
              error={amountInputStatus}
            />
          </Grid>
        </Grid>
        <MDBox pt={4} pb={3} px={1}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={3} lg={3}>
              <MDInput
                type="text"
                id="category"
                label={t("category")}
                onChange={handleCategoryOnChange}
                onBlur={handleCategoryOnChange}
                key={bill.category}
                defaultValue={bill.category}
                variant="standard"
                InputProps={{
                  sx: { height: 32 },
                  style: { fontSize: 16 },
                }}
                InputLabelProps={{ style: { padding: 1 } }}
                select
                fullWidth
                helperText={categoryInputHelperText}
                error={categoryInputStatus}
              >
                {categories.map((category) => {
                  if (billType === "transfer") {
                    if (
                      category.features.filter((feature) => feature === "transfer").length !== 0
                    ) {
                      return (
                        <MenuItem key={category.id} value={category.english_name}>
                          {t(category.english_name)}
                        </MenuItem>
                      );
                    }
                  }
                  return null;
                })}
              </MDInput>
            </Grid>
            <Grid item xs={8} md={9} lg={9}>
              <MDInput
                type="text"
                id="name"
                label={t("name")}
                onChange={handleNameOnChange}
                onBlur={handleNameOnChange}
                key={bill.name}
                defaultValue={bill.name}
                variant="standard"
                inputProps={{
                  maxLength: 30,
                  style: { fontSize: 16 },
                }}
                InputLabelProps={{ style: { padding: 1 } }}
                fullWidth
                helperText={nameInputHelperText}
                error={nameInputStatus}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <MDInput
                type="textarea"
                id="remark"
                label={t("remark")}
                key={bill.remark}
                defaultValue={bill.remark}
                variant="standard"
                InputProps={{
                  inputProps: { maxLength: 300 },
                  style: { fontSize: 16 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <MDButton
                        variant="text"
                        color="secondary"
                        size="medium"
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowEmojis(!showEmojis);
                        }}
                        iconOnly
                      >
                        <Icon>add_reaction</Icon>
                      </MDButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ style: { padding: 1 } }}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </MDBox>
        {showEmojis && (
          <Picker
            data={data}
            onEmojiSelect={addEmoji}
            onClickOutside={() => setShowEmojis(false)}
            maxFrequentRows="2"
            perLine="7"
            skin="1"
            previewPosition="none"
          />
        )}
        <MDBox py={1}>
          <Card>
            <MDBox pt={2} pb={3} px={3}>
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={1.5} md={1.5} lg={1.5} container justifyContent="flex-end">
                  <MDAvatar
                    id="fromUserAvatar"
                    src={fromUserAvatar}
                    bgColor="secondary"
                    sx={{
                      height: { xs: 30, md: 50, lg: 50 },
                      width: { xs: 30, md: 50, lg: 50 },
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={4} container direction="column">
                  <MDInput
                    type="text"
                    id="fromUser"
                    label={t("from")}
                    variant="standard"
                    onChange={handleFromUserOnChange}
                    value={fromUser}
                    InputProps={{
                      sx: { height: 32 },
                      style: { fontSize: 16 },
                    }}
                    InputLabelProps={{ style: { padding: 1 } }}
                    select
                    fullWidth
                  >
                    {memberships.map((member) => {
                      if (member.status === "member") {
                        return (
                          <MenuItem key={member.id} value={member.user}>
                            {member.user}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </MDInput>
                  <MDInput
                    type="text"
                    id="fromUserName"
                    variant="standard"
                    value={fromUserName}
                    InputProps={{ style: { fontSize: 16 } }}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  lg={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Icon>arrow_forward</Icon>
                </Grid>
                <Grid item xs={4} md={4} lg={4} container direction="column">
                  <MDInput
                    type="text"
                    id="toUser"
                    label={t("to")}
                    variant="standard"
                    onChange={handleToUserOnChange}
                    value={toUser}
                    InputProps={{
                      sx: { height: 32 },
                      style: { fontSize: 16 },
                    }}
                    InputLabelProps={{ style: { padding: 1 } }}
                    select
                    fullWidth
                  >
                    {memberships.map((member) => {
                      if (member.status === "member") {
                        return (
                          <MenuItem key={member.id} value={member.user}>
                            {member.user}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </MDInput>
                  <MDInput
                    type="text"
                    id="toUserName"
                    variant="standard"
                    value={toUserName}
                    InputProps={{ style: { fontSize: 16 } }}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={1.5} md={1.5} lg={1.5}>
                  <MDAvatar
                    id="toUserAvatar"
                    src={toUserAvatar}
                    bgColor="secondary"
                    sx={{
                      height: { xs: 30, md: 50, lg: 50 },
                      width: { xs: 30, md: 50, lg: 50 },
                    }}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill
TransferForm.defaultProps = {
  billType: "transfer",
};

// Typechecking props for the Bill
TransferForm.propTypes = {
  usergroup: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    feature: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    members: PropTypes.instanceOf(Array),
  }).isRequired,
  memberships: PropTypes.instanceOf(Array).isRequired,
  bill: PropTypes.shape({
    type: PropTypes.string,
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tz: PropTypes.string.isRequired,
    datetime: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    remark: PropTypes.string.isRequired,
    usergroup: PropTypes.string.isRequired,
    payment_record: PropTypes.instanceOf(Array),
    share_record: PropTypes.instanceOf(Array),
  }).isRequired,
  billType: PropTypes.string,
  setValidForm: PropTypes.func.isRequired,
};

export default TransferForm;
