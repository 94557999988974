// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useLocation } from "react-router-dom";
// UsergroupListPage components
import UsergroupListPage from "layouts/usergroups/components/UsergroupListPage";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import GroupSetNavbar from "examples/Navbars/GroupSetNavbar";
import Footer from "examples/Footer";
// http-api-tool
import axios from "axios";
// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;

function UsergroupSet() {
  const location = useLocation();
  const usergroupSetId = location.pathname.split("/")[2];

  const [usergroupSet, setUsergroupSet] = useState({});
  const [latestNotifications, setLatestNotifications] = useState([]);
  const [countOfUnreads, setCountOfUnreads] = useState([]);

  const usergroupSetsBackend = axios.create({
    baseURL: `${backendURL}/usergroupsets/${usergroupSetId}`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const notificationsBackend = axios.create({
    baseURL: `${backendURL}/notifications/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getNotificationData = async () => {
    try {
      const lateatNotificationResponse = await notificationsBackend.get("get_latest_one");
      const lateatNotificationResponseData = await lateatNotificationResponse.data;
      setLatestNotifications(lateatNotificationResponseData);
      const countOfUnreadResponse = await notificationsBackend.get("count_of_unread");
      const countOfUnreadResponseData = await countOfUnreadResponse.data;
      setCountOfUnreads(countOfUnreadResponseData);
    } catch (error) {
      // console.log("error");
    }
  };

  const getUsergroupSetData = async () => {
    try {
      const usergroupSetResponse = await usergroupSetsBackend.get("");
      const usergroupSetResponseData = await usergroupSetResponse.data;
      setUsergroupSet(usergroupSetResponseData);
    } catch (error) {
      // console.log("error");
    }
  };

  useEffect(() => {
    getNotificationData();
    getUsergroupSetData();
  }, [usergroupSetId]);

  const [usergroups, setUsergroups] = useState([]);
  const usergroupsBackend = axios.create({
    baseURL: `${backendURL}/usergroups/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const getUsergroupsData = async () => {
    let urlparams = "?ids=";
    if (Object.keys(usergroupSet).length > 0) {
      usergroupSet.usergroups.map((usegroupId) => {
        urlparams = urlparams.concat(usegroupId, ",");
        return null;
      });
    }
    if (urlparams !== "?ids=") {
      try {
        const usergroupsResponse = await usergroupsBackend.get(urlparams.slice(0, -1));
        const usergroupsResponseData = await usergroupsResponse.data;
        setUsergroups(usergroupsResponseData);
      } catch (error) {
        // console.log("error");
      }
    }
  };
  useEffect(() => {
    getUsergroupsData();
  }, [usergroupSet]);

  return (
    <DashboardLayout>
      <GroupSetNavbar />
      {usergroups.length > 0 && (
        <UsergroupListPage
          originUsergroups={usergroups}
          latestNotifications={latestNotifications}
          countOfUnreads={countOfUnreads}
        />
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default UsergroupSet;
