// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useLocation, useNavigate } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// Material Dashboard 2 React components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
// import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import UsergroupCheckList from "layouts/usergroups/usergroupSets/components/UsergroupCheckList";
import IconSelecter from "layouts/usergroups/usergroupSets/components/IconSelecter";
// http-api-tool
import axios from "axios";
// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

function UsergroupSetDetail() {
  const location = useLocation();
  const usergroupSetId = location.pathname.split("/")[2];
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [usergroupSet, setUsergroupSet] = useState({});
  const usergroupSetsBackend = axios.create({
    baseURL: `${backendURL}/usergroupsets/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const getUsergroupSetData = async () => {
    try {
      const usergroupSetResponse = await usergroupSetsBackend.get(usergroupSetId);
      const usergroupSetResponseData = await usergroupSetResponse.data;
      setUsergroupSet(usergroupSetResponseData);
    } catch (error) {
      // console.log("error");
    }
  };

  const [usergroups, setUsergroups] = useState([]);
  const usergroupsBackend = axios.create({
    baseURL: `${backendURL}/usergroups/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getUsergroupsData = async () => {
    try {
      const usergroupsResponse = await usergroupsBackend.get("");
      const usergroupsResponseData = await usergroupsResponse.data;
      setUsergroups(usergroupsResponseData);
    } catch (error) {
      // console.log("error");
    }
  };

  useEffect(() => {
    getUsergroupsData();
    getUsergroupSetData();
  }, []);

  const [checked, setChecked] = useState({});
  const setOriginCheckedDict = () => {
    const checkedDict = {};
    usergroups.map((usergroup) => {
      const key = usergroup.id;
      if (usergroupSet.usergroups.find((usergroupId) => usergroupId === key) !== undefined) {
        checkedDict[key] = true;
      } else {
        checkedDict[key] = false;
      }
      return null;
    });
    setChecked(checkedDict);
  };

  useEffect(() => {
    if (usergroups.length > 0 && Object.keys(usergroupSet).length > 0) {
      setOriginCheckedDict();
    }
  }, [usergroups, usergroupSet]);

  // Alert for Create usergroupSet
  const [successSwitch, setSuccessSwitch] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorSwitch, setErrorSwitch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleClose = () => {
    setSuccessSwitch(false);
    setErrorSwitch(false);
  };

  const editUsergroupSet = async () => {
    const usergroupList = [];
    Object.entries(checked).map((checkItem) => {
      if (checkItem[1] === true) {
        usergroupList.push(checkItem[0]);
      }
      return null;
    });
    if (usergroupList.length === 0) {
      setErrorSwitch(true);
      setErrorMessage(t("select at least one group"));
    } else {
      try {
        await usergroupSetsBackend
          .put(usergroupSetId.concat("/"), {
            user: localStorage.getItem("username"),
            usergroups: usergroupList,
            name: document.getElementById("name").value,
            icon: document.getElementById("iconSelect").value,
          })
          .then(() => {
            setSuccessSwitch(true);
            setSuccessMessage(t("category edited successfully"));
          })
          .then(() => {
            setTimeout(() => {
              navigate(`/groupsets/${usergroupSetId}`);
              navigate(0);
            }, 1000);
          });
      } catch (error) {
        // console.log("error", error);
        setErrorSwitch(true);
        setErrorMessage(t("required fields are not filled in"));
      }
    }
  };

  const deleteUsergroupSet = async () => {
    try {
      await usergroupSetsBackend
        .delete(usergroupSetId)
        .then(() => {
          setSuccessSwitch(true);
          setSuccessMessage(t("category deleted successfully"));
        })
        .then(() => {
          setTimeout(() => {
            navigate(`/groups/`);
            navigate(0);
          }, 1000);
        });
    } catch (error) {
      // console.log("error", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Helmet>
        <title>
          {t("edit category")} | {appName}
        </title>
      </Helmet>
      <MDBox pt={2} pb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} lg={8}>
            <MDTypography variant="h5" textTransform="capitalize" align="center">
              {t("edit category")}
            </MDTypography>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={5} lg={5}>
                {Object.keys(usergroupSet).length > 0 && (
                  <MDBox pt={2} px={1}>
                    <IconSelecter icon={usergroupSet.icon} />
                  </MDBox>
                )}
              </Grid>
              <Grid item xs={12} md={7} lg={7}>
                {Object.keys(usergroupSet).length > 0 && (
                  <MDBox pt={2} px={1}>
                    <MDInput
                      label={t("Enter category name")}
                      type="text"
                      id="name"
                      // variant="standard"
                      defaultValue={usergroupSet.name}
                      InputProps={{ style: { fontSize: 16 }, sx: { height: 46 } }}
                      InputLabelProps={{ style: { fontSize: 16, padding: 1 } }}
                      fullWidth
                    />
                  </MDBox>
                )}
              </Grid>
            </Grid>
            <UsergroupCheckList usergroups={usergroups} checked={checked} setChecked={setChecked} />
            <MDBox pb={3}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={4} md={3} lg={3}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => editUsergroupSet()}
                    fullWidth
                  >
                    <Icon>edit</Icon>
                    &nbsp;{t("edit")}
                  </MDButton>
                </Grid>
                <Grid item xs={4} md={3} lg={3}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => deleteUsergroupSet()}
                    fullWidth
                  >
                    <Icon>delete</Icon>
                    &nbsp;{t("delete")}
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Snackbar
        open={successSwitch}
        autoHideDuration={10000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSwitch}
        autoHideDuration={10000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Footer />
    </DashboardLayout>
  );
}

export default UsergroupSetDetail;
