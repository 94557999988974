// react
import useState from "react-usestateref";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// http-api-tool
import axios from "axios";

import * as qs from "qs";

// env
const lineChannelId = process.env.REACT_APP_LINE_CHANNEL_ID;
const lineChannelSecret = process.env.REACT_APP_LINE_CHANNEL_SECRET;
const drftoken = process.env.REACT_APP_DRF_TOKEN;
const drfLineClientId = process.env.REACT_APP_DRF_LINE_CLIENT_ID;
const drfLineClientSecret = process.env.REACT_APP_DRF_LINE_CLIENT_SECRET;

const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;

function LineAuthCallback() {
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);

  // 確保Login的call cpi 流程只跑一次，如果沒設此項，會跑2次以上
  const [called, setCall] = useState(false);

  if (urlParams.has("state") && urlParams.has("code") && called === false) {
    const state = urlParams.get("state");
    const code = urlParams.get("code");

    /*
    state will determine the navigation when finish Line login.
    If state="login", navigate to /groups.
    If the state contains usergroup, inviter, timecode, navigate to /groups/memebrs/application page.
    */
    const stateParams = {};
    state.split("_").map((param) => {
      const key = param.split("=")[0];
      const value = param.split("=")[1];
      stateParams[key] = value;
      return null;
    });
    const memberApplicationUrl = "/groups/members/application/".concat(
      "?usergroup=",
      stateParams.usergroup,
      "&inviter=",
      stateParams.inviter,
      "&timecode=",
      stateParams.timecode
    );

    const data = {
      grant_type: "authorization_code",
      code,
      redirect_uri: `${frontendURL}/authentication/line/auth-callback`,
      client_id: `${lineChannelId}`,
      client_secret: `${lineChannelSecret}`,
    };
    setCall(true);
    // Get Line.me access_token
    const lineLogin = async () => {
      try {
        await axios({
          method: "post",
          url: "https://api.line.me/oauth2/v2.1/token",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          data: qs.stringify(data),
        }).then(async (lineResponse) => {
          // Get api.allbill.io access token
          try {
            await axios({
              method: "post",
              url: `${backendURL}/auth/convert-token`,
              headers: { Authorization: `JWT ${drftoken}` },
              data: {
                token: lineResponse.data.access_token,
                backend: "line",
                grant_type: "convert_token",
                client_id: drfLineClientId,
                client_secret: drfLineClientSecret,
              },
            }).then(async (convertTokenResponse) => {
              const accessToken = convertTokenResponse.data.access_token;
              const refreshToken = convertTokenResponse.data.refresh_token;
              localStorage.setItem("accessToken", accessToken);
              localStorage.setItem("refreshToken", refreshToken);
              // Get userinfo by token, then navigate to /groups
              try {
                await axios({
                  method: "post",
                  url: `${backendURL}/userinfo/`,
                  headers: { Authorization: `Bearer ${accessToken}` },
                })
                  .then((userinfoResponse) => {
                    localStorage.setItem("userId", userinfoResponse.data.id);
                    localStorage.setItem("username", userinfoResponse.data.username);
                  })
                  .then(() => {
                    if (state === "login") {
                      navigate("/groups");
                      navigate(0);
                    }
                    if (state !== "login") {
                      navigate(memberApplicationUrl);
                    }
                  });
              } catch (error) {
                // Userinfo error
                navigate("/authentication/sign-in");
              }
            });
          } catch (error) {
            // Convert token error
            navigate("/authentication/sign-in");
          }
        });
      } catch (error) {
        // Line Login error
        navigate("/authentication/sign-in");
      }
    };
    lineLogin();
  }
  return null;
}

export default LineAuthCallback;
