// timeZone
import moment from "moment-timezone";
import "moment/locale/zh-tw";

// i18n
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// locales
import en from "locales/en.json";
import zhTW from "locales/zh-TW.json";

const zhTWReverse = {};
Object.entries(zhTW).forEach((obj) => {
  const key = obj[1];
  const value = obj[0];
  zhTWReverse[key] = value;
});

const resources = {
  en: {
    translation: en,
  },
  zhTW: {
    translation: zhTW,
  },
  zhTWReverse: {
    translation: zhTWReverse,
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "en",
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

// set locale time
if (i18n.language === "en") {
  moment.locale("en");
} else if (i18n.language === "zhTW") {
  moment.locale("zh-tw");
}

export default i18n;
