/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import AppBar from "@mui/material/AppBar";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
// import breakpoints from "assets/theme/base/breakpoints";

// Images
// import burceMars from "assets/images/bruce-mars.jpg";
// import backgroundImage from "assets/images/bg-profile.jpeg";
// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;

function Header({ children, avatar, username, firstName }) {
  // const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  // const [tabValue, setTabValue] = useState(0);

  const avatarUrl = backendURL.concat(avatar);
  // useEffect(() => {
  // A function that sets the orientation state of the tabs.
  // function handleTabsOrientation() {
  //   return window.innerWidth < breakpoints.values.sm
  //     ? setTabsOrientation("vertical")
  //     : setTabsOrientation("horizontal");
  // }

  /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
  // window.addEventListener("resize", handleTabsOrientation);

  // Call the handleTabsOrientation function to set the state with the initial value.
  // handleTabsOrientation();

  // Remove event listener on cleanup
  //   return () => window.removeEventListener("resize", handleTabsOrientation);
  // }, [tabsOrientation]);

  // const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <MDBox position="relative" mb={5}>
      <Card
        sx={{
          position: "relative",
          mt: 0,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={avatarUrl} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {firstName}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {username}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
  avatar: "",
  username: "",
  firstName: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  avatar: PropTypes.string,
  username: PropTypes.string,
  firstName: PropTypes.string,
};

export default Header;
