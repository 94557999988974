/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
// import Dashboard from "layouts/dashboard";
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import LineAuthCallback from "layouts/authentication/sign-in/lineAuthCallback";
// import SignUp from "layouts/authentication/sign-up";
import Usergroups from "layouts/usergroups";
import UsergroupDetail from "layouts/usergroups/detail";
import UsergroupCreate from "layouts/usergroups/create";
import UsergroupSets from "layouts/usergroups/usergroupSets";
import UsergroupSetDetail from "layouts/usergroups/usergroupSets/detail";
import UsergroupSetCreate from "layouts/usergroups/usergroupSets/create";
import Bills from "layouts/usergroups/bills";
import BillDetail from "layouts/usergroups/bills/detail";
import BillCreate from "layouts/usergroups/bills/create";
import Members from "layouts/usergroups/members";
import MemberInvite from "layouts/usergroups/members/invite";
import MemberApplication from "layouts/usergroups/members/application";
import Logs from "layouts/usergroups/logs";
import Statistics from "layouts/usergroups/statistics";
import About from "layouts/about";
import Footer from "examples/Footer";
// @mui icons
import Icon from "@mui/material/Icon";

// http-api-tool
import axios from "axios";
// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;

const usergroupSetsBackend = axios.create({
  baseURL: `${backendURL}/usergroupsets/`,
  headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
});

const getUsergroupSets = async () => {
  const usergroupSetRoutes = [];
  try {
    const response = await usergroupSetsBackend.get("");
    const responseData = await response.data;
    responseData.map((usergroupSet) => {
      usergroupSetRoutes.push({
        type: "collapse",
        name: usergroupSet.name,
        key: usergroupSet.id,
        icon: <Icon fontSize="small">{usergroupSet.icon}</Icon>,
        route: "/groupsets/".concat(usergroupSet.id),
        component: <UsergroupSets />,
      });
      return null;
    });
  } catch (error) {
    // console.log("error", error);
  }
  return usergroupSetRoutes;
};

const defaultRoutes = [
  {
    type: "collapse",
    name: "all group",
    key: "groups",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/groups",
    component: <Usergroups />,
  },
  {
    name: "groupDetail",
    key: "groupdetail",
    route: "/groups/:id/settings",
    component: <UsergroupDetail />,
  },
  {
    name: "groupCreate",
    key: "groupcreate",
    route: "/groups/create",
    component: <UsergroupCreate />,
  },
  {
    name: "bills",
    key: "bills",
    route: "/groups/:id/bills",
    component: <Bills />,
  },
  {
    name: "billDetail",
    key: "billdetail",
    route: "/groups/:id/bills/:id",
    component: <BillDetail />,
  },
  {
    name: "billCreate",
    key: "billcreate",
    route: "/groups/:id/bills/create",
    component: <BillCreate />,
  },
  {
    name: "members",
    key: "members",
    route: "/groups/:id/members",
    component: <Members />,
  },
  {
    name: "memberInvite",
    key: "memberinvite",
    route: "/groups/:id/members/invite",
    component: <MemberInvite />,
  },
  {
    name: "logs",
    key: "logs",
    route: "/groups/:id/logs",
    component: <Logs />,
  },
  {
    name: "statistics",
    key: "statistics",
    route: "/groups/:id/statistics",
    component: <Statistics />,
  },
  {
    name: "memberApplication",
    key: "memberApplication",
    route: "/groups/members/application",
    component: <MemberApplication />,
  },
  {
    name: "notification",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    name: "profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    name: "about",
    key: "about",
    route: "/about",
    component: <About />,
  },
  {
    name: "multiLangAbout",
    key: "about",
    route: ":lang/about",
    component: <About />,
  },
  {
    name: "footer",
    key: "footer",
    route: "/footer",
    component: <Footer />,
  },
  {
    name: "singIn",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    name: "lineAuthCallback",
    key: "lineAuthCallback",
    route: "/authentication/line/auth-callback",
    component: <LineAuthCallback />,
  },
];

const usergroupSetRoute = [
  {
    type: "collapse",
    name: "",
    key: "groupsetcreate",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/groupsets/create",
    component: <UsergroupSetCreate />,
  },
  {
    key: "groupsetdetail",
    route: "/groupsets/:id/settings",
    component: <UsergroupSetDetail />,
  },
];

const routes = defaultRoutes.concat(await getUsergroupSets(), usergroupSetRoute);

export default routes;
