// i18n
import { useTranslation } from "react-i18next";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import MDAvatar from "components/MDAvatar";
// import MDInput from "components/MDInput";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
// example image
// import allBillPng from "assets/images/favicon-128x128-dark.ico";

function Bill({
  type,
  name,
  remark,
  category,
  currency,
  usergroupCurrency,
  amount,
  foreignAmount,
  myPaymentAmount,
  myPaymentForeignAmount,
  myShareAmount,
  myShareForeignAmount,
  href,
}) {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const setBoardColor = (billType) => {
    if (billType === "expense") {
      return "secondary.light";
    }
    if (billType === "income") {
      return "primary.light";
    }
    if (billType === "transfer") {
      return "info.light";
    }
    return "";
  };

  return (
    <MDBox
      component="a"
      href={href}
      display="flex"
      borderLeft={3}
      // borderRight={3}
      borderColor={setBoardColor(type)}
      bgColor={darkMode ? "grey-900" : "grey-100"}
      borderRadius="lg"
      p={1}
      mt={1}
    >
      <Grid container spacing={0} alignItems="stretch">
        <Grid item xs={3} md={2.5} lg={2} container alignItems="center">
          <MDBox
            sx={{
              height: { xs: 70, md: 80, lg: 80 },
              width: { xs: 70, md: 80, lg: 80 },
            }}
            borderRadius="lg"
            bgColor="black"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <MDTypography variant="h1" color="white">
              {name.slice(0, 1)}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="stretch"
          justifyContent="space-between"
          item
          xs={9}
          md={9.5}
          lg={10}
        >
          <Grid item container justifyContent="space-between">
            <MDTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize"
              maxWidth="70%"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden !important"
            >
              {name}
            </MDTypography>
            <MDTypography
              variant="button"
              sx={{ borderRadius: 1 }}
              color="secondary"
              bgcolor="light.main"
              fontWeight="medium"
              textTransform="capitalize"
              maxWidth="30%"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden !important"
            >
              {t(category)}
            </MDTypography>
          </Grid>
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            textTransform="capitalize"
            maxWidth="90%"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden !important"
          >
            {remark}
          </MDTypography>
          <Grid item container justifyContent="space-between">
            <Grid item>
              {type === "expense" && parseFloat(myShareAmount) - parseFloat(myPaymentAmount) > 0 && (
                <MDBox display="flex">
                  <MDTypography variant="button" color="primary" textTransform="capitalize">
                    {t("in advance")} &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="medium">
                    {currency === usergroupCurrency
                      ? Math.abs(
                          parseFloat((myShareAmount - myPaymentAmount).toFixed(2))
                        ).toLocaleString()
                      : Math.abs(
                          parseFloat((myShareForeignAmount - myPaymentForeignAmount).toFixed(2))
                        ).toLocaleString()}
                  </MDTypography>
                </MDBox>
              )}
              {type === "expense" && parseFloat(myShareAmount) - parseFloat(myPaymentAmount) < 0 && (
                <MDBox display="flex">
                  <MDTypography variant="button" textTransform="capitalize">
                    {t("to be paid")} &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="medium">
                    {currency === usergroupCurrency
                      ? Math.abs(
                          parseFloat((myShareAmount - myPaymentAmount).toFixed(2))
                        ).toLocaleString()
                      : Math.abs(
                          parseFloat((myShareForeignAmount - myPaymentForeignAmount).toFixed(2))
                        ).toLocaleString()}
                  </MDTypography>
                </MDBox>
              )}
              {type === "income" && parseFloat(myShareAmount) - parseFloat(myPaymentAmount) > 0 && (
                <MDBox display="flex">
                  <MDTypography variant="button" color="primary" textTransform="capitalize">
                    {t("in advance")} &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="medium">
                    {currency === usergroupCurrency
                      ? Math.abs(
                          parseFloat((myShareAmount - myPaymentAmount).toFixed(2))
                        ).toLocaleString()
                      : Math.abs(
                          parseFloat((myShareForeignAmount - myPaymentForeignAmount).toFixed(2))
                        ).toLocaleString()}
                  </MDTypography>
                </MDBox>
              )}
              {type === "income" && parseFloat(myShareAmount) - parseFloat(myPaymentAmount) < 0 && (
                <MDBox display="flex">
                  <MDTypography variant="button" textTransform="capitalize">
                    {t("to be paid")} &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="medium">
                    {currency === usergroupCurrency
                      ? Math.abs(
                          parseFloat((myShareAmount - myPaymentAmount).toFixed(2))
                        ).toLocaleString()
                      : Math.abs(
                          parseFloat((myShareForeignAmount - myPaymentForeignAmount).toFixed(2))
                        ).toLocaleString()}
                  </MDTypography>
                </MDBox>
              )}
              {type === "transfer" && parseFloat(myShareAmount) - parseFloat(myPaymentAmount) > 0 && (
                <MDBox display="flex">
                  <MDTypography variant="button" textTransform="capitalize">
                    {t("sent")} &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="medium">
                    {currency === usergroupCurrency
                      ? Math.abs(
                          parseFloat((myShareAmount - myPaymentAmount).toFixed(2))
                        ).toLocaleString()
                      : Math.abs(
                          parseFloat((myShareForeignAmount - myPaymentForeignAmount).toFixed(2))
                        ).toLocaleString()}
                  </MDTypography>
                </MDBox>
              )}
              {type === "transfer" && parseFloat(myShareAmount) - parseFloat(myPaymentAmount) < 0 && (
                <MDBox display="flex">
                  <MDTypography variant="button" textTransform="capitalize">
                    {t("received")} &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="medium">
                    {currency === usergroupCurrency
                      ? Math.abs(
                          parseFloat((myShareAmount - myPaymentAmount).toFixed(2))
                        ).toLocaleString()
                      : Math.abs(
                          parseFloat((myShareForeignAmount - myPaymentForeignAmount).toFixed(2))
                        ).toLocaleString()}
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
            <Grid item>
              <MDBox display="flex" justifyContent="flex-end">
                <MDTypography variant="button" textTransform="capitalize">
                  {t("total")}&nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="medium">
                  {currency === usergroupCurrency
                    ? Math.abs(parseFloat(parseFloat(amount).toFixed(2)))
                        .toLocaleString()
                        .concat(" ", currency)
                    : Math.abs(parseFloat(parseFloat(foreignAmount).toFixed(2)))
                        .toLocaleString()
                        .concat(" ", currency)}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  foreignAmount: null,
  myPaymentForeignAmount: null,
  myShareForeignAmount: null,
};

// Typechecking props for the Bill
Bill.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  remark: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  usergroupCurrency: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  foreignAmount: PropTypes.string,
  myPaymentAmount: PropTypes.string.isRequired,
  myPaymentForeignAmount: PropTypes.string,
  myShareAmount: PropTypes.string.isRequired,
  myShareForeignAmount: PropTypes.string,
  href: PropTypes.string.isRequired,
};

export default Bill;
