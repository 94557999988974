// timeZone
import moment from "moment-timezone";
// i18n
import { useTranslation } from "react-i18next";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Alert from "@mui/material/Alert";
import MDTypography from "components/MDTypography";
// timeZone of current location
const cLTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

function LogListUnit({ notification }) {
  const { t } = useTranslation();
  return (
    <Alert
      severity={notification.level}
      color={notification.level}
      action={
        <MDTypography variant="bottun">
          {moment(notification.datetime_created).tz(cLTZ).format("HH:mm")}
        </MDTypography>
      }
    >
      <MDTypography variant="bottun" component="a">
        <MDTypography variant="bottun" fontWeight="medium">
          {notification.verb !== "welcome a new member" && notification.actor.nickname}
          &nbsp;
        </MDTypography>
        <MDTypography variant="bottun" fontWeight="medium">
          {t(notification.verb)} &nbsp;
        </MDTypography>
        {notification.description !== null && (
          <MDTypography variant="bottun">{notification.description} &nbsp;</MDTypography>
        )}
        {notification.actionobject !== null && (
          <MDTypography variant="bottun">
            {notification.actionobject.nickname}
            {notification.actionobject.name}
          </MDTypography>
        )}
      </MDTypography>
    </Alert>
  );
}

// Setting default values for the props of LogListUnit
LogListUnit.defaultProps = {};

// Typechecking props for the LogListUnit
LogListUnit.propTypes = {
  notification: PropTypes.instanceOf(Object).isRequired,
};

export default LogListUnit;
