// timeZone
import moment from "moment-timezone";
// React
import { useEffect } from "react";
import useState from "react-usestateref";

// i18n
import { useTranslation } from "react-i18next";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";

// @mui icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
// Material Dashboard 2 React components
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

// http-api-tool
import axios from "axios";
// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
// timeZone of current location
const cLTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

function TimeForm({ dataTz, dataTime }) {
  const { t } = useTranslation();

  // Get Timezone List
  const [timezones, setTimezones] = useState([]);

  const timezoneListBackend = axios.create({
    baseURL: `${backendURL}/timezones/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getTimezoneList = async () => {
    try {
      const timezoneResponse = await timezoneListBackend.get("");
      const timezoneResponseData = await timezoneResponse.data;
      setTimezones(timezoneResponseData);
    } catch (error) {
      // console.log("error");
    }
  };

  useEffect(() => {
    getTimezoneList();
  }, []);

  // Set Timezone and Time
  const [, setDataTZ, dataTZRef] = useState("");
  const [, setDataTZOffset, dataTZOffsetRef] = useState("");
  const [, setDataTime, dataTimeRef] = useState("");
  const [, setCLTZOffset, cLTZOffsetRef] = useState("");
  const [, setCLDataTime, cLDataTimeRef] = useState("");
  const [, setTimeDisplaySwitch, timeDisplaySwitchRef] = useState(true);

  const setTimezoneAndTime = () => {
    setDataTZ(dataTz);

    const dataTZOffsetString = moment(dataTime).tz(dataTz).format("Z");
    setDataTZOffset(dataTZOffsetString);

    const dataTimeString = moment(dataTime).tz(dataTz).format("YYYY-MM-DDTHH:mm");
    setDataTime(dataTimeString);

    const cLTZOffsetString = moment(dataTime).tz(cLTZ).format("Z");
    setCLTZOffset(cLTZOffsetString);

    const cLDataTimeString = moment(dataTime).tz(cLTZ).format("YYYY-MM-DDTHH:mm");
    setCLDataTime(cLDataTimeString);

    if (dataTimeString !== cLDataTimeString) {
      setTimeDisplaySwitch(true);
    }
    if (dataTimeString === cLDataTimeString) {
      setTimeDisplaySwitch(false);
    }
  };

  useEffect(() => {
    setTimezoneAndTime();
  }, [timezones]);

  // onChange Event for TimeZone and Time
  const handleDataTimeChange = (event) => {
    const inputDataTime = event.target.value;
    const inputDataTimezone = document.getElementById("dataTimezone").value;
    const inputDataTimeWithOffset = moment.tz(inputDataTime, inputDataTimezone);
    const outputDataTime = inputDataTimeWithOffset.tz(cLTZ).format("YYYY-MM-DDTHH:mm");
    setCLDataTime(outputDataTime);
  };

  const handleDataTimezoneChange = (event) => {
    const inputDataTime = document.getElementById("dataTime").value;
    const inputDataTimezone = event.target.innerHTML;
    const inputDataTimeWithOffset = moment.tz(inputDataTime, inputDataTimezone);
    const outputDataTime = inputDataTimeWithOffset.tz(cLTZ).format("YYYY-MM-DDTHH:mm");
    setCLDataTime(outputDataTime);
    const outputDataTimeOffset = moment.tz(inputDataTime, inputDataTimezone).format("Z");
    setDataTZOffset(outputDataTimeOffset);
    if (inputDataTime !== outputDataTime) {
      setTimeDisplaySwitch(true);
    }
    if (inputDataTime === outputDataTime) {
      setTimeDisplaySwitch(false);
    }
  };

  return (
    <MDBox>
      <MDBox>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item xs={5} md={3} lg={3}>
            <InputLabel sx={{ pt: 0.5 }}>{t("timezone")}</InputLabel>
          </Grid>
          <Grid
            item
            component={MDBox}
            display={{ xs: "none", md: "block", lg: "block" }}
            md={1.2}
            lg={1.2}
          >
            <InputLabel sx={{ pt: 0.5 }}>UTC</InputLabel>
          </Grid>
          <Grid item xs={7} md={4.5} lg={4.5}>
            <InputLabel sx={{ pt: 0.5 }}>{t("time")}</InputLabel>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        {timeDisplaySwitchRef.current === true && (
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={5} md={3} lg={3}>
              <MDInput
                type="text"
                id="cLTimezone"
                key={cLTZ}
                value={cLTZ}
                InputProps={{
                  // sx: { height: 29.125, px: "12px" },
                  style: { fontSize: 16 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                fullWidth
                disabled
              />
            </Grid>
            <Grid
              item
              component={MDBox}
              display={{ xs: "none", md: "block", lg: "block" }}
              md={1.2}
              lg={1.2}
            >
              <MDInput
                type="text"
                id="cLTimezoneOffset"
                key={cLTZOffsetRef.current}
                value={cLTZOffsetRef.current}
                variant="standard"
                InputProps={{ style: { fontSize: 16 } }}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={7} md={4.5} lg={4.5}>
              <MDInput
                type="datetime-local"
                id="cLDataTime"
                key={cLDataTimeRef.current}
                value={cLDataTimeRef.current}
                variant="standard"
                InputProps={{ style: { fontSize: 16 } }}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        )}
      </MDBox>
      <MDBox pb={3}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item xs={5} md={3} lg={3}>
            <Autocomplete
              id="dataTimezone"
              disableClearable
              onChange={handleDataTimezoneChange}
              key={dataTZRef.current}
              defaultValue={dataTZRef.current}
              options={timezones.map((timezone) => timezone.name)}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  InputProps={{ ...params.InputProps, style: { fontSize: 16 } }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            component={MDBox}
            display={{ xs: "none", md: "block", lg: "block" }}
            md={1.2}
            lg={1.2}
          >
            <MDInput
              type="text"
              id="dataTimezoneOffset"
              key={dataTZOffsetRef.current}
              value={dataTZOffsetRef.current}
              variant="standard"
              InputProps={{ style: { fontSize: 16 } }}
              fullWidth
            />
          </Grid>
          <Grid item xs={7} md={4.5} lg={4.5}>
            <MDInput
              type="datetime-local"
              id="dataTime"
              onChange={handleDataTimeChange}
              key={dataTimeRef.current}
              defaultValue={dataTimeRef.current}
              variant="standard"
              InputProps={{ style: { fontSize: 16 } }}
              fullWidth
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of TimeForm
TimeForm.defaultProps = {};

// Typechecking props for the TimeForm
TimeForm.propTypes = {
  dataTz: PropTypes.string.isRequired,
  dataTime: PropTypes.string.isRequired,
};

export default TimeForm;
