// timeZone
import moment from "moment-timezone";
// React
import { useEffect } from "react";
import useState from "react-usestateref";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
// import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";

// BillListUnit components
import Usergroup from "layouts/usergroups/components/UsergroupListUnit";

// env
const frontendURL = process.env.REACT_APP_FRONTENDURL;

function UsergroupListPage({ originUsergroups, latestNotifications, countOfUnreads }) {
  const [usergroups, setUsergroups] = useState([]);

  // get usergroup latest notification & count of unread
  const getUsergroupLatestNotification = (usergroupId) => {
    const usergroupLatestNotification = latestNotifications.filter(
      (notification) => notification.usergroup === usergroupId
    );
    if (usergroupLatestNotification.length !== 0) {
      return usergroupLatestNotification[0].notification;
    }
    return null;
  };

  const getUsergroupCountOfUnread = (usergroupId) => {
    const usergroupCountOfUnread = countOfUnreads.filter(
      (notification) => notification.usergroup === usergroupId
    );
    if (usergroupCountOfUnread.length !== 0) {
      return usergroupCountOfUnread[0].count;
    }
    return null;
  };

  const sortUsergroupsData = () => {
    let i = 0;
    const usergroupListWithTime = [];
    const usergroupListWithNullTime = [];
    while (originUsergroups[i]) {
      const latestNotification = getUsergroupLatestNotification(originUsergroups[i].id);
      if (latestNotification === null) {
        usergroupListWithNullTime.push(originUsergroups[i]);
      }
      if (latestNotification !== null) {
        if (usergroupListWithTime.length === 0) {
          usergroupListWithTime.push(originUsergroups[i]);
        }
        if (usergroupListWithTime.length !== 0) {
          const thisTime = latestNotification.datetime_created;
          const listStartTime = getUsergroupLatestNotification(
            usergroupListWithTime[usergroupListWithTime.length - 1].id
          ).datetime_created;
          if (moment(thisTime).isBefore(listStartTime) === true) {
            usergroupListWithTime.push(originUsergroups[i]);
          }
          let j = 0;
          while (usergroupListWithTime[j]) {
            const listItemTime = getUsergroupLatestNotification(
              usergroupListWithTime[j].id
            ).datetime_created;
            if (moment(thisTime).isAfter(listItemTime)) {
              usergroupListWithTime.splice(j, 0, originUsergroups[i]);
              break;
            }
            j += 1;
          }
        }
      }
      i += 1;
    }
    const usergroupList = usergroupListWithTime.concat(usergroupListWithNullTime);
    setUsergroups(usergroupList);
  };

  useEffect(() => {
    sortUsergroupsData();
  }, [latestNotifications]);

  return (
    <MDBox pt={2} pb={3}>
      <MDBox
        sx={{
          mx: { xs: 0.5, md: 2, lg: 3 },
        }}
      >
        {usergroups.map((usergroup) => (
          <Usergroup
            key={usergroup.id}
            id={usergroup.id}
            name={usergroup.name}
            logo={usergroup.logo}
            currency={usergroup.currency}
            href={frontendURL.concat("/groups/", usergroup.id, "/bills")}
            latestNotification={getUsergroupLatestNotification(usergroup.id)}
            countOfUnread={getUsergroupCountOfUnread(usergroup.id)}
          />
        ))}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of UsergroupListPage
UsergroupListPage.defaultProps = {};

// Typechecking props for the UsergroupListPage
UsergroupListPage.propTypes = {
  originUsergroups: PropTypes.instanceOf(Array).isRequired,
  latestNotifications: PropTypes.instanceOf(Array).isRequired,
  countOfUnreads: PropTypes.instanceOf(Array).isRequired,
};

export default UsergroupListPage;
