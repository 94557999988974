// React
// import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useNavigate } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// heic/heic to jpeg
import heic2any from "heic2any";
// generate random filename for upload
import { v4 as uuidv4 } from "uuid";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// UsergroupForm components
import UsergroupForm from "layouts/usergroups/components/UsergroupForm";

import favicon from "assets/images/favicon-128x128-dark.ico";
// http-api-tool
import axios from "axios";
// env
const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

function UsergroupCreate() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // Alert for Create usergroup
  const [successSwitch, setSuccessSwitch] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorSwitch, setErrorSwitch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleClose = () => {
    setSuccessSwitch(false);
    setErrorSwitch(false);
  };

  // Create Usergroup
  const usergroupCreateBackend = axios.create({
    baseURL: `${backendURL}/usergroups/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const createUsergroupDetail = async () => {
    try {
      const formData = new FormData();
      formData.append(
        "feature",
        t(document.getElementById("feature").innerHTML, { lng: "zhTWReverse" })
      );
      formData.append("currency", document.getElementById("currency").value);
      formData.append("name", document.getElementById("name").value);
      if (document.getElementById("usergroupLogo").files.length > 0) {
        const file = document.getElementById("usergroupLogo").files[0];
        if (
          file.name.split(".").at(-1) === "heic" ||
          file.name.split(".").at(-1) === "HEIC" ||
          file.name.split(".").at(-1) === "heif" ||
          file.name.split(".").at(-1) === "HEIF"
        ) {
          await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.3,
          }).then((blob) => {
            formData.append("logo", blob, uuidv4().concat(".jpg"));
          });
        } else {
          formData.append("logo", file, uuidv4().concat(".jpg"));
        }
      }
      await usergroupCreateBackend
        .post("", formData)
        .then(() => {
          setSuccessSwitch(true);
          setSuccessMessage(t("group created successfully"));
        })
        .then(() => {
          setTimeout(() => {
            navigate(`/groups/`);
          }, 1000);
        });
    } catch (error) {
      // console.log("error");
      setErrorSwitch(true);
      setErrorMessage(t("required fields are not filled in"));
    }
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>
          {t("create group")} | {appName}
        </title>
      </Helmet>
      <DashboardNavbar />
      <MDBox pt={1}>
        <Grid container spacing={2}>
          <Grid item xs={4} md={3} lg={3}>
            <MDButton
              variant="text"
              color="secondary"
              align="left"
              href={frontendURL.concat("/groups/")}
              iconOnly
            >
              <ArrowBackOutlinedIcon />
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <Card>
        <MDBox pt={2} pb={1}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                {t("create group")}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <UsergroupForm logo={favicon} />
        <Snackbar
          open={successSwitch}
          autoHideDuration={10000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorSwitch}
          autoHideDuration={10000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {errorMessage}
          </Alert>
        </Snackbar>
        <MDBox pt={1} pb={2}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={6} md={3} lg={3}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => createUsergroupDetail()}
                fullWidth
              >
                <Icon>add</Icon>
                &nbsp;{t("create")}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
      <MDBox py={2} />
      <Footer />
    </DashboardLayout>
  );
}

export default UsergroupCreate;
