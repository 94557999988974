// timeZone
import moment from "moment-timezone";
// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useLocation, useNavigate } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import Alert from "@mui/material/Alert";
// import Collapse from "@mui/material/Collapse";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import GroupNavbar from "examples/Navbars/GroupNavbar";
import Footer from "examples/Footer";

import LogListUnit from "layouts/usergroups/logs/components/LogListUnit";
// http-api-tool
import axios from "axios";

// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

// timeZone of current location
const cLTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

function LogList() {
  const location = useLocation();
  const usergroupId = location.pathname.split("/")[2];
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [notifications, setNotifications] = useState([]);
  const notificationsBackend = axios.create({
    baseURL: `${backendURL}/notifications/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getBackendData = async () => {
    try {
      const notificationsResponse = await notificationsBackend.get(
        "?target_content_type=9&target_object_id=".concat(usergroupId)
      );
      const notificationsResponseData = await notificationsResponse.data;
      setNotifications(notificationsResponseData);
    } catch (error) {
      // console.log("error");
    }
  };

  const [usergroup, setUsergroup] = useState("");
  const usergroupBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const getUsergroupData = async () => {
    try {
      const usergroupResponse = await usergroupBackend.get("");
      const usergroupResponseData = await usergroupResponse.data;
      setUsergroup(usergroupResponseData);
    } catch (error) {
      navigate("/groups");
    }
  };

  useEffect(() => {
    getBackendData();
    getUsergroupData();
  }, []);

  let displayDate = moment().tz(cLTZ).format("YYYY/MM/DD");

  return (
    <DashboardLayout>
      {usergroup !== "" && (
        <Helmet>
          <title>
            {usergroup.name} | {appName}
          </title>
        </Helmet>
      )}
      <GroupNavbar />
      <MDBox
        mt={2}
        mb={3}
        sx={{
          minHeight: 640,
          mx: { xs: 0.5, md: 0.5, lg: 0 },
        }}
      >
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card sx={{ minHeight: 600 }}>
              <MDBox p={2}>
                <MDTypography variant="h5" align="center" textTransform="capitalize">
                  {t("logs")}
                  <br />
                  <hr />
                </MDTypography>
              </MDBox>
              <MDBox mx={1}>
                <MDTypography variant="h6" color="text" fontWeight="medium">
                  {notifications.length !== 0 &&
                    moment(notifications[0].datetime_created).tz(cLTZ).format("YYYY/MM/DD") ===
                      moment().tz(cLTZ).format("YYYY/MM/DD") &&
                    t("today")}
                </MDTypography>
              </MDBox>
              {notifications.length > 0 &&
                notifications.map((notification) => {
                  const dataDate = moment(notification.datetime_created)
                    .tz(cLTZ)
                    .format("YYYY/MM/DD");
                  if (dataDate !== displayDate) {
                    displayDate = dataDate;
                    return (
                      <MDBox mb={1} mx={1} key={notification.id}>
                        <MDBox>
                          <MDTypography variant="h6" color="text" fontWeight="medium">
                            {moment(notification.datetime_created)
                              .tz(cLTZ)
                              .format("YYYY/MM/DD | ddd")}
                          </MDTypography>
                        </MDBox>
                        <LogListUnit notification={notification} />
                      </MDBox>
                    );
                  }
                  if (dataDate === displayDate) {
                    return (
                      <MDBox mb={1} mx={1} key={notification.id}>
                        <LogListUnit notification={notification} />
                      </MDBox>
                    );
                  }
                  return null;
                })}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LogList;
