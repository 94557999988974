// route
import { useNavigate } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Icon from "@mui/material/Icon";
import Badge from "@mui/material/Badge";

// Material Dashboard 2 React components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
// example image
// import team5 from "assets/images/team-5.jpg";
// http-api-tool
import axios from "axios";
// timeZone
import moment from "moment-timezone";
// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
// timeZone of current location
const cLTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

function Usergroup({ id, name, logo, currency, latestNotification, countOfUnread }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();
  const { t } = useTranslation();

  // setTimeFormat
  const setTimeFormat = (inputTime) => {
    const outputTime = moment(inputTime).tz(cLTZ).format("MM/DD");
    return outputTime;
  };

  // mark_all_as_read
  const notificationsBackend = axios.create({
    baseURL: `${backendURL}/notifications/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const markAllAsRead = async () => {
    try {
      await notificationsBackend.patch("mark_all_as_read/", { usergroup: id }).then(() => {
        navigate(`/groups/${id}/bills`);
      });
    } catch (error) {
      // console.log("error", error);
    }
  };

  return (
    <MDBox
      component="a"
      href="#"
      onClick={markAllAsRead}
      display="flex"
      bgColor={darkMode ? "grey-900" : "grey-100"}
      borderRadius="lg"
      p={1}
      sx={{
        mt: { xs: 1, md: 2, lg: 2 },
      }}
    >
      <Grid container spacing={1} alignItems="stretch" justifyContent="space-between">
        <Grid item xs={2.5} md={1.5} lg={1} container alignItems="center">
          <MDAvatar alt="Avatar" size="lg" src={logo} />
        </Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          justifyContent="space-evenly"
          alignItems="stretch"
          item
          xs={8}
          md={9.5}
          lg={9.5}
        >
          <MDTypography
            variant="button"
            fontWeight="medium"
            maxWidth="100%"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden !important"
          >
            {currency} | {name}
          </MDTypography>
          <MDTypography
            variant="button"
            textGradient
            maxWidth="100%"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden !important"
          >
            {latestNotification !== null &&
              latestNotification.verb !== "welcome a new member" &&
              latestNotification.actor.nickname}
            &nbsp;
            {latestNotification !== null &&
              latestNotification.verb !== null &&
              t(latestNotification.verb)}
            &nbsp;
            {latestNotification !== null &&
              latestNotification.description !== null &&
              latestNotification.description}
            {latestNotification !== null &&
              latestNotification.actionobject !== null &&
              latestNotification.actionobject.name !== null &&
              latestNotification.actionobject.name}
            &nbsp;
            {latestNotification !== null &&
              latestNotification.actionobject !== null &&
              latestNotification.actionobject.nickname !== null &&
              latestNotification.actionobject.nickname}
          </MDTypography>
        </Grid>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="flex-start"
          item
          xs={1.5}
          md={1}
          lg={1}
        >
          <Grid item container justifyContent="center">
            <MDTypography variant="button" textGradient>
              {latestNotification !== null && setTimeFormat(latestNotification.datetime_created)}
            </MDTypography>
          </Grid>
          <Grid item container justifyContent="center">
            {countOfUnread !== null && (
              <Badge color="info" badgeContent={countOfUnread} max={999} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

// Setting default values for the props of Usergroup
Usergroup.defaultProps = {
  latestNotification: null,
  countOfUnread: null,
};

// Typechecking props for the Usergroup
Usergroup.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  latestNotification: PropTypes.objectOf(PropTypes.any),
  countOfUnread: PropTypes.number,
};

export default Usergroup;
