// timeZone and time
import moment from "moment-timezone";
// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useNavigate, useLocation } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
// import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import GroupNavbar from "examples/Navbars/GroupNavbar";
import Footer from "examples/Footer";
// QRCode
import { QRCode } from "react-qrcode-logo";
// http-api-tool
import axios from "axios";
// env
const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

function MemberInvite() {
  const location = useLocation();
  const usergroupId = location.pathname.split("/")[2];
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get Current Member
  const [, setMembers, membersRef] = useState([]);
  const [, setUsernames, usernamesRef] = useState([]);
  const memberListBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/memberships/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const getMemberList = async () => {
    try {
      const memberListResponse = await memberListBackend.get("");
      const memberListResponseData = await memberListResponse.data;
      setMembers(memberListResponseData);
      setUsernames([]);
      let i = 0;
      while (i < memberListResponseData.length) {
        const usernameData = memberListResponseData[i].user;
        setUsernames((username) => [...username, usernameData]);
        i += 1;
      }
    } catch (error) {
      // console.log("error", error);
    }
  };

  // don't display anything before usergroup data or error coming back
  const [usergroupLoading, setUsergroupLoading] = useState(true);

  // get usergroup logo for QR code
  const [usergroup, setUsergroup] = useState("");
  const usergroupBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getUsergroupDetail = async () => {
    try {
      const usergroupResponse = await usergroupBackend.get("");
      const usergroupResponseData = await usergroupResponse.data;
      setUsergroup(usergroupResponseData);
      setUsergroupLoading(false);
    } catch (error) {
      navigate("/groups");
    }
  };

  useEffect(() => {
    getMemberList();
    getUsergroupDetail();
  }, []);

  // Get User
  // Loading mode (Waiting for First Time getUserByParam Calling)
  const [pressSearch, setPressSearch] = useState(true);
  const [, setPreviewUser, previewUserRef] = useState([]);
  const [, setMemberStatus, memberStatusRef] = useState("");
  const [, setMemberId, memberIdRef] = useState("");
  const userBackend = axios.create({
    baseURL: `${backendURL}/searchusers/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getUserByParam = async () => {
    const param = document.getElementById("searchUser").value;
    if (param !== "") {
      const userResponse = await userBackend.get("?search=".concat(param));
      const userResponseData = await userResponse.data;
      setPressSearch(false);
      setPreviewUser(userResponseData);
      // Set member id & status for inviting button
      setMemberStatus("");
      setMemberId("");
      if (userResponseData.length > 0) {
        const member = membersRef.current.find(
          (membership) => membership.user === userResponseData[0].username
        );
        if (member !== undefined) {
          setMemberStatus(member.status);
          setMemberId(member.id);
        }
      }
    }
  };

  // Create Membership
  const membershipBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/memberships/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const inviteMember = async () => {
    try {
      await membershipBackend
        .post("", {
          inviter: `${localStorage.getItem("username")}`,
          user: previewUserRef.current[0].username,
          status: "invited",
          usergroup: usergroupId,
          invite_reason: "",
        })
        .then(() => {
          getMemberList();
          setMemberStatus("invited");
        })
        .then(() => {
          setTimeout(getUserByParam, 100);
        });
    } catch (error) {
      // console.log("error");
    }
  };

  // Cancel Member Invitation
  const cancelMemberInvite = async () => {
    try {
      await membershipBackend.delete(memberIdRef.current.toString()).then(() => {
        getMemberList();
      });
    } catch (error) {
      // console.log("error");
    }
  };

  // generate link and QR code
  const [link, setLink] = useState("");
  const generateLink = () => {
    const timeCode = moment().unix() + 3600;
    const randomNumber1 = Math.floor(Math.random() * 901) + 100;
    const randomNumber2 = Math.floor(Math.random() * 901) + 100;
    const linkString = frontendURL.concat(
      "/authentication/sign-in/?usergroup=",
      usergroupId,
      "&inviter=",
      localStorage.getItem("username"),
      "&timecode=",
      randomNumber1,
      timeCode,
      randomNumber2
    );
    setLink(linkString);
  };
  const [showQrCode, setShowQrCode] = useState(false);
  const generateQrCode = () => {
    generateLink();
    setShowQrCode(true);
  };

  // copy link
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    const copyText = document.getElementById("link");
    copyText.select();
    document.execCommand("copy");
    copyText.blur();
    if (document.getElementById("link").value.length > 100) {
      setCopied(true);
    }
  };

  return (
    <DashboardLayout>
      {usergroupLoading === false && (
        <Helmet>
          <title>
            {usergroup.name} | {appName}
          </title>
        </Helmet>
      )}
      <GroupNavbar />
      {usergroupLoading === false && (
        <MDBox>
          <MDBox
            py={3}
            sx={{
              width: { xs: 400, md: 320, lg: 320 },
              margin: "auto",
            }}
            borderRadius="lg"
          >
            <Card sx={{ height: 360 }}>
              <MDBox py={2}>
                <MDBox py={1}>
                  <Grid container spacing={1} justifyContent="center">
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      {t("search users")}
                    </MDTypography>
                  </Grid>
                </MDBox>
                <MDBox py={1}>
                  <Grid container spacing={1} justifyContent="center">
                    <Grid item>
                      <MDInput
                        type="text"
                        id="searchUser"
                        placeholder={t("enter username / email")}
                        sx={{ width: 280 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                variant="gradient"
                                color="secondary"
                                size="small"
                                onClick={getUserByParam}
                              >
                                <Icon>search</Icon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox py={1}>
                  <Grid container spacing={1} justifyContent="center">
                    {previewUserRef.current.length !== 0 && (
                      <Grid item>
                        <MDBox py={1} display="flex" alignItems="center" justifyContent="center">
                          <MDAvatar
                            size="xxl"
                            bgColor="light"
                            shadow="lg"
                            alt="Avatar"
                            variant="circular"
                            src={previewUserRef.current[0].avatar}
                          />
                        </MDBox>
                        <MDBox py={1} display="flex" alignItems="center" justifyContent="center">
                          <MDTypography variant="button" fontWeight="medium" align="center">
                            {previewUserRef.current[0].username}
                          </MDTypography>
                        </MDBox>
                        {usernamesRef.current.includes(previewUserRef.current[0].username) ===
                          true &&
                          memberStatusRef.current === "member" && (
                            <MDBox
                              py={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <MDButton
                                variant="gradient"
                                color="dark"
                                size="small"
                                disabled
                                // onClick={() => inviteMember()}
                              >
                                <Icon>person</Icon>
                                &nbsp;{t("member")}
                              </MDButton>
                            </MDBox>
                          )}
                        {usernamesRef.current.includes(previewUserRef.current[0].username) ===
                          true &&
                          memberStatusRef.current === "invited" && (
                            <MDBox
                              py={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <MDButton
                                variant="gradient"
                                color="dark"
                                size="small"
                                onClick={() => cancelMemberInvite()}
                              >
                                <Icon>person_remove</Icon>
                                &nbsp;{t("cancel")}
                              </MDButton>
                            </MDBox>
                          )}
                        {usernamesRef.current.includes(previewUserRef.current[0].username) ===
                          false && (
                          <MDBox py={1} display="flex" alignItems="center" justifyContent="center">
                            <MDButton
                              variant="gradient"
                              color="light"
                              size="small"
                              onClick={() => inviteMember()}
                            >
                              <Icon>person_add</Icon>
                              &nbsp;{t("invite")}
                            </MDButton>
                          </MDBox>
                        )}
                      </Grid>
                    )}
                    {pressSearch === false && previewUserRef.current.length === 0 && (
                      <Grid item>
                        <MDBox py={1} display="flex" alignItems="center" justifyContent="center">
                          <MDTypography variant="button" align="center">
                            {t("the user does not exist or is not open for query")}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    )}
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
          <MDBox
            sx={{
              width: { xs: 400, md: 320, lg: 320 },
              margin: "auto",
            }}
            borderRadius="lg"
            pb={3}
          >
            <Card>
              <MDBox py={2}>
                <MDBox py={1}>
                  <Grid container spacing={1} justifyContent="center">
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      {t("generate link")}
                    </MDTypography>
                  </Grid>
                </MDBox>
                <MDBox py={0} mx={2}>
                  <Grid container spacing={0} direction="column" alignItems="center">
                    <MDInput
                      id="link"
                      value={link}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              variant="gradient"
                              color="secondary"
                              size="small"
                              onClick={copyToClipboard}
                            >
                              <Icon>{copied ? "done" : "copy"}</Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {showQrCode && (
                      <QRCode
                        value={link}
                        logoImage={usergroup.logo}
                        removeQrCodeBehindLogo
                        logoWidth="50"
                        logoPadding="5"
                        size="200"
                      />
                    )}
                    {link !== "" && (
                      <MDBox>
                        <MDBox>
                          <MDTypography variant="button" align="center" color="info">
                            1. {t("copy this link or QR code to the people you want to invite")}
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="button" align="center" color="info">
                            2. {t("time limit is one hour")}
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="button" align="center" color="error">
                            3. {t("do not send the link or QR code to the public or strangers")}
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="button" align="center" color="error">
                            4. {t("people can enter your group directly through the link")}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    )}
                    <MDBox pt={1}>
                      <MDButton variant="gradient" color="secondary" onClick={() => generateLink()}>
                        {t("generate link")}&nbsp;
                        <Icon>link</Icon>
                      </MDButton>
                    </MDBox>
                    <MDBox pt={1}>
                      <MDButton
                        variant="gradient"
                        color="secondary"
                        onClick={() => generateQrCode()}
                      >
                        {t("generate QR code")}&nbsp;
                        <Icon>qr_code_scanner_icon</Icon>
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default MemberInvite;
