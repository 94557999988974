/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
// import ReactDOM from "react-dom";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

import IndexHelmet from "components/ReactHelmet";

// react-oauth/google
import { GoogleOAuthProvider } from "@react-oauth/google";

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <GoogleOAuthProvider clientId={googleClientId}>
      <IndexHelmet />
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>,
    rootElement
  );
} else {
  render(
    <GoogleOAuthProvider clientId={googleClientId}>
      <IndexHelmet />
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>,
    rootElement
  );
}
