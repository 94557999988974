import React, { useEffect } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

function GoogleAds({ dataAdSlot }) {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      // console.log("error", error);
    }
  }, []);

  return (
    <MDBox>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-8688818891192203"
        data-ad-slot={dataAdSlot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </MDBox>
  );
}

// Setting default values for the props of GoogleAds
GoogleAds.defaultProps = {};

// Typechecking props for the GoogleAds
GoogleAds.propTypes = {
  dataAdSlot: PropTypes.string.isRequired,
};

export default GoogleAds;
