import React from "react";
// react-number-format
import { NumericFormat } from "react-number-format";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

const PercentFormat = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  let floatValue = 0;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      isAllowed={(values) => {
        floatValue = values.floatValue;
        return floatValue <= 999 || floatValue === undefined;
      }}
      onChange={() =>
        onChange({
          target: {
            name: props.name,
            value: Math.min(floatValue, 100),
          },
        })
      }
      // onValueChange={(values) => {
      //   console.log(values);
      //   onChange({
      //     target: {
      //       name: props.name,
      //       value: Math.min(values.value, 100),
      //     },
      //   });
      // }}

      suffix="%"
      decimalScale={2}
      allowNegative={false}
      allowLeadingZeros={false}
    />
  );
});

// Setting default values for the props of PercentFormat
PercentFormat.defaultProps = {};

// Typechecking props for the PercentFormat
PercentFormat.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PercentFormat;
