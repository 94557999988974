// React
// import { useEffect } from "react";
// import useState from "react-usestateref";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import Checkbox from "@mui/material/Checkbox";
// customize checkbox
import { BpIcon, BpCheckedIcon } from "components/MUICheckbox/style";
import { useMaterialUIController } from "context";

function UsergroupCheckList({ usergroups, checked, setChecked }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleCheck = (event) => {
    const checkedDict = checked;
    checkedDict[event.target.id] = event.target.checked;
    setChecked(() => ({ ...checkedDict }));
  };

  return (
    <MDBox
      sx={{
        mb: 2,
        display: "flex",
        flexDirection: "column",
        height: { xs: 380, md: 660, lg: 660 },
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      {usergroups.length > 0 &&
        usergroups.map((usergroup) => (
          <MDBox
            display="flex"
            bgColor={darkMode ? "grey-900" : "grey-100"}
            borderRadius="lg"
            p={1}
            mt={1}
            key={usergroup.id}
          >
            <Grid container spacing={1} alignItems="stretch" justifyContent="space-between">
              <Grid item xs={2} md={1.5} lg={1.5} container alignItems="center">
                <MDAvatar alt="Avatar" size="lg" src={usergroup.logo} />
              </Grid>
              <Grid item xs={9} md={9.5} lg={9.5}>
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  maxWidth="100%"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden !important"
                >
                  {usergroup.currency} | {usergroup.name}
                </MDTypography>
              </Grid>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                item
                xs={1}
                md={1}
                lg={1}
              >
                {Object.keys(checked).length > 0 && (
                  <Checkbox
                    id={usergroup.id}
                    size="small"
                    sx={{ m: -1 }}
                    checked={checked[usergroup.id]}
                    onChange={handleCheck}
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                  />
                )}
              </Grid>
            </Grid>
          </MDBox>
        ))}
    </MDBox>
  );
}

// Setting default values for the props of UsergroupCheckList
UsergroupCheckList.defaultProps = {};

// Typechecking props for the UsergroupCheckList
UsergroupCheckList.propTypes = {
  usergroups: PropTypes.instanceOf(Array).isRequired,
  checked: PropTypes.objectOf(Object).isRequired,
  setChecked: PropTypes.func.isRequired,
};

export default UsergroupCheckList;
