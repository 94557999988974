// import React, { memo } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { Handle, Position } from "reactflow";

import MDBox from "components/MDBox";
// import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";

function GroupNode({ data }) {
  return (
    <MDBox>
      <MDBox component="a" display="flex" alignItems="center" justifyContent="center">
        <MDBox
          component="img"
          sx={{
            height: { xs: 100, md: 120, lg: 135 },
            width: { xs: 100, md: 120, lg: 135 },
            objectFit: "cover",
          }}
          borderRadius="lg"
          src={data.avatar}
        />
      </MDBox>
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: { xs: 100, md: 120, lg: 135 },
        }}
      >
        <MDTypography variant="button" fontWeight="medium" align="center">
          {data.name}
        </MDTypography>
      </MDBox>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
    </MDBox>
  );
}

// Setting default values for the props of BalanceFlowChart
GroupNode.defaultProps = {};

// Typechecking props for the BalanceFlowChart
GroupNode.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
};

export default GroupNode;
