// timeZone
import moment from "moment-timezone";
// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useLocation, useNavigate } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
// import IconButton from "@mui/material/IconButton";
// import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import GroupSubNavbar from "examples/Navbars/GroupSubNavbar";
import Footer from "examples/Footer";
// BillForm components
import BillForm from "layouts/usergroups/bills/components/BillForm";
import TransferForm from "layouts/usergroups/bills/components/TransferForm";
// http-api-tool
import axios from "axios";
// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

function BillCreate() {
  const { t } = useTranslation();

  const location = useLocation();
  const usergroupId = location.pathname.split("/")[2];
  const navigate = useNavigate();

  // Loading mode (Waiting for Axios before rendering)
  const [isLoading, setLoading] = useState(true);
  const [usergroup, setUsergroup, usergroupRef] = useState("");
  const [memberships, setMemberships, membershipsRef] = useState([]);
  const [billType, setBillType, billTypeRef] = useState("expense");
  const [boardColor, setBoardColor] = useState("secondary");

  const usergroupBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const membershipListBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/memberships/?ordering=status`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const billCreateBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/bills/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  // Get group and  membership data
  const getBackendData = async () => {
    try {
      const usergroupResponse = await usergroupBackend.get("");
      const usergroupResponseData = await usergroupResponse.data;
      setUsergroup(usergroupResponseData);
      const membershipResponse = await membershipListBackend.get("");
      const membershipResponseData = await membershipResponse.data;
      const membershipList = [];
      let i = 0;
      while (membershipResponseData[i]) {
        if (membershipResponseData[i].user === localStorage.getItem("username")) {
          membershipList.unshift(membershipResponseData[i]);
        } else {
          membershipList.push(membershipResponseData[i]);
        }
        i += 1;
      }
      setMemberships(membershipList);
      setLoading(false);
    } catch (error) {
      navigate("/groups");
    }
  };

  useEffect(() => {
    getBackendData();
  }, []);

  // Blank bill
  const bill = {
    amount: "0.00",
    currency: usergroupRef.current.currency,
    name: "",
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    datetime: new Date().toISOString(),
    category: "",
    remark: "",
    usergroup: usergroupRef.current.id,
    payment_record: [],
    share_record: [],
  };

  // Expense/Income/Transfer Button
  const [, setExpenseButton, expenseButtonRef] = useState("gradient");
  const [, setIncomeButton, incomeButtonRef] = useState("text");
  const [, setTransferButton, transferButtonRef] = useState("text");
  // Expense/Income/Transfer Switch
  const switchToExpense = () => {
    setBillType("expense");
    setExpenseButton("gradient");
    setIncomeButton("text");
    setTransferButton("text");
    setBoardColor("secondary");
  };
  const switchToIncome = () => {
    setBillType("income");
    setExpenseButton("text");
    setIncomeButton("gradient");
    setTransferButton("text");
    setBoardColor("primary");
  };
  const switchToTransfer = () => {
    setBillType("transfer");
    setExpenseButton("text");
    setIncomeButton("text");
    setTransferButton("gradient");
    setBoardColor("info");
  };

  // Alert for Create Bill Detail
  const [successSwitch, setSuccessSwitch] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorSwitch, setErrorSwitch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleClose = () => {
    setSuccessSwitch(false);
    setErrorSwitch(false);
  };

  // Create Bill Detail
  let memberCount = 0;
  if (membershipsRef.current !== undefined) {
    const formalMembers = membershipsRef.current.filter(
      (membership) => membership.status === "member"
    );
    memberCount = formalMembers.length;
  }

  const amountData = () => {
    let amount = 0;
    if (billTypeRef.current === "expense") {
      amount = document.getElementById("amount").value.replace(/,/g, "") * -1;
    } else if (billTypeRef.current === "income" || billTypeRef.current === "transfer") {
      amount = document.getElementById("amount").value.replace(/,/g, "") * 1;
    }
    return amount;
  };

  const foreignAmountData = () => {
    let foreignAmount = 0;
    if (billTypeRef.current === "expense") {
      foreignAmount = document.getElementById("foreignAmount").value.replace(/,/g, "") * -1;
    } else if (billTypeRef.current === "income" || billTypeRef.current === "transfer") {
      foreignAmount = document.getElementById("foreignAmount").value.replace(/,/g, "") * 1;
    }
    return foreignAmount;
  };

  const paymentFormData = (isForeign) => {
    if (billType === "expense" || billType === "income") {
      const paymentList = [];
      let count = 1;
      while (count <= memberCount) {
        const payer = document.getElementById("member".concat(count)).value;
        if (billTypeRef.current === "expense") {
          const amount =
            document.getElementById("payAmount".concat(count)).value.replace(/,/g, "") * -1;
          let foreignAmount = null;
          if (isForeign === true) {
            foreignAmount =
              document.getElementById("payForeignAmount".concat(count)).value.replace(/,/g, "") *
              -1;
          }
          const x = { payer, amount, foreign_amount: foreignAmount };
          paymentList.push(x);
        }
        if (billTypeRef.current === "income") {
          const amount =
            document.getElementById("payAmount".concat(count)).value.replace(/,/g, "") * 1;
          let foreignAmount = null;
          if (isForeign === true) {
            foreignAmount =
              document.getElementById("payForeignAmount".concat(count)).value.replace(/,/g, "") * 1;
          }
          const x = { payer, amount, foreign_amount: foreignAmount };
          paymentList.push(x);
        }
        count += 1;
      }
      return paymentList;
    }

    if (billType === "transfer") {
      const fromUser = document.getElementById("fromUser").innerHTML;
      const toUser = document.getElementById("toUser").innerHTML;
      const amountValue = document.getElementById("amount").value.replace(/,/g, "");
      const x = [
        { payer: fromUser, amount: amountValue * -1 },
        { payer: toUser, amount: amountValue },
      ];
      return x;
    }
    return null;
  };

  const shareFormData = (isForeign) => {
    if (billType === "expense" || billType === "income") {
      const shareList = [];
      let count = 1;
      while (count <= memberCount) {
        const sharer = document.getElementById("member".concat(count)).value;
        if (billTypeRef.current === "expense") {
          const amount =
            document.getElementById("shareAmount".concat(count)).value.replace(/,/g, "") * -1;
          let foreignAmount = null;
          if (isForeign === true) {
            foreignAmount =
              document.getElementById("shareForeignAmount".concat(count)).value.replace(/,/g, "") *
              -1;
          }
          const x = { sharer, amount, foreign_amount: foreignAmount };
          shareList.push(x);
        }
        if (billTypeRef.current === "income") {
          const amount =
            document.getElementById("shareAmount".concat(count)).value.replace(/,/g, "") * 1;
          let foreignAmount = null;
          if (isForeign === true) {
            foreignAmount =
              document.getElementById("shareForeignAmount".concat(count)).value.replace(/,/g, "") *
              1;
          }
          const x = { sharer, amount, foreign_amount: foreignAmount };
          shareList.push(x);
        }
        count += 1;
      }
      return shareList;
    }
    if (billType === "transfer") {
      const fromUser = document.getElementById("fromUser").innerHTML;
      const toUser = document.getElementById("toUser").innerHTML;
      const x = [
        { sharer: fromUser, amount: 0 },
        { sharer: toUser, amount: 0 },
      ];
      return x;
    }
    return null;
  };

  // validForm pass to child:BillForm to check every input is valid or not
  const [validForm, setValidForm] = useState(false);

  const createLocalBill = async () => {
    try {
      await billCreateBackend
        .post("", {
          type: `${billType}`,
          currency: document.getElementById("currency").value,
          amount: amountData(),
          foreign_amount: null,
          tz: document.getElementById("dataTimezone").value,
          datetime: moment
            .tz(
              document.getElementById("dataTime").value,
              document.getElementById("dataTimezone").value
            )
            .format(),
          category: t(document.getElementById("category").innerHTML, { lng: "zhTWReverse" }),
          name: document.getElementById("name").value,
          remark: document.getElementById("remark").value,
          usergroup: usergroupId,
          payment_record: paymentFormData(),
          share_record: shareFormData(),
        })
        .then(() => {
          setSuccessSwitch(true);
          setSuccessMessage(t("record added successfully"));
        })
        .then(() => {
          setTimeout(() => {
            navigate(`/groups/${usergroupId}/bills/`);
          }, 1000);
        });
    } catch (error) {
      // console.log("error", error);
      setErrorSwitch(true);
      setErrorMessage(t("required fields are not filled in or the values ​​are incorrect"));
    }
  };

  const createForeignBill = async () => {
    try {
      await billCreateBackend
        .post("", {
          type: `${billType}`,
          currency: document.getElementById("currency").value,
          amount: amountData(),
          foreign_amount: foreignAmountData(),
          tz: document.getElementById("dataTimezone").value,
          datetime: moment
            .tz(
              document.getElementById("dataTime").value,
              document.getElementById("dataTimezone").value
            )
            .format(),
          category: t(document.getElementById("category").innerHTML, { lng: "zhTWReverse" }),
          name: document.getElementById("name").value,
          remark: document.getElementById("remark").value,
          usergroup: usergroupId,
          payment_record: paymentFormData(true),
          share_record: shareFormData(true),
        })
        .then(() => {
          setSuccessSwitch(true);
          setSuccessMessage(t("record added successfully"));
        })
        .then(() => {
          setTimeout(() => {
            navigate(`/groups/${usergroupId}/bills/`);
          }, 1000);
        });
    } catch (error) {
      // console.log("error");
      setErrorSwitch(true);
      setErrorMessage(t("required fields are not filled in or the values ​​are incorrect"));
    }
  };

  const createBillDetail = () => {
    if (validForm) {
      if (document.getElementById("currency").value === usergroup.currency) {
        createLocalBill();
      } else {
        createForeignBill();
      }
    }
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <GroupSubNavbar />
        ...
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>
          {usergroup.name} | {appName}
        </title>
      </Helmet>
      <GroupSubNavbar />
      <MDBox
        pt={2}
        sx={{
          mx: { xs: 0.5, md: 2, lg: 3 },
        }}
      >
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} md={10} lg={10}>
            <MDBox>
              <Grid container spacing={0} justifyContent="space-between">
                <Grid item xs={4} md={4} lg={4}>
                  <MDButton
                    variant={expenseButtonRef.current}
                    color="secondary"
                    size="medium"
                    fullWidth
                    onClick={() => switchToExpense()}
                  >
                    {t("expense")}
                  </MDButton>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <MDButton
                    variant={incomeButtonRef.current}
                    color="primary"
                    size="medium"
                    fullWidth
                    onClick={() => switchToIncome()}
                  >
                    {t("income")}
                  </MDButton>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <MDButton
                    variant={transferButtonRef.current}
                    color="info"
                    size="medium"
                    fullWidth
                    onClick={() => switchToTransfer()}
                  >
                    {t("transfer")}
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
            {billTypeRef.current === "expense" && (
              <BillForm
                usergroup={usergroup}
                memberships={memberships}
                bill={bill}
                billType={billType}
                setValidForm={setValidForm}
              />
            )}
            {billTypeRef.current === "income" && (
              <BillForm
                usergroup={usergroup}
                memberships={memberships}
                bill={bill}
                billType={billType}
                setValidForm={setValidForm}
              />
            )}
            {billTypeRef.current === "transfer" && (
              <TransferForm
                usergroup={usergroup}
                memberships={memberships}
                bill={bill}
                billType={billType}
                setValidForm={setValidForm}
              />
            )}
            <Snackbar
              open={successSwitch}
              autoHideDuration={10000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                {successMessage}
              </Alert>
            </Snackbar>
            <Snackbar
              open={errorSwitch}
              autoHideDuration={10000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                {errorMessage}
              </Alert>
            </Snackbar>
            <MDBox pt={1} pb={5}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={4} md={3} lg={3}>
                  <MDButton
                    variant="gradient"
                    color={boardColor}
                    onClick={() => createBillDetail()}
                    fullWidth
                  >
                    <Icon>add</Icon>
                    &nbsp;{t("create")}
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BillCreate;
