// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
// import { useParams } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// http-api-tool
import axios from "axios";
// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

function Notification() {
  const { t } = useTranslation();
  const [, setMemberships, membershipsRef] = useState([]);
  const membershipsBackend = axios.create({
    baseURL: `${backendURL}/memberships/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const [, setUsergroups, usergroupsRef] = useState([]);
  const usergroupsForInvitedBackend = axios.create({
    baseURL: `${backendURL}/usergroupsforinvited/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const [loading, setLoading] = useState(true);

  const getBackendData = async () => {
    try {
      const membershipsResponse = await membershipsBackend.get("?status__name=invited");
      const membershipsResponseData = await membershipsResponse.data;
      setMemberships(membershipsResponseData);
      const usergroupsResponse = await usergroupsForInvitedBackend.get("");
      const usergroupsResponseData = await usergroupsResponse.data;
      setUsergroups(usergroupsResponseData);
      setLoading(false);
    } catch (error) {
      // console.log("error", error);
    }
  };

  useEffect(() => {
    getBackendData();
  }, []);

  // set usergroup name by id
  const getUsergroupName = (usergroupId) => {
    const usergroupData = usergroupsRef.current.find((usergroup) => usergroup.id === usergroupId);
    if (usergroupData !== undefined) {
      return usergroupData.name;
    }
    return null;
  };

  // set user name by usergroupId, username
  const getUserNickname = (usergroupId, username) => {
    const usergroupData = usergroupsRef.current.find((usergroup) => usergroup.id === usergroupId);
    const userObj = usergroupData.members.find((member) => member.username === username);
    return userObj.nickname;
  };

  // Agree to Join the UserGroup
  const [invitationSwitch, setInvitationSwitch] = useState(true);
  const joinGroup = async (memberId) => {
    try {
      await membershipsBackend
        .patch(memberId.toString().concat("/"), {
          status: "member",
        })
        .then(() => {
          setInvitationSwitch(false);
        });
    } catch (error) {
      // console.log("error", error);
    }
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>
          {t("notification")} | {appName}
        </title>
      </Helmet>
      <DashboardNavbar />
      <MDBox
        mt={2}
        mb={3}
        sx={{
          minHeight: 640,
          mx: { xs: 0.5, md: 0.5, lg: 0 },
        }}
      >
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card sx={{ minHeight: 600 }}>
              <MDBox p={2}>
                <MDTypography variant="h5" align="center" textTransform="capitalize">
                  {t("notification")}
                  <br />
                  <hr />
                </MDTypography>
              </MDBox>
              {loading === false &&
                membershipsRef.current.length > 0 &&
                membershipsRef.current.map((member) => (
                  <MDBox mb={1} mx={1} key={member.id}>
                    <Collapse in={invitationSwitch}>
                      <Alert
                        severity="info"
                        action={
                          <MDButton color="white" size="small" onClick={() => joinGroup(member.id)}>
                            {t("join")}
                          </MDButton>
                        }
                      >
                        <MDTypography
                          variant="bottun"
                          fontWeight="medium"
                          color="info"
                          textTransform="capitalize"
                        >
                          [{t("invitation")}] &nbsp;
                        </MDTypography>
                        <MDTypography variant="bottun" fontWeight="medium" color="dark">
                          {getUserNickname(member.usergroup, member.inviter)} &nbsp;
                        </MDTypography>
                        <MDTypography variant="bottun">
                          {t("invites you to join")} &nbsp;
                        </MDTypography>
                        <MDTypography variant="bottun" fontWeight="medium" color="dark">
                          {getUsergroupName(member.usergroup)}
                        </MDTypography>
                      </Alert>
                    </Collapse>
                  </MDBox>
                ))}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Notification;
