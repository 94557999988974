import React from "react";
// react-number-format
import { NumericFormat } from "react-number-format";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

const AmountFormat = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      // isNumericString
      thousandSeparator=","
      decimalScale={2}
      allowNegative={false}
      allowLeadingZeros={false}
    />
  );
});

// Setting default values for the props of AmountFormat
AmountFormat.defaultProps = {};

// Typechecking props for the AmountFormat
AmountFormat.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AmountFormat;
