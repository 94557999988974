// timeZone and time
import moment from "moment-timezone";
// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useNavigate, useLocation } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// import IconButton from "@mui/material/IconButton";
// import InputAdornment from "@mui/material/InputAdornment";
// import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// http-api-tool
import axios from "axios";
// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

function MemberApplication() {
  // handle URL
  const location = useLocation();
  const urlParams = {};
  location.search
    .slice(1)
    .split("&")
    .map((param) => {
      const key = param.split("=")[0];
      const value = param.split("=")[1];
      urlParams[key] = value;
      return null;
    });
  const navigate = useNavigate();

  const { t } = useTranslation();

  // timecode validation
  const timeCodeValidation = () => {
    const now = moment().unix();
    const timeCode = urlParams.timecode.slice(3, -3);
    if (now < timeCode && now > timeCode - 3600) {
      return true;
    }
    return false;
  };

  // get usergroup name and logo
  const [usergroup, setUsergroup] = useState("");
  const usergroupForApplicationBackend = axios.create({
    baseURL: `${backendURL}/usergroupsforapplication/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getUsergroupNameAndLogo = async () => {
    try {
      const response = await usergroupForApplicationBackend.get(urlParams.usergroup);
      const responseData = await response.data;
      setUsergroup(responseData);
    } catch (error) {
      // console.log("error");
    }
  };

  useEffect(() => {
    if (Object.keys(urlParams).length === 1) {
      navigate("/groups");
    }
    if (Object.keys(urlParams).length > 1) {
      if (timeCodeValidation() === true) {
        getUsergroupNameAndLogo();
      }
    }
  }, []);

  const membershipsBackend = axios.create({
    baseURL: `${backendURL}/memberships/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const joinGroupDirectly = async () => {
    try {
      await membershipsBackend
        .post("", {
          inviter: urlParams.inviter,
          user: `${localStorage.getItem("username")}`,
          status: "member",
          usergroup: urlParams.usergroup,
          invite_reason: "join via invitation url",
        })
        .then(() => {
          navigate("/groups/".concat(urlParams.usergroup, "/bills"));
        });
    } catch (error) {
      // console.log("error");
    }
  };

  return (
    <DashboardLayout>
      {usergroup !== "" && (
        <Helmet>
          <title>
            {usergroup.name} | {appName}
          </title>
        </Helmet>
      )}
      <DashboardNavbar />
      <MDBox
        py={3}
        sx={{
          width: { xs: 400, md: 320, lg: 320 },
          margin: "auto",
        }}
        borderRadius="lg"
      >
        <Card sx={{ height: 360 }}>
          <MDBox py={2}>
            <MDBox py={1}>
              <Grid container spacing={1} justifyContent="center">
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  {t("group")}
                </MDTypography>
              </Grid>
            </MDBox>
            {Object.keys(usergroup).length !== 0 && (
              <MDBox py={1}>
                <Grid container spacing={0} direction="column" alignItems="center">
                  <MDBox
                    component="img"
                    src={usergroup.logo}
                    sx={{ width: 200, height: 200 }}
                    borderRadius="lg"
                  />
                  <MDBox pt={1}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      {usergroup.name}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={() => joinGroupDirectly()}
                    >
                      <Icon>done_outline</Icon>
                      &nbsp;{t("join group")}
                    </MDButton>
                  </MDBox>
                </Grid>
              </MDBox>
            )}
            {Object.keys(usergroup).length === 0 && (
              <MDBox py={1}>
                <Grid container spacing={0} direction="column" alignItems="center">
                  <MDBox
                    sx={{ height: 200, width: 200 }}
                    borderRadius="lg"
                    bgColor="black"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <MDTypography variant="h2" color="white" textTransform="uppercase">
                      {t("expired")}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={2}>
                    <MDTypography variant="h6" textTransform="capitalize">
                      {t("the invitation link has expired")}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </MDBox>
            )}
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MemberApplication;
