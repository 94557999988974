/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useEffect } from "react";
// import useState from "react-usestateref";

// react-router-dom components
// import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
import googleIcon from "assets/images/google_48.png";
import lineIcon from "assets/images/line_44.png";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// react - google auth2
import { useGoogleLogin } from "@react-oauth/google";

// http-api-tool
import axios from "axios";

// env
const drftoken = process.env.REACT_APP_DRF_TOKEN;
const drfGoogleClientId = process.env.REACT_APP_DRF_GOOGLE_CLIENT_ID;
const drfGoogleClientSecret = process.env.REACT_APP_DRF_GOOGLE_CLIENT_SECRET;
const lineChannelId = process.env.REACT_APP_LINE_CHANNEL_ID;

const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

function Basic() {
  // handle URL
  const location = useLocation();
  const urlParams = {};
  location.search
    .slice(1)
    .split("&")
    .map((param) => {
      const key = param.split("=")[0];
      const value = param.split("=")[1];
      urlParams[key] = value;
      return null;
    });

  const navigate = useNavigate();
  const { t } = useTranslation();

  // Check user is signin or not, if not, redirect to signin page.
  const userinfoBackend = axios.create({
    baseURL: `${backendURL}/userinfo/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const memberApplicationUrl = "/groups/members/application/".concat(
    "?usergroup=",
    urlParams.usergroup,
    "&inviter=",
    urlParams.inviter,
    "&timecode=",
    urlParams.timecode
  );

  const checkUser = async () => {
    try {
      const getUserByToken = await userinfoBackend.post("", {});
      if (getUserByToken !== null) {
        // is sign in
        if (Object.keys(urlParams).length === 1) {
          navigate("/groups");
          navigate(0);
        }
        if (Object.keys(urlParams).length > 1) {
          navigate(memberApplicationUrl);
        }
      }
    } catch (error) {
      // not sign in
      navigate(location);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  // login procedure
  const authBackend = axios.create({
    baseURL: `${backendURL}/auth/convert-token`,
    headers: { Authorization: `JWT ${drftoken}` },
  });

  const googleLogin = useGoogleLogin({
    flow: "implicit",
    onSuccess: async (tokenResponse) => {
      try {
        await authBackend
          .post("", {
            token: tokenResponse.access_token,
            backend: "google-oauth2",
            grant_type: "convert_token",
            client_id: drfGoogleClientId,
            client_secret: drfGoogleClientSecret,
          })
          .then(async (res) => {
            const accessToken = res.data.access_token;
            const refreshToken = res.data.refresh_token;
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);

            const userinfoBackendNewToken = axios.create({
              baseURL: `${backendURL}/userinfo/`,
              headers: { Authorization: `Bearer ${accessToken}` },
            });
            const response = await userinfoBackendNewToken.post("", {});
            const data = await response.data;
            localStorage.setItem("userId", data.id);
            localStorage.setItem("username", data.username);
          })
          .then(() => {
            if (Object.keys(urlParams).length === 1) {
              navigate("/groups");
              navigate(0);
            }
            if (Object.keys(urlParams).length > 1) {
              navigate(memberApplicationUrl);
            }
          });
      } catch (error) {
        // login Failed
      }
    },
    onError: () => "Google Login Failed",
  });

  const lineRedirectUri = frontendURL.concat("/authentication/line/auth-callback");
  const lineState = () => {
    if (Object.keys(urlParams).length === 1) {
      return "login";
    }
    if (Object.keys(urlParams).length > 1) {
      const stateString = "usergroup=".concat(
        urlParams.usergroup,
        "_inviter=",
        urlParams.inviter,
        "_timecode=",
        urlParams.timecode
      );
      return stateString;
    }
    return null;
  };

  const lineLogin = () => {
    let lineLink = "https://access.line.me/oauth2/v2.1/authorize";
    lineLink += "?response_type=code";
    lineLink += "&client_id=".concat(lineChannelId);
    lineLink += "&redirect_uri=".concat(lineRedirectUri);
    lineLink += "&state=".concat(lineState());
    lineLink += "&scope=openid%20profile";
    window.location.href = lineLink;
  };

  return (
    <BasicLayout image={bgImage}>
      <Helmet>
        <title>
          {t("sign in")} | {appName}
        </title>
      </Helmet>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDButton variant="contained" color="dark" onClick={googleLogin} fullWidth>
            <MDTypography variant="h5" color="white" display="flex" textTransform="capitalize">
              <MDAvatar src={googleIcon} sx={{ height: "25px", width: "25px" }} />
              &nbsp;{t("sign in with Google")}
            </MDTypography>
          </MDButton>
        </MDBox>
        <MDBox pb={3} px={3}>
          <MDButton variant="contained" color="success" onClick={lineLogin} fullWidth>
            <MDTypography variant="h5" color="white" display="flex" textTransform="capitalize">
              <MDAvatar src={lineIcon} sx={{ height: "25px", width: "25px" }} />
              &nbsp;{t("sign in with Line")}
            </MDTypography>
          </MDButton>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
