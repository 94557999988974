// React
import { useEffect } from "react";
import useState from "react-usestateref";
// i18n
import { useTranslation } from "react-i18next";
// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// UsergroupListPage components
import UsergroupListPage from "layouts/usergroups/components/UsergroupListPage";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// http-api-tool
import axios from "axios";
// env
const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;

function UsergroupList() {
  const { t } = useTranslation();
  const [usergroupsLoading, setUsergroupsLoading] = useState(true);
  const [usergroups, setUsergroups] = useState([]);
  const [latestNotifications, setLatestNotifications] = useState([]);
  const [countOfUnreads, setCountOfUnreads] = useState([]);

  const usergroupsBackend = axios.create({
    baseURL: `${backendURL}/usergroups/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const notificationsBackend = axios.create({
    baseURL: `${backendURL}/notifications/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getBackendData = async () => {
    try {
      const lateatNotificationResponse = await notificationsBackend.get("get_latest_one");
      const lateatNotificationResponseData = await lateatNotificationResponse.data;
      setLatestNotifications(lateatNotificationResponseData);
      const countOfUnreadResponse = await notificationsBackend.get("count_of_unread");
      const countOfUnreadResponseData = await countOfUnreadResponse.data;
      setCountOfUnreads(countOfUnreadResponseData);
      const usergroupsResponse = await usergroupsBackend.get("");
      const usergroupsResponseData = await usergroupsResponse.data;
      setUsergroups(usergroupsResponseData);
      setUsergroupsLoading(false);
    } catch (error) {
      // console.log("error");
    }
  };

  useEffect(() => {
    getBackendData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {usergroupsLoading === false && usergroups.length === 0 && (
        <MDBox pt={10} pb={10}>
          <Grid container spacing={5} direction="column" alignItems="center">
            <Grid item>
              <MDTypography variant="bottun" textTransform="capitalize">
                {t("let's start with creating a group")}
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox component="a" color="secondary" href={frontendURL.concat("/groups/create")}>
                <Icon fontSize="large">group_add</Icon>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      )}
      {usergroups.length > 0 && (
        <UsergroupListPage
          originUsergroups={usergroups}
          latestNotifications={latestNotifications}
          countOfUnreads={countOfUnreads}
        />
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default UsergroupList;
