// timeZone
import moment from "moment-timezone";
// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useLocation, useNavigate } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
// import IconButton from "@mui/material/IconButton";
// import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
// import Card from "@mui/material/Card";
// import InputAdornment from "@mui/material/InputAdornment";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import GroupSubNavbar from "examples/Navbars/GroupSubNavbar";
import Footer from "examples/Footer";

// BillForm components
import BillForm from "layouts/usergroups/bills/components/BillForm";
import TransferForm from "layouts/usergroups/bills/components/TransferForm";
// http-api-tool
import axios from "axios";
// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

function BillDetail() {
  const { t } = useTranslation();

  const location = useLocation();
  const usergroupId = location.pathname.split("/")[2];
  const navigate = useNavigate();

  // Loading mode (Waiting for Axios before rendering)
  const [isLoading, setLoading] = useState(true);
  const [usergroup, setUsergroup] = useState("");
  const [memberships, setMemberships] = useState([]);
  const [bill, setBill] = useState("");
  const [billType, setBillType, billTypeRef] = useState("");
  const [boardColor, setBoardColor] = useState("");

  const usergroupBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const membershipListBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/memberships/?ordering=status`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const billDetailBackend = axios.create({
    baseURL: `${backendURL}/user${location.pathname.substr(1, 200)}/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  // Get group , memberships, and bill data
  const getBackendData = async () => {
    try {
      const usergroupResponse = await usergroupBackend.get("");
      const usergroupResponseData = await usergroupResponse.data;
      setUsergroup(usergroupResponseData);

      const membershipResponse = await membershipListBackend.get("");
      const membershipResponseData = await membershipResponse.data;
      const membershipList = [];
      let i = 0;
      while (membershipResponseData[i]) {
        if (membershipResponseData[i].user === localStorage.getItem("username")) {
          membershipList.unshift(membershipResponseData[i]);
        } else {
          membershipList.push(membershipResponseData[i]);
        }
        i += 1;
      }
      setMemberships(membershipList);

      const billDetailResponse = await billDetailBackend.get("");
      const billDetailResponseData = await billDetailResponse.data;
      setBill(billDetailResponseData);

      setBillType(billDetailResponseData.type);
      if (billDetailResponseData.type === "expense") {
        setBoardColor("secondary");
      }
      if (billDetailResponseData.type === "income") {
        setBoardColor("primary");
      }
      if (billDetailResponseData.type === "transfer") {
        setBoardColor("info");
      }

      setLoading(false);
    } catch (error) {
      navigate("/groups");
    }
  };

  const getCount = () => {
    if (document.getElementById("count") !== null) {
      return document.getElementById("count").value;
    }
    return 0;
  };

  useEffect(() => {
    getBackendData();
    getCount();
  }, []);

  // Alert for edit & delete bill
  const [successSwitch, setSuccessSwitch] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorSwitch, setErrorSwitch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleClose = () => {
    setSuccessSwitch(false);
    setErrorSwitch(false);
  };

  // Edit Bill Detail
  const amountData = () => {
    let amount = 0;
    if (billTypeRef.current === "expense") {
      amount = document.getElementById("amount").value.replace(/,/g, "") * -1;
    }
    if (billTypeRef.current === "income" || billTypeRef.current === "transfer") {
      amount = document.getElementById("amount").value.replace(/,/g, "") * 1;
    }
    return amount;
  };

  const foreignAmountData = () => {
    let foreignAmount = 0;
    if (billTypeRef.current === "expense") {
      foreignAmount = document.getElementById("foreignAmount").value.replace(/,/g, "") * -1;
    } else if (billTypeRef.current === "income" || billTypeRef.current === "transfer") {
      foreignAmount = document.getElementById("foreignAmount").value.replace(/,/g, "") * 1;
    }
    return foreignAmount;
  };

  const paymentFormData = (isForeign) => {
    if (billType === "expense" || billType === "income") {
      const paymentList = [];
      let count = 1;
      while (count <= getCount()) {
        const payer = document.getElementById("member".concat(count)).value;
        if (billTypeRef.current === "expense") {
          const amount =
            document.getElementById("payAmount".concat(count)).value.replace(/,/g, "") * -1;
          let foreignAmount = null;
          if (isForeign === true) {
            foreignAmount =
              document.getElementById("payForeignAmount".concat(count)).value.replace(/,/g, "") *
              -1;
          }
          const x = { payer, amount, foreign_amount: foreignAmount };
          paymentList.push(x);
        }
        if (billTypeRef.current === "income") {
          const amount =
            document.getElementById("payAmount".concat(count)).value.replace(/,/g, "") * 1;
          let foreignAmount = null;
          if (isForeign === true) {
            foreignAmount =
              document.getElementById("payForeignAmount".concat(count)).value.replace(/,/g, "") * 1;
          }
          const x = { payer, amount, foreign_amount: foreignAmount };
          paymentList.push(x);
        }
        count += 1;
      }
      return paymentList;
    }
    if (billType === "transfer") {
      const fromUser = document.getElementById("fromUser").innerHTML;
      const toUser = document.getElementById("toUser").innerHTML;
      const amountValue = document.getElementById("amount").value.replace(/,/g, "");
      const x = [
        { payer: fromUser, amount: amountValue * -1 },
        { payer: toUser, amount: amountValue },
      ];
      return x;
    }
    return null;
  };

  const shareFormData = (isForeign) => {
    if (billType === "expense" || billType === "income") {
      const shareList = [];
      let count = 1;
      while (count <= getCount()) {
        const sharer = document.getElementById("member".concat(count)).value;
        if (billTypeRef.current === "expense") {
          const amount =
            document.getElementById("shareAmount".concat(count)).value.replace(/,/g, "") * -1;
          let foreignAmount = null;
          if (isForeign === true) {
            foreignAmount =
              document.getElementById("shareForeignAmount".concat(count)).value.replace(/,/g, "") *
              -1;
          }
          const x = { sharer, amount, foreign_amount: foreignAmount };
          shareList.push(x);
        }
        if (billTypeRef.current === "income") {
          const amount =
            document.getElementById("shareAmount".concat(count)).value.replace(/,/g, "") * 1;
          let foreignAmount = null;
          if (isForeign === true) {
            foreignAmount =
              document.getElementById("shareForeignAmount".concat(count)).value.replace(/,/g, "") *
              1;
          }
          const x = { sharer, amount, foreign_amount: foreignAmount };
          shareList.push(x);
        }
        count += 1;
      }
      return shareList;
    }
    if (billType === "transfer") {
      const fromUser = document.getElementById("fromUser").innerHTML;
      const toUser = document.getElementById("toUser").innerHTML;
      const x = [
        { sharer: fromUser, amount: 0 },
        { sharer: toUser, amount: 0 },
      ];
      return x;
    }
    return null;
  };

  // validForm pass to child:BillForm to check every input is valid or not
  const [validForm, setValidForm] = useState(true);

  const editLocalBill = async () => {
    try {
      await billDetailBackend
        .put("", {
          type: `${billType}`,
          currency: document.getElementById("currency").value,
          amount: amountData(),
          foreign_amount: null,
          tz: document.getElementById("dataTimezone").value,
          datetime: moment
            .tz(
              document.getElementById("dataTime").value,
              document.getElementById("dataTimezone").value
            )
            .format(),
          category: t(document.getElementById("category").innerHTML, { lng: "zhTWReverse" }),
          name: document.getElementById("name").value,
          remark: document.getElementById("remark").value,
          usergroup: usergroupId,
          payment_record: paymentFormData(),
          share_record: shareFormData(),
        })
        .then(() => {
          setSuccessSwitch(true);
          setSuccessMessage(t("record edited successfully"));
        })
        .then(() => {
          setTimeout(() => {
            navigate(`/groups/${usergroupId}/bills/`);
          }, 1000);
        });
    } catch (error) {
      // console.log("error", error);
      setErrorSwitch(true);
      setErrorMessage(t("required fields are not filled in or the values ​​are incorrect"));
    }
  };

  const editForeignBill = async () => {
    try {
      await billDetailBackend
        .put("", {
          type: `${billType}`,
          currency: document.getElementById("currency").value,
          amount: amountData(),
          foreign_amount: foreignAmountData(),
          tz: document.getElementById("dataTimezone").value,
          datetime: moment
            .tz(
              document.getElementById("dataTime").value,
              document.getElementById("dataTimezone").value
            )
            .format(),
          category: t(document.getElementById("category").innerHTML, { lng: "zhTWReverse" }),
          name: document.getElementById("name").value,
          remark: document.getElementById("remark").value,
          usergroup: usergroupId,
          payment_record: paymentFormData(true),
          share_record: shareFormData(true),
        })
        .then(() => {
          setSuccessSwitch(true);
          setSuccessMessage(t("record edited successfully"));
        })
        .then(() => {
          setTimeout(() => {
            navigate(`/groups/${usergroupId}/bills/`);
          }, 1000);
        });
    } catch (error) {
      // console.log("error", error);
      setErrorSwitch(true);
      setErrorMessage(t("required fields are not filled in or the values ​​are incorrect"));
    }
  };

  const editBillDetail = () => {
    if (validForm) {
      if (document.getElementById("currency").value === usergroup.currency) {
        editLocalBill();
      } else {
        editForeignBill();
      }
    }
  };

  // Delete Bill Detail
  const deleteBillDetail = async () => {
    try {
      await billDetailBackend
        .delete("")
        .then(() => {
          setSuccessSwitch(true);
          setSuccessMessage("Bill deleted.");
        })
        .then(() => {
          setTimeout(() => {
            navigate(`/groups/${usergroupId}/bills/`);
          }, 1000);
        });
    } catch (error) {
      // console.log("error");
    }
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <GroupSubNavbar />
        ...
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>
          {usergroup.name} | {appName}
        </title>
      </Helmet>
      <GroupSubNavbar />
      <MDBox
        pt={2}
        sx={{
          mx: { xs: 0.5, md: 2, lg: 3 },
        }}
      >
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} md={10} lg={10}>
            <MDBox>
              <Grid container spacing={0} justifyContent="space-between">
                <Grid item xs={4} md={4} lg={4}>
                  {billType === "expense" && (
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      size="medium"
                      fullWidth
                      // onClick={() => deleteBillDetail()}
                    >
                      {t("expense")}
                    </MDButton>
                  )}
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  {billType === "income" && (
                    <MDButton
                      variant="gradient"
                      color="primary"
                      size="medium"
                      fullWidth
                      // onClick={() => deleteBillDetail()}
                    >
                      {t("income")}
                    </MDButton>
                  )}
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  {billType === "transfer" && (
                    <MDButton
                      variant="gradient"
                      color="info"
                      size="medium"
                      fullWidth
                      // onClick={() => deleteBillDetail()}
                    >
                      {t("transfer")}
                    </MDButton>
                  )}
                </Grid>
              </Grid>
            </MDBox>
            {billTypeRef.current === "expense" && (
              <BillForm
                usergroup={usergroup}
                memberships={memberships}
                bill={bill}
                billType={billType}
                setValidForm={setValidForm}
              />
            )}
            {billTypeRef.current === "income" && (
              <BillForm
                usergroup={usergroup}
                memberships={memberships}
                bill={bill}
                billType={billType}
                setValidForm={setValidForm}
              />
            )}
            {billTypeRef.current === "transfer" && (
              <TransferForm
                usergroup={usergroup}
                memberships={memberships}
                bill={bill}
                billType={billType}
                setValidForm={setValidForm}
              />
            )}
            <MDBox pt={1} pb={5}>
              <Snackbar
                open={successSwitch}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                  {successMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                open={errorSwitch}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                  {errorMessage}
                </Alert>
              </Snackbar>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={4.5} md={4.5} lg={4.5} />
                <Grid item xs={3} md={3} lg={3}>
                  <MDButton
                    variant="gradient"
                    color={boardColor}
                    size="small"
                    onClick={() => editBillDetail()}
                    fullWidth
                  >
                    <Icon>edit</Icon>
                    &nbsp;{t("edit")}
                  </MDButton>
                </Grid>
                <Grid
                  item
                  xs={4.5}
                  md={4.5}
                  lg={4.5}
                  container
                  spacing={0}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <MDButton
                    variant="text"
                    color="secondary"
                    size="medium"
                    onClick={() => deleteBillDetail()}
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BillDetail;
