// timeZone
import moment from "moment-timezone";
// React
import { useEffect } from "react";
import useState from "react-usestateref";
// Routes
import { useLocation, useNavigate } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// @mui material components
import Grid from "@mui/material/Grid";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import GroupNavbar from "examples/Navbars/GroupNavbar";
import Footer from "examples/Footer";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";

// http-api-tool
import axios from "axios";

// customize ToggleButton
const ToggleButton = styled(MuiToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "black",
    backgroundColor: "white",
  },
});
const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

// env
// const frontendURL = process.env.REACT_APP_FRONTENDURL;
const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

// timeZone of current location
const cLTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

function Statistics() {
  const location = useLocation();
  const usergroupId = location.pathname.split("/")[2];
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [groupMonthlyExpenses, setGroupMonthlyExpenses, groupMonthlyExpensesRef] = useState([]);
  const [groupMonthlyIncome, setGroupMonthlyIncome, groupMonthlyIncomeRef] = useState([]);
  const [years, setYears] = useState([]);
  const billsBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/bills`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });

  const getGroupMonthlyStatistics = async (type, offset) => {
    try {
      const response = await billsBackend.get(
        "/usergroup_monthly_statistics/".concat(type, "/", offset)
      );
      const responseData = await response.data;

      const yearList = [];
      responseData.map((item) => {
        if (yearList.some((year) => year === item.year) === false) {
          yearList.push(item.year);
        }
        return null;
      });
      setYears(yearList);

      if (type === "expense") {
        setGroupMonthlyExpenses(responseData);
      } else if (type === "income") {
        setGroupMonthlyIncome(responseData);
      }
    } catch (error) {
      // console.log("error", error);
    }
  };

  const [usergroup, setUsergroup] = useState("");
  const usergroupBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const getUsergroupData = async () => {
    try {
      const usergroupResponse = await usergroupBackend.get("");
      const usergroupResponseData = await usergroupResponse.data;
      setUsergroup(usergroupResponseData);
    } catch (error) {
      navigate("/groups");
    }
  };

  const offset = moment().tz(cLTZ).format("Z");
  const yearNow = moment().tz(cLTZ).format("YYYY");
  const monthNow = moment().tz(cLTZ).format("MM");
  const monthList = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

  useEffect(() => {
    getGroupMonthlyStatistics("expense", offset);
    getGroupMonthlyStatistics("income", offset);
    getUsergroupData();
  }, []);

  // Group Monthly Expenses Doughnut Chart (GE = Group Expenses)
  const [buttonFocusGEMonth, setButtonFocusGEMonth] = useState(monthNow);
  const handleToggleButtonChangeGEMonth = (event, newFocus) => {
    setButtonFocusGEMonth(newFocus);
  };
  const [buttonFocusGEYear, setButtonFocusGEYear] = useState(yearNow);
  const handleToggleButtonChangeGEYear = (event, newFocus) => {
    setButtonFocusGEYear(newFocus);
  };

  const [groupMonthlyExpensesCategory, setGroupMonthlyExpensesCategory] = useState([]);
  const [groupMonthlyExpensesAmount, setGroupMonthlyExpensesAmount] = useState([]);
  const handleGroupMonthlyExpenses = (year, month) => {
    const categoryList = [];
    const amountList = [];
    groupMonthlyExpensesRef.current.map((expenseItem) => {
      if (expenseItem.year === year) {
        if (expenseItem.month === month) {
          categoryList.push(t(expenseItem.category));
          amountList.push(Math.abs(parseFloat(expenseItem.amount)));
        }
      }
      return null;
    });
    setGroupMonthlyExpensesCategory(categoryList);
    setGroupMonthlyExpensesAmount(amountList);
  };

  useEffect(() => {
    handleGroupMonthlyExpenses(yearNow, monthNow);
  }, [groupMonthlyExpenses]);

  // Group Monthly Income Doughnut Chart (GI = Group Income)
  const [buttonFocusGIMonth, setButtonFocusGIMonth] = useState(monthNow);
  const handleToggleButtonChangeGIMonth = (event, newFocus) => {
    setButtonFocusGIMonth(newFocus);
  };
  const [buttonFocusGIYear, setButtonFocusGIYear] = useState(yearNow);
  const handleToggleButtonChangeGIYear = (event, newFocus) => {
    setButtonFocusGIYear(newFocus);
  };

  const [groupMonthlyIncomeCategory, setGroupMonthlyIncomeCategory] = useState([]);
  const [groupMonthlyIncomeAmount, setGroupMonthlyIncomeAmount] = useState([]);
  const handleGroupMonthlyIncome = (year, month) => {
    const categoryList = [];
    const amountList = [];
    groupMonthlyIncomeRef.current.map((incomeItem) => {
      if (incomeItem.year === year) {
        if (incomeItem.month === month) {
          categoryList.push(t(incomeItem.category));
          amountList.push(Math.abs(parseFloat(incomeItem.amount)));
        }
      }
      return null;
    });
    setGroupMonthlyIncomeCategory(categoryList);
    setGroupMonthlyIncomeAmount(amountList);
  };

  useEffect(() => {
    handleGroupMonthlyIncome(yearNow, monthNow);
  }, [groupMonthlyIncome]);

  return (
    <DashboardLayout>
      {usergroup !== "" && (
        <Helmet>
          <title>
            {usergroup.name} | {appName}
          </title>
        </Helmet>
      )}
      <GroupNavbar />
      <MDBox
        mt={2}
        mb={3}
        sx={{
          minHeight: 640,
          mx: { xs: 0.5, md: 0.5, lg: 0 },
        }}
      >
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6} lg={6}>
            <MDBox
              display="flex"
              justifyContent="center"
              // bgColor={darkMode ? "grey-900" : "grey-100"}
              borderRadius="lg"
            >
              <ToggleButtonGroup
                color="dark"
                value={buttonFocusGEYear}
                exclusive
                onChange={handleToggleButtonChangeGEYear}
                aria-label="Platform"
                fullWidth
                size="small"
              >
                {years.map((year) => (
                  <ToggleButton
                    key={year}
                    value={year}
                    onClick={() => handleGroupMonthlyExpenses(year, buttonFocusGEMonth)}
                  >
                    {year}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </MDBox>
            <MDBox>
              <DefaultDoughnutChart
                icon={{ color: "secondary", component: "group" }}
                title={t("Group Expenses")}
                description={t("Monthly Statistical Results").concat(" | ", usergroup.currency)}
                chart={{
                  labels: groupMonthlyExpensesCategory,
                  datasets: {
                    label: "groupMonthlyExpenses".concat(buttonFocusGEYear, buttonFocusGEMonth),
                    backgroundColors: [
                      "info",
                      "warning",
                      "success",
                      "error",
                      "secondary",
                      "warning",
                      "success",
                      "error",
                      "secondary",
                      "warning",
                      "success",
                      "error",
                      "secondary",
                      "warning",
                      "success",
                      "error",
                      "secondary",
                      "warning",
                      "success",
                      "error",
                      "secondary",
                    ],
                    data: groupMonthlyExpensesAmount,
                  },
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              // bgColor={darkMode ? "grey-900" : "grey-100"}
              borderRadius="lg"
            >
              <ToggleButtonGroup
                color="dark"
                value={buttonFocusGEMonth}
                exclusive
                onChange={handleToggleButtonChangeGEMonth}
                aria-label="Platform"
                fullWidth
                size="small"
              >
                {monthList.map((month) => (
                  <ToggleButton
                    key={month}
                    value={month}
                    onClick={() => handleGroupMonthlyExpenses(buttonFocusGEYear, month)}
                  >
                    {parseInt(month, 10)}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox
              display="flex"
              justifyContent="center"
              // bgColor={darkMode ? "grey-900" : "grey-100"}
              borderRadius="lg"
            >
              <ToggleButtonGroup
                color="dark"
                value={buttonFocusGIYear}
                exclusive
                onChange={handleToggleButtonChangeGIYear}
                aria-label="Platform"
                fullWidth
                size="small"
              >
                {years.map((year) => (
                  <ToggleButton
                    key={year}
                    value={year}
                    onClick={() => handleGroupMonthlyIncome(year, buttonFocusGIMonth)}
                  >
                    {year}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </MDBox>
            <MDBox>
              <DefaultDoughnutChart
                icon={{ color: "primary", component: "group" }}
                title={t("Group Income")}
                description={t("Monthly Statistical Results").concat(" | ", usergroup.currency)}
                chart={{
                  labels: groupMonthlyIncomeCategory,
                  datasets: {
                    label: "groupMonthlyIncome".concat(buttonFocusGIYear, buttonFocusGIMonth),
                    backgroundColors: [
                      "primary",
                      "success",
                      "error",
                      "info",
                      "secondary",
                      "success",
                      "error",
                      "info",
                      "secondary",
                      "success",
                      "error",
                      "info",
                      "secondary",
                      "success",
                      "error",
                      "info",
                      "secondary",
                      "success",
                      "error",
                      "info",
                      "secondary",
                    ],
                    data: groupMonthlyIncomeAmount,
                  },
                }}
              />
            </MDBox>
            <MDBox display="flex" justifyContent="center" borderRadius="lg">
              <ToggleButtonGroup
                color="dark"
                value={buttonFocusGIMonth}
                exclusive
                onChange={handleToggleButtonChangeGIMonth}
                aria-label="Platform"
                fullWidth
                size="small"
              >
                {monthList.map((month) => (
                  <ToggleButton
                    key={month}
                    value={month}
                    onClick={() => handleGroupMonthlyIncome(buttonFocusGIYear, month)}
                  >
                    {parseInt(month, 10)}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Statistics;
