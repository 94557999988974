// import React, { memo } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { Handle, Position } from "reactflow";

import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";

function UserNode({ data }) {
  return (
    <MDBox>
      <MDBox component="a" display="flex" alignItems="center" justifyContent="center">
        <MDAvatar
          size="lg"
          bgColor="light"
          shadow="lg"
          alt="Avatar"
          variant="circular"
          src={data.avatar}
        />
      </MDBox>
      <MDBox display="flex" alignItems="center" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium" align="center">
          {data.name}
        </MDTypography>
      </MDBox>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
    </MDBox>
  );
}

// Setting default values for the props of BalanceFlowChart
UserNode.defaultProps = {};

// Typechecking props for the BalanceFlowChart
UserNode.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
};

export default UserNode;
