// timeZone
import moment from "moment-timezone";
import "moment/locale/zh-tw";
// React
import { useEffect, useCallback, useMemo } from "react";
import useState from "react-usestateref";
// route
import { useLocation } from "react-router-dom";
// i18n
import i18n from "i18n";
import { useTranslation } from "react-i18next";
// Helmet
import { Helmet } from "react-helmet";
// MUI
import { useMaterialUIController } from "context";
import Grid from "@mui/material/Grid";
// import CardMedia from "@mui/material/CardMedia";
// import Card from "@mui/material/Card";
import ReactPlayer from "react-player";
import Icon from "@mui/material/Icon";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";

// MD
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// examples
import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "examples/Footer";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";

// reactflow
import ReactFlow, {
  addEdge,
  MarkerType,
  Background,
  useNodesState,
  useEdgesState,
} from "reactflow";
import UserNode from "components/ReactFlow/UserNode";
import GroupNode from "components/ReactFlow/GroupNode";

// BillListUnit components
import Bill from "layouts/usergroups/bills/components/BillListUnit";
// LogListUnit components
import LogListUnit from "layouts/usergroups/logs/components/LogListUnit";

// Images
import brandWhite from "assets/images/favicon-128x128.ico";

import aiMichael from "assets/images/about/ai-michael.jpg";
import aiSunny from "assets/images/about/ai-sunny.jpg";
import aiJudy from "assets/images/about/ai-judy.jpg";
import muiWillian from "assets/images/about/mui-willian.jpg";
import muiEmma from "assets/images/about/mui-emma.jpg";
import muiTom from "assets/images/about/mui-tom.jpg";

import christmasVideo from "assets/images/about/allbill-christmas-ad.mp4";

// import hongkongView from "assets/images/about/hongkong-view.jpg";
import finlandView from "assets/images/about/finland-view.jpg";
import happy1stBirthday from "assets/images/about/happy-1st-birthday.jpg";
import simpleOffice from "assets/images/about/simple-office.jpg";
import collegeRoom from "assets/images/about/college-room.jpg";
import makeCoffee from "assets/images/about/make-coffee.jpg";

import worldmapAsia from "assets/images/about/worldmap-asia-dark.jpg";
import worldmapAmerica from "assets/images/about/worldmap-america-dark.jpg";
import sloganBackground from "assets/images/about/sloganBackground.jpg";
// import sloganBackground3 from "assets/images/about/sloganBackground3.jpg";
// import sloganBackground4 from "assets/images/about/sloganBackground4.jpg";

// customize ToggleButton
const ToggleButton = styled(MuiToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "black",
    backgroundColor: "white",
  },
});
const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

// remove Reactflow logo
const proOptions = { hideAttribution: true };
// env
const frontendURL = process.env.REACT_APP_FRONTENDURL;
// const backendURL = process.env.REACT_APP_BACKENDURL;
const appName = process.env.REACT_APP_NAME;

function About() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const location = useLocation();

  const { t } = useTranslation();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const nodeTypes = useMemo(
    () => ({
      userNode: UserNode,
      groupNode: GroupNode,
    }),
    []
  );

  const nodesFor2Member = [
    {
      id: "1",
      position: { x: 20, y: 0 },
      type: "userNode",
      data: { name: t("Michael"), avatar: `${aiMichael}` },
    },
    {
      id: "2",
      position: { x: 440, y: 0 },
      type: "userNode",
      data: { name: t("Sunny"), avatar: `${aiSunny}` },
    },
    {
      id: "group",
      position: { x: 200, y: 0 },
      type: "groupNode",
      data: { name: t("Finland Trip"), avatar: `${finlandView}` },
    },
  ];
  const edgesFor2Member = [
    {
      id: "1-group",
      source: "1",
      target: "group",
      label: "2,000",
      labelStyle: { fontSize: 16, fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#000000",
      },
      style: {
        strokeWidth: 2,
        stroke: "#000000",
      },
    },
    {
      id: "group-2",
      source: "group",
      target: "2",
      label: "2,000",
      labelStyle: { fontSize: 16, fill: "red", fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#FF0072",
      },
      style: {
        strokeWidth: 2,
        stroke: "#FF0072",
      },
    },
  ];

  const nodesFor3Member = [
    {
      id: "1",
      position: { x: 20, y: 0 },
      type: "userNode",
      data: { name: t("Michael"), avatar: `${aiMichael}` },
    },
    {
      id: "2",
      position: { x: 440, y: 0 },
      type: "userNode",
      data: { name: t("Sunny"), avatar: `${aiSunny}` },
    },
    {
      id: "3",
      position: { x: 440, y: 100 },
      type: "userNode",
      data: { name: t("Judy"), avatar: `${aiJudy}` },
    },
    {
      id: "group",
      position: { x: 200, y: 0 },
      type: "groupNode",
      data: { name: t("Alice 1st Birthday"), avatar: `${happy1stBirthday}` },
    },
  ];
  const edgesFor3Member = [
    {
      id: "1-group",
      source: "1",
      target: "group",
      label: "3,000",
      labelStyle: { fontSize: 16, fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#000000",
      },
      style: {
        strokeWidth: 2,
        stroke: "#000000",
      },
    },
    {
      id: "group-2",
      source: "group",
      target: "2",
      label: "2,000",
      labelStyle: { fontSize: 16, fill: "red", fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#FF0072",
      },
      style: {
        strokeWidth: 2,
        stroke: "#FF0072",
      },
    },
    {
      id: "group-3",
      source: "group",
      target: "3",
      label: "1,000",
      labelStyle: { fontSize: 16, fill: "red", fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#FF0072",
      },
      style: {
        strokeWidth: 2,
        stroke: "#FF0072",
      },
    },
  ];

  const nodesFor4Member = [
    {
      id: "1",
      position: { x: 20, y: 0 },
      type: "userNode",
      data: { name: t("Willian"), avatar: `${muiWillian}` },
    },
    {
      id: "2",
      position: { x: 440, y: 0 },
      type: "userNode",
      data: { name: t("Sunny"), avatar: `${aiSunny}` },
    },
    {
      id: "3",
      position: { x: 440, y: 100 },
      type: "userNode",
      data: { name: t("Michael"), avatar: `${aiMichael}` },
    },
    {
      id: "4",
      position: { x: 20, y: 100 },
      type: "userNode",
      data: { name: t("Judy"), avatar: `${aiJudy}` },
    },
    {
      id: "group",
      position: { x: 200, y: 0 },
      type: "groupNode",
      data: { name: t("LA Office"), avatar: `${simpleOffice}` },
    },
  ];
  const edgesFor4Member = [
    {
      id: "1-group",
      source: "1",
      target: "group",
      label: "3,000",
      labelStyle: { fontSize: 16, fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#000000",
      },
      style: {
        strokeWidth: 2,
        stroke: "#000000",
      },
    },
    {
      id: "group-2",
      source: "group",
      target: "2",
      label: "2,000",
      labelStyle: { fontSize: 16, fill: "red", fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#FF0072",
      },
      style: {
        strokeWidth: 2,
        stroke: "#FF0072",
      },
    },
    {
      id: "group-3",
      source: "group",
      target: "3",
      label: "5,000",
      labelStyle: { fontSize: 16, fill: "red", fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#FF0072",
      },
      style: {
        strokeWidth: 2,
        stroke: "#FF0072",
      },
    },
    {
      id: "4-group",
      source: "4",
      target: "group",
      label: "4,000",
      labelStyle: { fontSize: 16, fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#000000",
      },
      style: {
        strokeWidth: 2,
        stroke: "#000000",
      },
    },
  ];

  const nodesFor5Member = [
    {
      id: "1",
      position: { x: 20, y: 0 },
      type: "userNode",
      data: { name: t("Emma"), avatar: `${muiEmma}` },
    },
    {
      id: "2",
      position: { x: 440, y: 0 },
      type: "userNode",
      data: { name: t("Willian"), avatar: `${muiWillian}` },
    },
    {
      id: "3",
      position: { x: 440, y: 100 },
      type: "userNode",
      data: { name: t("Judy"), avatar: `${aiJudy}` },
    },
    {
      id: "4",
      position: { x: 20, y: 100 },
      type: "userNode",
      data: { name: t("Sunny"), avatar: `${aiSunny}` },
    },
    {
      id: "5",
      position: { x: 20, y: 200 },
      type: "userNode",
      data: { name: t("Michael"), avatar: `${aiMichael}` },
    },
    {
      id: "group",
      position: { x: 200, y: 0 },
      type: "groupNode",
      data: { name: t("College Roommate"), avatar: `${collegeRoom}` },
    },
  ];
  const edgesFor5Member = [
    {
      id: "1-group",
      source: "1",
      target: "group",
      label: "3,000",
      labelStyle: { fontSize: 16, fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#000000",
      },
      style: {
        strokeWidth: 2,
        stroke: "#000000",
      },
    },
    {
      id: "group-2",
      source: "group",
      target: "2",
      label: "2,000",
      labelStyle: { fontSize: 16, fill: "red", fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#FF0072",
      },
      style: {
        strokeWidth: 2,
        stroke: "#FF0072",
      },
    },
    {
      id: "group-3",
      source: "group",
      target: "3",
      label: "6,000",
      labelStyle: { fontSize: 16, fill: "red", fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#FF0072",
      },
      style: {
        strokeWidth: 2,
        stroke: "#FF0072",
      },
    },
    {
      id: "4-group",
      source: "4",
      target: "group",
      label: "4,000",
      labelStyle: { fontSize: 16, fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#000000",
      },
      style: {
        strokeWidth: 2,
        stroke: "#000000",
      },
    },
    {
      id: "5-group",
      source: "5",
      target: "group",
      label: "1,000",
      labelStyle: { fontSize: 16, fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#000000",
      },
      style: {
        strokeWidth: 2,
        stroke: "#000000",
      },
    },
  ];

  const nodesFor6Member = [
    {
      id: "1",
      position: { x: 20, y: 0 },
      type: "userNode",
      data: { name: t("Judy"), avatar: `${aiJudy}` },
    },
    {
      id: "2",
      position: { x: 440, y: 0 },
      type: "userNode",
      data: { name: t("Willian"), avatar: `${muiWillian}` },
    },
    {
      id: "3",
      position: { x: 440, y: 100 },
      type: "userNode",
      data: { name: t("Emma"), avatar: `${muiEmma}` },
    },
    {
      id: "4",
      position: { x: 20, y: 100 },
      type: "userNode",
      data: { name: t("Sunny"), avatar: `${aiSunny}` },
    },
    {
      id: "5",
      position: { x: 20, y: 200 },
      type: "userNode",
      data: { name: t("Michael"), avatar: `${aiMichael}` },
    },
    {
      id: "6",
      position: { x: 440, y: 200 },
      type: "userNode",
      data: { name: t("Tom"), avatar: `${muiTom}` },
    },
    {
      id: "group",
      position: { x: 200, y: 0 },
      type: "groupNode",
      data: { name: t("Coffee Club"), avatar: `${makeCoffee}` },
    },
  ];
  const edgesFor6Member = [
    {
      id: "1-group",
      source: "1",
      target: "group",
      label: "3,500",
      labelStyle: { fontSize: 16, fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#000000",
      },
      style: {
        strokeWidth: 2,
        stroke: "#000000",
      },
    },
    {
      id: "group-2",
      source: "group",
      target: "2",
      label: "1,000",
      labelStyle: { fontSize: 16, fill: "red", fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#FF0072",
      },
      style: {
        strokeWidth: 2,
        stroke: "#FF0072",
      },
    },
    {
      id: "group-3",
      source: "group",
      target: "3",
      label: "1,500",
      labelStyle: { fontSize: 16, fill: "red", fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#FF0072",
      },
      style: {
        strokeWidth: 2,
        stroke: "#FF0072",
      },
    },
    {
      id: "4-group",
      source: "4",
      target: "group",
      label: "5,400",
      labelStyle: { fontSize: 16, fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#000000",
      },
      style: {
        strokeWidth: 2,
        stroke: "#000000",
      },
    },
    {
      id: "5-group",
      source: "5",
      target: "group",
      label: "1,600",
      labelStyle: { fontSize: 16, fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#000000",
      },
      style: {
        strokeWidth: 2,
        stroke: "#000000",
      },
    },
    {
      id: "group-6",
      source: "group",
      target: "6",
      label: "8,000",
      labelStyle: { fontSize: 16, fill: "red", fontWeight: 1000 },
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#FF0072",
      },
      style: {
        strokeWidth: 2,
        stroke: "#FF0072",
      },
    },
  ];

  const billListExample = [
    {
      id: 1,
      type: "expense",
      name: t("McDonald's for Breakfast"),
      remark: t("Two combo no.11: chicken nuggets, Big Mac, and iced latte"),
      category: "food",
      currency: "TWD",
      amount: "-396",
      myPaymentAmount: "-396",
      myShareAmount: "-198",
      href: "#",
    },
    {
      id: 2,
      type: "expense",
      name: t("Japanese food for lunch"),
      remark: t("Pork chop rice and seafood ramen"),
      category: "food",
      currency: "TWD",
      amount: "-590",
      myPaymentAmount: "-590",
      myShareAmount: "-290",
      href: "#",
    },
    {
      id: 3,
      type: "expense",
      name: t("Starbucks"),
      remark: t("Matcha Latte and cold brew"),
      category: "drink",
      currency: "TWD",
      amount: "-290",
      myPaymentAmount: "0",
      myShareAmount: "-130",
      href: "#",
    },
    {
      id: 4,
      type: "expense",
      name: t("Hotpot for dinner"),
      remark: t("Kimchi hotpot and milk curry hotpot"),
      category: "food",
      currency: "TWD",
      amount: "-750",
      myPaymentAmount: "0",
      myShareAmount: "-350",
      href: "#",
    },
  ];

  const notificationListExample = [
    {
      id: 1,
      level: "success",
      datetime_created: "2023-10-26T10:10:50.710324+08:00",
      actor: {
        nickname: t("Michael"),
      },
      verb: "created the group",
      description: t("HongKong Trip"),
      actionobject: null,
    },
    {
      id: 2,
      level: "success",
      datetime_created: "2023-10-26T10:30:50.710324+08:00",
      actor: {
        nickname: t("Sunny"),
      },
      verb: "welcome a new member",
      description: t("Judy"),
      actionobject: null,
    },
    {
      id: 3,
      level: "success",
      datetime_created: "2023-10-26T12:30:50.710324+08:00",
      actor: {
        nickname: t("Michael"),
      },
      verb: "added a new record",
      description: t("Japanese food for lunch"),
      actionobject: null,
    },
    {
      id: 4,
      level: "info",
      datetime_created: "2023-10-26T12:35:50.710324+08:00",
      actor: {
        nickname: t("Michael"),
      },
      verb: "edited a record",
      description: t("Japanese food for lunch"),
      actionobject: null,
    },
    {
      id: 5,
      level: "success",
      datetime_created: "2023-10-26T15:30:50.710324+08:00",
      actor: {
        nickname: t("Judy"),
      },
      verb: "added a new record",
      description: t("Starbucks"),
      actionobject: null,
    },
    {
      id: 6,
      level: "success",
      datetime_created: "2023-10-26T19:30:50.710324+08:00",
      actor: {
        nickname: t("Judy"),
      },
      verb: "added a new record",
      description: t("Steak for dinner"),
      actionobject: null,
    },
    {
      id: 7,
      level: "warning",
      datetime_created: "2023-10-26T19:30:50.710324+08:00",
      actor: {
        nickname: t("Judy"),
      },
      verb: "deleted a record",
      description: t("Steak for dinner"),
      actionobject: null,
    },
  ];

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const [buttonFocus, setButtonFocus] = useState("2");
  const handleToggleButtonChange = (event, newFocus) => {
    setButtonFocus(newFocus);
  };

  const handle2MemberButton = () => {
    setNodes(nodesFor2Member);
    setEdges(edgesFor2Member);
  };
  const handle3MemberButton = () => {
    setNodes(nodesFor3Member);
    setEdges(edgesFor3Member);
  };
  const handle4MemberButton = () => {
    setNodes(nodesFor4Member);
    setEdges(edgesFor4Member);
  };
  const handle5MemberButton = () => {
    setNodes(nodesFor5Member);
    setEdges(edgesFor5Member);
  };
  const handle6MemberButton = () => {
    setNodes(nodesFor6Member);
    setEdges(edgesFor6Member);
  };

  const changeLanguageToEN = () => {
    i18n.changeLanguage("en");
    moment.locale("en");
  };

  const changeLanguageToZHTW = () => {
    i18n.changeLanguage("zhTW");
    moment.locale("zh-tw");
  };

  const urlLang = location.pathname.split("/")[1];
  const setLangByUrl = () => {
    if (urlLang === "en") {
      changeLanguageToEN();
    } else if (urlLang === "tw") {
      changeLanguageToZHTW();
    }
  };

  useEffect(() => {
    setLangByUrl();

    setNodes(nodesFor2Member);
    setEdges(edgesFor2Member);

    // Remove the resizeObserver error
    const errorHandler = (e: any) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  const [showReactPlayerControlBar, setShowReactPlayerControlBar] = useState(false);
  const changeReactPlayerControlBarStatus = () => {
    setShowReactPlayerControlBar(true);
  };

  return (
    <PageLayout>
      <Helmet>
        <title>
          {t("About Us")} | {appName}
        </title>
      </Helmet>
      <MDBox pt={1} px={1}>
        <MDBox px={0}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={4} lg={4} />
            <Grid item xs={4} md={4} lg={4}>
              <MDBox display="flex" justifyContent="center">
                <MDBox component="a" href="#">
                  <MDBox
                    component="img"
                    src={brandWhite}
                    sx={{
                      height: 20,
                      width: 20,
                    }}
                  />
                  <MDTypography
                    variant="H6"
                    sx={{ color: "#0DABF3" }}
                    fontWeight="medium"
                    maxWidth="100%"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden !important"
                  >
                    &nbsp;AllBill
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDBox
                  component="a"
                  href={frontendURL.concat("/authentication/sign-in")}
                  display="flex"
                  alignItems="center"
                  sx={{ bgcolor: "#9CCDD4", height: 22 }}
                  borderRadius="lg"
                  px={1}
                >
                  <MDTypography
                    variant="button"
                    color="white"
                    fontWeight="medium"
                    maxWidth="100%"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden !important"
                  >
                    {t("sign in")}&nbsp;
                  </MDTypography>
                  <Icon fontSize="small" sx={{ color: "#FFFFFF" }}>
                    login
                  </Icon>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            sx={{
              height: "auto",
              width: "100%",
            }}
            borderRadius="lg"
            onClick={changeReactPlayerControlBarStatus}
          >
            <ReactPlayer
              // Disable download button
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload nofullscreen noremoteplayback",
                    disablepictureinpicture: "true",
                  },
                },
              }}
              // Disable right click
              onContextMenu={(e) => e.preventDefault()}
              // styled
              style={{ borderRadius: "0.5em", overflow: "hidden" }}
              // props
              url={christmasVideo}
              controls={showReactPlayerControlBar}
              pip
              playing
              playsinline
              playIcon
              muted
              height="100%"
              width="100%"
            />
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="center"
          // bgColor={darkMode ? "grey-900" : "grey-100"}
          borderRadius="lg"
        >
          <ToggleButtonGroup
            color="dark"
            value={buttonFocus}
            exclusive
            onChange={handleToggleButtonChange}
            aria-label="Platform"
            fullWidth
            size="small"
          >
            <ToggleButton value="2" onClick={handle2MemberButton}>
              2{t(" members")}
            </ToggleButton>
            <ToggleButton value="3" onClick={handle3MemberButton}>
              3{t(" members")}
            </ToggleButton>
            <ToggleButton value="4" onClick={handle4MemberButton}>
              4{t(" members")}
            </ToggleButton>
            <ToggleButton value="5" onClick={handle5MemberButton}>
              5{t(" members")}
            </ToggleButton>
            <ToggleButton value="6" onClick={handle6MemberButton}>
              6{t(" members")}
            </ToggleButton>
          </ToggleButtonGroup>
        </MDBox>
        <MDBox
          id="reactflow"
          sx={{
            height: { xs: 220, md: 300, lg: 400 },
            width: "100%",
          }}
          borderRadius="lg"
          shadow="lg"
          bgColor={darkMode ? "grey-900" : "white"}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            proOptions={proOptions}
            fitView
          >
            <Background />
          </ReactFlow>
        </MDBox>
        <MDBox pt={1}>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} md={12}>
              <MDBox bgColor={darkMode ? "grey-900" : "grey-200"} borderRadius="lg">
                <MDBox display="flex" justifyContent="center">
                  <MDTypography
                    variant="h4"
                    color="dark"
                    fontWeight="medium"
                    maxWidth="100%"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden !important"
                    textTransform="capitalize"
                  >
                    {t("track your group's expenses")}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" justifyContent="center">
                  <MDButton variant="text" color="secondary" onClick={changeLanguageToEN}>
                    <Icon>A</Icon>
                    &nbsp; {t("english")}
                  </MDButton>
                  <MDButton variant="text" color="secondary" onClick={changeLanguageToZHTW}>
                    <Icon>繁</Icon>
                    &nbsp; {t("traditional chinese")}
                  </MDButton>
                </MDBox>
                <MDBox display="flex" flexDirection="column" alignItems="center">
                  <MDBox width={{ xs: "100%", md: "70%", lg: "70" }}>
                    {billListExample.map((bill) => (
                      <Bill
                        key={bill.id}
                        type={bill.type}
                        name={bill.name}
                        remark={bill.remark}
                        category={bill.category}
                        currency={bill.currency}
                        usergroupCurrency={bill.currency}
                        amount={bill.amount}
                        myPaymentAmount={bill.myPaymentAmount}
                        myShareAmount={bill.myShareAmount}
                        href={bill.href}
                      />
                    ))}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={6} md={6}>
              <MDBox
                sx={{
                  height: 440,
                  backgroundImage: `url(${worldmapAmerica})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                borderRadius="lg"
                bgColor="grey-400"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <MDTypography variant="h1" textGradient color="dark" textTransform="uppercase">
                  {t("bill")}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={6} md={6}>
              <MDBox
                sx={{
                  height: 440,
                  backgroundImage: `url(${worldmapAsia})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                borderRadius="lg"
                bgColor="grey-300"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <MDTypography variant="h1" textGradient color="dark" textTransform="uppercase">
                  {t("all")}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox pt={2}>
                <DefaultDoughnutChart
                  icon={{ color: "secondary", component: "group" }}
                  title={t("Group Expenses")}
                  description={t("Monthly Statistical Results")}
                  chart={{
                    labels: [
                      t("food"),
                      t("drink"),
                      t("transportation"),
                      t("shopping"),
                      t("enjoy"),
                      t("other"),
                    ],
                    datasets: {
                      label: "groupMonthlyExpensesExample",
                      backgroundColors: [
                        "info",
                        "warning",
                        "success",
                        "error",
                        "secondary",
                        "warning",
                        "success",
                        "error",
                        "secondary",
                        "warning",
                        "success",
                        "error",
                        "secondary",
                        "warning",
                        "success",
                        "error",
                        "secondary",
                        "warning",
                        "success",
                        "error",
                        "secondary",
                      ],
                      data: [6588, 1950, 2620, 9900, 2560, 3500],
                    },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox pt={2}>
                <DefaultDoughnutChart
                  icon={{ color: "primary", component: "group" }}
                  title={t("Group Income")}
                  description={t("Monthly Statistical Results")}
                  chart={{
                    labels: [t("salary"), t("commission"), t("sell things"), t("sponsoring")],
                    datasets: {
                      label: "groupMonthlyIncomeExample",
                      backgroundColors: [
                        "primary",
                        "success",
                        "error",
                        "info",
                        "secondary",
                        "success",
                        "error",
                        "info",
                        "secondary",
                        "success",
                        "error",
                        "info",
                        "secondary",
                        "success",
                        "error",
                        "info",
                        "secondary",
                        "success",
                        "error",
                        "info",
                        "secondary",
                      ],
                      data: [96000, 3600, 6000, 10000],
                    },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} md={6}>
              <MDBox
                sx={{
                  height: 440,
                }}
                px={2}
                borderRadius="lg"
                bgColor="grey-900"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-end"
              >
                <MDTypography variant="h4" color="dark" align="center">
                  {t("Cairo")}
                  {moment().tz("Africa/Cairo").format(" | HH:mm")}
                </MDTypography>
                <MDTypography variant="h4" color="secondary" align="center">
                  {t("Taipei")}
                  {moment().tz("Asia/Taipei").format(" | HH:mm")}
                </MDTypography>
                <MDTypography variant="h4" color="dark" align="center">
                  {t("New York")}
                  {moment().tz("America/New_York").format(" | HH:mm")}
                </MDTypography>
                <MDTypography variant="h4" color="dark" align="center">
                  {t("Berlin")}
                  {moment().tz("Europe/Berlin").format(" | HH:mm")}
                </MDTypography>
                <MDTypography variant="h4" color="dark" align="center">
                  {t("Sydney")}
                  {moment().tz("Australia/Sydney").format(" | HH:mm")}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={6} md={6}>
              <MDBox
                sx={{
                  height: 440,
                }}
                borderRadius="lg"
                bgColor="grey-900"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <MDTypography variant="h3" color="secondary" textTransform="capitalize">
                  {t("expense")}
                </MDTypography>
                <MDTypography variant="h3" color="primary" textTransform="capitalize">
                  {t("income")}
                </MDTypography>
                <MDTypography variant="h3" color="info" textTransform="capitalize">
                  {t("transfer")}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <MDBox pt={1} bgColor={darkMode ? "grey-900" : "grey-500"} borderRadius="lg">
                <MDBox display="flex" justifyContent="center">
                  <MDTypography
                    variant="h4"
                    color="light"
                    fontWeight="medium"
                    maxWidth="100%"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden !important"
                    textTransform="capitalize"
                  >
                    {t("track your group's operations")}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" justifyContent="center" py={1.2}>
                  <MDTypography
                    variant="button"
                    color="light"
                    fontWeight="medium"
                    maxWidth="100%"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden !important"
                    textTransform="capitalize"
                  >
                    {t("keep you updated")}
                  </MDTypography>
                </MDBox>
                <MDBox py={1} display="flex" flexDirection="column" alignItems="center">
                  <MDBox width={{ xs: "100%", md: "70%", lg: "70" }}>
                    {notificationListExample.map((notification) => (
                      <MDBox pb={1} px={1}>
                        <LogListUnit key={notification.id} notification={notification} />
                      </MDBox>
                    ))}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <MDBox display="flex" justifyContent="center">
                <MDBox
                  sx={{
                    height: { xs: 170, md: 400, lg: 450 },
                    width: "100%",
                    backgroundImage: `url(${sloganBackground})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  borderRadius="lg"
                  bgColor="grey-300"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <MDTypography
                    variant="h5"
                    sx={{ color: "#0DABF3" }}
                    fontWeight="medium"
                    maxWidth="100%"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden !important"
                    textTransform="capitalize"
                    component="a"
                    href={frontendURL.concat("/groups")}
                  >
                    {t("Start Managing Money with Your Friends")}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox px={0} py={1}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={4} lg={4} />
            <Grid item xs={4} md={4} lg={4}>
              <MDBox display="flex" justifyContent="center">
                <MDBox component="a" href="#">
                  <MDBox
                    component="img"
                    src={brandWhite}
                    sx={{
                      height: 20,
                      width: 20,
                    }}
                  />
                  <MDTypography
                    variant="H6"
                    sx={{ color: "#0DABF3" }}
                    fontWeight="medium"
                    maxWidth="100%"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden !important"
                  >
                    &nbsp;AllBill
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDBox
                  component="a"
                  href={frontendURL.concat("/authentication/sign-in")}
                  display="flex"
                  alignItems="center"
                  sx={{ bgcolor: "#9CCDD4", height: 22 }}
                  borderRadius="lg"
                  px={1}
                >
                  <MDTypography
                    variant="button"
                    color="white"
                    fontWeight="medium"
                    maxWidth="100%"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden !important"
                  >
                    {t("sign in")}&nbsp;
                  </MDTypography>
                  <Icon fontSize="small" sx={{ color: "#FFFFFF" }}>
                    login
                  </Icon>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </MDBox>
    </PageLayout>
  );
}

export default About;
