// timeZone
import moment from "moment-timezone";
import "moment/locale/zh-tw";

import { useState, useEffect } from "react";
// react-router components
// import { useNavigate } from "react-router-dom";
// i18n
import i18n from "i18n";
import { useTranslation } from "react-i18next";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

// http-api-tool
import axios from "axios";
// Material Dashboard 2 React example components
// import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";

const backendURL = process.env.REACT_APP_BACKENDURL;
const frontendURL = process.env.REACT_APP_FRONTENDURL;

function AccountNavbarItem({ light }) {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const [isSignIn, setSignIn] = useState(false);
  // Check user is signin or not, if not, redirect to signin page.
  const userinfoBackend = axios.create({
    baseURL: `${backendURL}/userinfo/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const checkUser = async () => {
    try {
      const getUserByToken = await userinfoBackend.post("", {});
      if (getUserByToken !== null) {
        setSignIn(true);
      }
    } catch (error) {
      setSignIn(false);
    }
  };
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    setSignIn(false);
  };

  useEffect(() => {
    // check User SignIn
    checkUser();
  }, []);

  const [controller] = useMaterialUIController();
  const { transparentNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenSubMenu = (event) => setOpenSubMenu(event.currentTarget);
  const handleCloseSubMenu = () => setOpenSubMenu(false);

  const changeLanguageToEN = () => {
    i18n.changeLanguage("en");
    moment.locale("en");
    handleCloseMenu();
    handleCloseSubMenu();
  };

  const changeLanguageToZHTW = () => {
    i18n.changeLanguage("zhTW");
    moment.locale("zh-tw");
    handleCloseMenu();
    handleCloseSubMenu();
  };
  const renderSubMenu = () => (
    <Menu
      anchorEl={openSubMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      open={Boolean(openSubMenu)}
      onClose={handleCloseSubMenu}
      sx={{ mt: 0.5, mx: -1 }}
    >
      <NotificationItem
        icon={<Icon>A</Icon>}
        onClick={() => changeLanguageToEN()}
        title={t("english")}
        href="#"
      />
      <NotificationItem
        icon={<Icon>繁</Icon>}
        onClick={() => changeLanguageToZHTW()}
        title={t("traditional chinese")}
        href="#"
      />
    </Menu>
  );
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<Icon>account_box</Icon>}
        title={t("profile")}
        href={frontendURL.concat("/profile")}
      />
      <NotificationItem
        icon={<Icon>language</Icon>}
        title={t("language")}
        onClick={handleOpenSubMenu}
        href="#"
      />
      {renderSubMenu()}
      {isSignIn ? (
        <NotificationItem
          icon={<Icon>logout</Icon>}
          title={t("sign out")}
          onClick={() => logout()}
        />
      ) : (
        <NotificationItem
          icon={<Icon>login</Icon>}
          title={t("sign in")}
          href={frontendURL.concat("/authentication/sign-in")}
        />
      )}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <MDBox>
      <Tooltip
        title={
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="white"
            textTransform="capitalize"
          >
            {t("account")}
          </MDTypography>
        }
      >
        <IconButton
          sx={navbarIconButton}
          size="small"
          disableRipple
          aria-controls="notification-menu"
          aria-haspopup="true"
          variant="contained"
          onClick={handleOpenMenu}
        >
          <Icon sx={iconsStyle}>account_circle</Icon>
        </IconButton>
      </Tooltip>
      {renderMenu()}
    </MDBox>
  );
}

// Setting default values for the props of AccountNavbarItem
AccountNavbarItem.defaultProps = {
  light: false,
};

// Typechecking props for the AccountNavbarItem
AccountNavbarItem.propTypes = {
  light: PropTypes.bool,
};
export default AccountNavbarItem;
