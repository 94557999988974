/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// Routes
import { useLocation, useNavigate } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
// import Grid from "@mui/material/Grid";
// import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
// import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import AccountNavbarItem from "examples/Navbars/components/AccountNavbarItem";
// Custom styles for GroupNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from "context";

// http-api-tool
import axios from "axios";

const backendURL = process.env.REACT_APP_BACKENDURL;
const frontendURL = process.env.REACT_APP_FRONTENDURL;

function GroupNavbar({ absolute, light, isMini }) {
  const { t } = useTranslation();

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  // const route = useLocation().pathname.split("/").slice(1);

  const [, setSignIn] = useState(false);

  const location = useLocation();
  const usergroupId = location.pathname.split("/")[2];
  const navigate = useNavigate();

  // Check user is signin or not, if not, redirect to signin page.
  const userinfoBackend = axios.create({
    baseURL: `${backendURL}/userinfo/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const checkUser = async () => {
    try {
      const getUserByToken = await userinfoBackend.post("", {});
      if (getUserByToken !== null) {
        setSignIn(true);
      }
    } catch (error) {
      navigate("/authentication/sign-in");
    }
  };
  // const logout = () => {
  //   localStorage.removeItem("accessToken");
  //   localStorage.removeItem("userId");
  //   localStorage.removeItem("userName");
  //   setSignIn(false);
  //   navigate("/authentication/sign-in");
  // };

  // get usergroup name
  const [usergroupName, setUsergroupName] = useState("");
  const usergroupBackend = axios.create({
    baseURL: `${backendURL}/usergroups/${usergroupId}/`,
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  });
  const getUsergroupName = async () => {
    try {
      const response = await usergroupBackend.get("");
      const responseData = await response.data;
      setUsergroupName(responseData.name);
    } catch (error) {
      // console.log("error");
    }
  };

  useEffect(() => {
    // check User SignIn
    checkUser();
    // get usergroup name
    getUsergroupName();
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<Icon>homepage</Icon>}
        title={t("homepage")}
        href={frontendURL.concat("/groups/", usergroupId, "/bills")}
      />
      <NotificationItem
        icon={<Icon>people</Icon>}
        title={t("member")}
        href={frontendURL.concat("/groups/", usergroupId, "/members")}
      />
      <NotificationItem
        icon={<Icon>leaderboard</Icon>}
        title={t("statistics")}
        href={frontendURL.concat("/groups/", usergroupId, "/statistics")}
      />
      <NotificationItem
        icon={<Icon>dvr</Icon>}
        title={t("log")}
        href={frontendURL.concat("/groups/", usergroupId, "/logs")}
      />
      <NotificationItem
        icon={<Icon>settings</Icon>}
        title={t("setting")}
        href={frontendURL.concat("/groups/", usergroupId, "/settings")}
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox display="flex" color="inherit" alignItems="center">
          <MDBox>
            <Tooltip
              title={
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="white"
                  textTransform="capitalize"
                >
                  {t("quickway")}
                </MDTypography>
              }
            >
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "apps" : "grid_view"}
                </Icon>
              </IconButton>
            </Tooltip>
          </MDBox>
          <MDBox
            color="inherit"
            sx={{
              width: { xs: "220px", md: "620px", lg: "620px" },
            }}
          >
            <MDTypography
              component="a"
              href={frontendURL.concat("/groups/", usergroupId, "/bills")}
              variant="H6"
              fontWeight="medium"
              textTransform="capitalize"
              maxWidth="100px"
            >
              {usergroupName}
            </MDTypography>
          </MDBox>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"} display="flex">
              <MDBox>
                <Tooltip
                  title={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="white"
                      textTransform="capitalize"
                    >
                      {t("add a record")}
                    </MDTypography>
                  }
                >
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton}
                    variant="contained"
                    href={frontendURL.concat("/groups/", usergroupId, "/bills/create")}
                  >
                    <Icon sx={iconsStyle}>note_add</Icon>
                  </IconButton>
                </Tooltip>
              </MDBox>
              <MDBox>
                <Tooltip
                  title={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="white"
                      textTransform="capitalize"
                    >
                      {t("menu")}
                    </MDTypography>
                  }
                >
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton}
                    variant="contained"
                    onClick={handleOpenMenu}
                  >
                    <Icon sx={iconsStyle}>{openMenu ? "menu" : "menu_open"}</Icon>
                  </IconButton>
                </Tooltip>
                {renderMenu()}
              </MDBox>
              <AccountNavbarItem light={light} />
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of GroupNavbar
GroupNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the GroupNavbar
GroupNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default GroupNavbar;
